import React, {useState} from 'react';
import InputMask from 'react-input-mask';
import { AiFillCalendar } from 'react-icons/ai';
import { FaUser, FaBuilding } from 'react-icons/fa';
import { GrMail } from 'react-icons/gr';
import { IoFingerPrintSharp} from 'react-icons/io5';
import { MdSmartphone, MdLocationOn } from 'react-icons/md';

import api from '../../services/wiseit';
import cep from '../../services/cep';

function ModalPfForm(){

    const [cpf, setCpf] = useState('');
    const [nome, setNome] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [dataNasc, setDataNasc] = useState('');
    const [genero, setGenero] = useState('');
    const [CEP, setCEP] = useState('');
    const [endereco, setEndereco] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [uf, setUF] = useState('');
    const [news, setNews] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);

    const pesquisaCEP = () => {
        cep.get(`/${CEP}/json`).then(response => {
          const foundCEP =  response.data;
          setEndereco(foundCEP.logradouro);
          setBairro(foundCEP.bairro);
          setCidade(foundCEP.localidade);
          setUF(foundCEP.uf);
        })
        .catch(error => console.log(error))
    }

    async function SendFormCadPF(e) {
        e.preventDefault();

        try {
          
            const FormatedCPF = cpf.replace('.','').replace('-','').replace('.','');
            const FormatedDataNasc = dataNasc.replace('/','').replace('/','');
            const FormatedCEP = CEP.replace('-','');
            const dia = FormatedDataNasc.slice(0,2);
            const mes = FormatedDataNasc.slice(2,4);
            const ano = FormatedDataNasc.slice(4,9);
            const FormatedTelefone = telefone.replace('-','').replace('(','').replace(')','').replace(' ','');
            const FormatedDDD = FormatedTelefone.slice(0,2);
            const FormatedCelular = FormatedTelefone.slice(2,11);

            const data = {
                "nome": nome,
                "apelido": '',
                "cpf": FormatedCPF,
                "anoAniversario": ano,
                "mesAniversario": mes,
                "diaAniversario": dia,
                "sexo": genero,
                "emails": [
                    {"email": email,}
                ],
                "enderecos": [
                    {
                      "complemento": complemento,
                      "numero": numero,
                      "logradouro": endereco,
                      "bairro": bairro,
                      "estado": uf,
                      "cep": FormatedCEP,
                      "cidade": cidade,
                      "tipoLogradouro": "",
                      "classificacao": {
                        "codigo": 1
                      }
                    }
                ],
                "telefones": [
                    {
                      "numero": FormatedCelular,
                      "ddd": FormatedDDD,
                      "classificacao": {
                        "codigo": 3
                      }
                    }
                ],
                "observacao": empresa,
                "permiteReceberEmail": news
            }

            const response = await api.post('pessoa/', data);

            console.log(response);

            setSuccessMsg(true);

            setCpf('');
            setNome('');
            setEmpresa('');
            setTelefone('');
            setEmail('');
            setDataNasc('');
            setGenero('');
            setCEP('');
            setEndereco('');
            setNumero('');
            setComplemento('');
            setBairro('');
            setCidade('');
            setUF('');
        
            setTimeout(function(){ setSuccessMsg(false); }, 3000);

        } catch (error) {
            console.error(error) ;
        }
    }

    

    return (
        <>
            <div className="modal-bg show-modal">
                <div className="modal-contato modal-top-bar">
                    <div className="identificação">
                        <h2>Cadastro Pessoa Física</h2>
                    </div>
                    <div className="header-form">
                        <h2>Informações pessoais</h2>
                        <span><i>*preenchimento obrigatórios</i></span>
                    </div>
                    <div className="modal-content-contato modal-top-bar-content">
                        <form>
                            <div className="mb-input">
                                <InputMask mask="999.999.999-99" type="tel" name="cpf" placeholder="*CPF" onChange={(e) => setCpf(e.target.value)} value={cpf} />
                                <label><IoFingerPrintSharp color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className="mb-input">
                                <input type="text" name="nome" placeholder="*Nome" onChange={(e) => setNome(e.target.value)} value={nome} />
                                <label><FaUser color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className="mb-input">
                                <input type="text" name="empresa" placeholder="*Empresa ou Condomínio Residencial" onChange={(e) => setEmpresa(e.target.value)} value={empresa} />
                                <label><FaBuilding color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className="mb-input">
                                <InputMask mask="(99) 99999-9999" type="tel" name="telefone" placeholder="*Telefone Celular" onChange={(e) => setTelefone(e.target.value)} value={telefone} />
                                <label><MdSmartphone color="#dbdbdb" size={18} /></label>
                                <div className="infos-input"><p>(DDD + número)</p></div>
                            </div>
                            <div className="mb-input">
                                <input type="email" name="email" placeholder="*e-mail" onChange={(e) => setEmail(e.target.value)} value={email} />
                                <label><GrMail color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className="mb-input">
                                <InputMask mask="99/99/9999" type="tel" name="data_nasc" placeholder="*data de nascimento" onChange={(e) => setDataNasc(e.target.value)} value={dataNasc} />
                                <label><AiFillCalendar color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className="mb-input input-radios">
                                <div><h3>Gênero</h3></div><br />
                                <div><input type="radio" name="genero" id="feminino" checked={genero === 'F'} value="F" onClick={(e) => setGenero('F')} /> Feminino</div>
                                <div><input type="radio" name="genero" id="masculino" checked={genero === 'M'} value="M" onClick={(e) => setGenero('M')} /> Masculino</div>
                                {/* <div><input type="radio" name="genero" id="nao_informar" checked={genero === 'Prefiro não informar'} value="Prefiro não informar" onClick={(e) => setGenero('Prefiro não informar')} /> Prefiro não informar</div> */}
                            </div>
                            <div className="mb-input">
                                <InputMask mask="99999-999" type="tel" name="cep" placeholder="*CEP" onChange={(e) => setCEP(e.target.value)} onBlur={pesquisaCEP} value={CEP} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className="mb-input">
                                <input type="text" name="endereco" placeholder="*Endereço" onChange={(e) => setEndereco(e.target.value)} value={endereco} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className="mb-input">
                                <input type="tel" name="numero" placeholder="*Número" onChange={(e) => setNumero(e.target.value)} value={numero} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className="mb-input">
                                <input type="text" name="complemento" placeholder="Complemento" onChange={(e) => setComplemento(e.target.value)} value={complemento} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className="mb-input">
                                <input type="text" name="bairro" placeholder="*Bairro" onChange={(e) => setBairro(e.target.value)} value={bairro} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className="mb-input">
                                <input type="text" name="cidade" placeholder="*Cidade" onChange={(e) => setCidade(e.target.value)} value={cidade} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className="mb-input">
                                <input type="text" name="uf" placeholder="*UF" onChange={(e) => setUF(e.target.value)} value={uf} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className="mb-input input-checkbox">
                                <div><input type="checkbox" name="info_mail" value={news} onChange={e => setNews(e.target.checked)} checked={news === true}   /> <p>Deseja receber novidades, ofertas e promoções por e-mail?</p></div>
                            </div>
                        </form>
                        <button className="form-button-identifica" onClick={SendFormCadPF}>Cadastrar</button>
                        <br /><br />
                        {successMsg && (<div className="center">Cadastro realizado com sucesso!</div>)}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModalPfForm;