import React, { useState/*, useEffect*/ } from 'react';
import { Link, /*useHistory*/ } from 'react-router-dom';
//import api from '../../services/api';
//import axios from "axios";

import { GoTriangleDown } from 'react-icons/go';
import { GrFacebook, GrInstagram/*, GrMail*/ } from 'react-icons/gr';
// import { FaUser, FaKey } from 'react-icons/fa';
// import { AiFillCloseCircle, AiFillEyeInvisible, AiFillEye, AiFillCalendar } from 'react-icons/ai';
// import { IoFingerPrintSharp} from 'react-icons/io5';
// import { FiArrowLeft } from 'react-icons/fi';
// import { MdSmartphone } from 'react-icons/md';

//import ReCAPTCHA from "react-google-recaptcha";
import Topbar from '../Topbar/Topbar';
// import Cinema from '../Submenus/Cinema';
// import AconteceNoParque from '../Submenus/AconteceNoParque';
// import TemNoParque from '../Submenus/TemNoParque';
// import ClubeDoParque from '../Submenus/ClubeDoParque';

import logo from '../../assets/logo_header.svg';
import logoMobile from '../../assets/logo_mobile.png';


import '../../styles/menu.css';
import './Header.css';

//const token = localStorage.getItem('token');

function Header() {

  const [ open, setOpen ]  = useState(false);
  const [ open2, setOpen2 ] = useState(false);
  const [ open3, setOpen3 ] = useState(false);
  const [ open4, setOpen4 ] = useState(false);
  //const [ open5, setOpen5 ] = useState(false);

  const [ openMobile, setOpenMobile ] = useState(false);
  // const [login, setLogin] = useState('');
  // const [senha, setSenha] = useState('');
  // const [fail, setFail] = useState(false);
  // const history = useHistory();

  const handleSub1 = () => { setOpen(!open); setOpen2(false); setOpen3(false); setOpen4(false); /*setOpen5(false);*/};
  const handleSub2 = () => { setOpen2(!open2); setOpen(false); setOpen3(false); setOpen4(false); /*setOpen5(false);*/ };
  const handleSub3 = () => { setOpen3(!open3); setOpen(false); setOpen2(false); setOpen4(false); /*setOpen5(false);*/ };
  const handleSub4 = () => { setOpen4(!open4); setOpen(false); setOpen2(false); setOpen3(false); /*setOpen5(false);*/ };
  //const handleSub5 = () => { setOpen5(!open5); setOpen(false); setOpen2(false); setOpen3(false); setOpen4(false);};
  const handleRemoveSubs = () => {setOpen(false); setOpen2(false); setOpen3(false); setOpen4(false); /*setOpen5(false);*/};

  // const [type, setType] = useState('password');
  // const [modal, setModal] = useState(false);
  // const [icon, setIcon] = useState(false);
  // const [modalContentLogin, setModalContentLogin] = useState(true);
  // const [modalContentForgotPassword, setModalContentForgotPassword] = useState(false);
  // const [modalContentCadastro, setModalContentCadastro] = useState(false);
  // const [username, setUserName ] = useState(null);

  // const handleLogin = () => {
  //   setOpenMobile(false);
  //   setModal(true);
  //   setModalContentLogin(true);
  //   setModalContentCadastro(false);
  //   setModalContentForgotPassword(false);
  // }

  // const handleCadastro = () => {
  //   setModalContentCadastro(true);
  //   setModalContentLogin(false);
  //   setModalContentForgotPassword(false);
  // }

  // const handlePassword = () => {
  //   setType(type === 'text' ? 'password' : 'text');
  //   setIcon(icon === true ? false : true);
  // }

  // const handleForgotPassword = () => {
  //   setModal(true);
  //   setModalContentForgotPassword(true);
  //   setModalContentLogin(false);
  //   setModalContentCadastro(false);
  // }

  // const handleBack = () => {
  //   setModalContentLogin(true);
  //   setModalContentForgotPassword(false);
  //   setModalContentCadastro(false);
  // }

  // const reCaptcha = (value) => {
  //   console.log("Captcha value:", value);
  // }

  // async function handleFormLogin(e){
  //   setFail(false);

  //   e.preventDefault();
  //   const username = login;
  //   const password = senha;
    
  //   try {

  //     const response = await api.post('/login', {login: username, senha: password });
  //     const data = await response.data.message;
  //     const token = await response.data.token;
    
  //     if(!data){
  //       localStorage.setItem('token', token);
  //       api.defaults.headers.Authorization = `Bearer ${token}`;
  //       window.location.reload();
  //     }else{  
  //       setFail(data);
  //       history.push('/');
  //     }

  //   } catch (error) {
  //     console.error(error) 
  //   }
  // }

  // useEffect(() => {
  //   axios.get('https://api-sdc.herokuapp.com/user', { 
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json', 
  //       'Authorization': `Bearer ${token }`
  //     } 
  //   }).then(response => {
  //     setUserName(response.data);
  //   }).catch(error => {
  //     console.log('Erro', error);
  //   });
  // }, []);

  // async function handleLogout() {

  //   localStorage.removeItem('token');
  //   window.location.reload();
  // }

  return (
    <>
      {/* {modalContentLogin && (
        <>
          <div className={modal ? "modal-bg show-modal" : "modal-bg"}>
          <div className={fail ? "fail fail-show" : "fail"}>{fail}</div>
            <div className="modal-contato modal-top-bar">
              <div className="identificação">
                <h2>Identificação</h2>
              </div>
              <AiFillCloseCircle size={30} color="#485860" className="modal-icon" onClick={() => setModal(false)} />
              <div className="modal-content-contato modal-top-bar-content">
                <form method="POST">              
                  <div className="mb-input">
                  <input type="text" name="login" placeholder="e-mail ou cpf" value={login} onChange={e => setLogin(e.target.value)} />
                    <label><FaUser color="#dbdbdb" size={18} /></label>
                  </div>
                  <div className="last-input">
                  <input type={type} name="senha" placeholder="senha" value={senha} onChange={e=> setSenha(e.target.value)}  />
                    <label><FaKey color="#dbdbdb" size={18} /></label>
                    <label className="view">
                      {
                        icon ? <AiFillEye color="#dbdbdb" size={25} onClick={handlePassword} /> : <AiFillEyeInvisible color="#dbdbdb" size={25} onClick={handlePassword} />
                      }
                    </label>
                  </div>
                  <span onClick={handleForgotPassword}>Esqueci a minha senha</span>
                </form>
                <button className="form-button-identifica" type="submit" onClick={handleFormLogin} >Entrar</button>
              </div>
              <button className="cadastrar" onClick={handleCadastro}>Quero me cadastrar</button>
            </div>
          </div>
        </>
      )}
      {
        modalContentCadastro && (
          <>
            <div className={modal ? "modal-bg show-modal" : "modal-bg"}>
              <div className="modal-contato modal-top-bar">
                <div className="identificação">
                  <h2>Identificação</h2>
                </div>
                <div className="voltar" onClick={handleBack}>
                  <h3>Voltar</h3>
                  <FiArrowLeft size={30} color="#ffffff" />
                </div>
                <AiFillCloseCircle size={30} color="#485860" className="modal-icon" onClick={() => setModal(false)} />
                <div className="header-form">
                  <h2>Cadastro</h2>
                  <span><i>*preenchimento obrigatórios</i></span>
                </div>
                <div className="modal-content-contato modal-top-bar-content">
                  <form>              
                    <div className="mb-input">
                      <input type="email" name="email" placeholder="*e-mail" />
                      <label><GrMail color="#dbdbdb" size={18} /></label>
                    </div>
                    <div className="mb-input">
                      <input type="tel" name="cpf" placeholder="*CPF" />
                      <label><IoFingerPrintSharp color="#dbdbdb" size={18} /></label>
                    </div>
                    <div className="mb-input">
                      <input type="text" name="nome" placeholder="*nome" />
                      <label><FaUser color="#dbdbdb" size={18} /></label>
                    </div>
                    <div className="mb-input">
                      <input type="text" name="sobrenome" placeholder="*sobrenome" />
                      <label><FaUser color="#dbdbdb" size={18} /></label>
                    </div>
                    <div className="mb-input">
                      <input type="text" name="data_nasc" className="date" placeholder="*data de nascimento" />
                      <label><AiFillCalendar color="#dbdbdb" size={18} /></label>
                    </div>
                    <div className="mb-input input-radios">
                      <div><h3>Gênero</h3></div><br />
                      <div><input type="radio" name="genero" id="feminino" value="feminino"/> Feminino</div>
                      <div><input type="radio" name="genero" id="masculino" value="masculino"/> Masculino</div>
                      <div><input type="radio" name="genero" id="nao_informar" value="nao_informar"/> Prefiro não informar</div>
                    </div>
                    <div className="mb-input">
                        <input type={type} name="senha" placeholder="senha"  />
                        <label><FaKey color="#dbdbdb" size={18} /></label>
                        <label className="view">
                          {
                            icon ? <AiFillEye color="#dbdbdb" size={25} onClick={handlePassword} /> : <AiFillEyeInvisible color="#dbdbdb" size={25} onClick={handlePassword} />
                          }
                        </label>
                        <div className="infos-input"><p>(Mínimo de 6 caracteres)</p></div>
                    </div>
                    <div className="mb-input">
                        <input type="tel" name="telefone" placeholder="*telefone" />
                        <label><MdSmartphone color="#dbdbdb" size={18} /></label>
                        <div className="infos-input"><p>(DDD + número)</p></div>
                    </div>
                    <div className="mb-input input-checkbox">
                      <div><input type="checkbox" name="info_mail" /> <p>Quero receber promoções e novidades por email</p></div><br />
                      <div><input type="checkbox" name="sms"/> <p>Quero receber informações do meu pedido por SMS</p></div><br />
                      <div><input type="checkbox" name="whatsapp"/> <p>Quero receber notificações pelo Whatsapp</p></div><br />
                      <div><input type="checkbox" name="aceito"/> <p>Li e aceito a Política de Privacidade e Proteção de Dados e os Termos de Uso.</p></div>
                    </div>
                    <div className="mb-input">
                      <div id="g-recaptcha">
                        
                        <ReCAPTCHA sitekey="6LevRB4aAAAAAHWXOsz_zG5cQ5NlLZygq47lfCIP" onChange={reCaptcha} size="normal" />
                      </div>
                    </div>
                  </form>
                  <button className="form-button-identifica">Cadastrar</button>
                </div>
              </div>
            </div>
          </>
        )
      }
      {
        modalContentForgotPassword && (
          <>
            <div className={modal ? "modal-bg show-modal" : "modal-bg"}>
              <div className="modal-contato modal-top-bar">
                <div className="identificação">
                  <h2>Esqueci minha senha</h2>
                </div>
                <AiFillCloseCircle size={30} color="#485860" className="modal-icon" onClick={() => setModal(false)} />
                <div className="modal-content-contato modal-top-bar-content">
                  <form>              
                    <div className="mb-input">
                      <input type="text" name="cpf" placeholder="digite seu cpf" />
                      <label><IoFingerPrintSharp color="#dbdbdb" size={22} /></label>
                    </div>
                    <p>As informações para redefinição de senha serão enviadas para o e-mail cadastrado.</p>
                    <br /><br />
                  </form>
                  <button className="form-button-identifica">Recuperar minha senha</button>
                </div>
              </div>
            </div>
          </>
        )
      } */}
      <Topbar />
      <header className={openMobile ? "header-mobile-active" : "header-mobile-inative"}>
        <div className="mobile">
          <button type="button" onClick={() => setOpenMobile(!openMobile)} className={openMobile ? "hamburger hamburger--stand is-active" : "hamburger hamburger--spin"}>
            <span className="hamburger-box">
            <span className="hamburger-inner"></span>
            </span>
          </button>
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="Shopping Parque da Cidade" width="100%" className="logo-desk" />
              <img src={logoMobile} alt="Shopping Parque da Cidade" width="100%" className="logo-mobile" />
            </Link>
          </div>
        </div>
        <hr/>
        <div className="container">
          <ul>
            <li onMouseEnter={() => handleRemoveSubs()}><Link to="/lojas">Lojas</Link></li>
            <li onMouseEnter={() => handleRemoveSubs()}><Link to="/alimentacao">Alimentação</Link></li>
            <li onMouseEnter={() => handleSub1()}><span>Cinema <GoTriangleDown size={16} /></span> 
              {(open ? 
                <>
                  <div className="onlymobile">
                    <ul className="submenu">
                      <li><Link to="/cinema">Sobre</Link></li>
                      <li><Link to="/programacao">Programação</Link></li>
                    </ul>
                  </div>
                  <ul className="submenu-desk">
                    <li><Link to="/cinema">Sobre</Link></li>
                      <li><Link to="/programacao">Programação</Link></li>
                  </ul>
                </>
                 : '' 
              )}
            </li>
            <li onMouseEnter={() => handleSub2()}><span>Acontece no parque <GoTriangleDown size={16} /></span> 
              {(open2 ? 
                <>
                  <div className="onlymobile">
                    <ul className="submenu">
                      <li><Link to="/eventos">Eventos</Link></li>
                      <li><Link to="/novidades">Novidades</Link></li>
                      <li><Link to="/nossoshorarios">Nossos Horários</Link></li>
                    </ul>
                  </div>
                  <ul className="submenu-desk">
                    <li><Link to="/eventos">Eventos</Link></li>
                    <li><Link to="/novidades">Novidades</Link></li>
                    <li><Link to="/nossoshorarios">Nossos Horários</Link></li>
                  </ul>
                </> 
                : '' 
              )}
            </li>
            <li onMouseEnter={() => handleSub3()}><span>Tem no parque <GoTriangleDown size={16} /></span> 
              {(open3 ? 
                <>
                  <div className="onlymobile">
                    <ul className="submenu">
                      <li><Link to="/lifecenter">Life Center</Link></li>
                      <li><Link to="/clinica-einstein">Clínica Einstein</Link></li>
                      <li><Link to="/sustentabilidade">Sustentabilidade</Link></li>
                      <li><Link to="/mataatlantica">Mata Atlântica</Link></li>
                      <li><Link to="/telhadoverde">Telhado verde</Link></li>
                      <li><Link to="/coletadelixo">Coleta de Lixo</Link></li>
                      <li><Link to="/economiadeagua">Economia de Água</Link></li>
                      {/* <li><Link to="/temnoparque">Pontos de recarga</Link></li> */}
                    </ul>
                  </div> 
                  <ul className="submenu-desk">
                    <li><Link to="/lifecenter">Life Center</Link></li>
                    <li><Link to="/clinica-einstein">Clínica Einstein</Link></li>
                    <li><Link to="/sustentabilidade">Sustentabilidade</Link></li>
                    <li><Link to="/mataatlantica">Mata Atlântica</Link></li>
                    <li><Link to="/telhadoverde">Telhado verde</Link></li>
                    <li><Link to="/coletadelixo">Coleta de Lixo</Link></li>
                    <li><Link to="/economiadeagua">Economia de Água</Link></li>
                  </ul>
                </> 
                : '' 
              )}
            </li>
            <li onMouseEnter={() => handleSub4()}><span>Clube do parque <GoTriangleDown size={16} /></span> 
              {(open4 ? 
                <>
                  <div className="onlymobile">
                    <ul className="submenu">
                      <li><Link to="/clubedoparque">Sobre</Link></li>
                      <li><Link to="/beneficios">Benefícios</Link></li>
                    </ul>
                  </div>
                  <ul className="submenu-desk">
                    <li><Link to="/clubedoparque">Sobre</Link></li>
                    <li><Link to="/beneficios">Benefícios</Link></li>
                  </ul>
                </> : '' 
              )}
            </li>
            <li onMouseEnter={() => handleRemoveSubs()}><Link to="/servicos">Serviços</Link></li>
            <li onMouseEnter={() => handleRemoveSubs()}><Link to="/contato">Contato</Link></li>
            {/* <li className="onlymobile" onMouseEnter={() => handleSub5()} onMouseLeave={() => setOpen5(false)}>
              {token ? 
                <>
                  <span> Olá, <b>{username}</b> <GoTriangleDown size={16} /></span> 
                  {open5 && (
                      <ul className="submenu area-logada" onMouseLeave={() => setOpen5(false)}>
                        <li> <Link to="/painel">Meu cadastro</Link></li>
                        <li> <Link to="/endereco">Meus endereços</Link></li>
                        <li> <Link to="/alterarsenha">Alterar senha</Link></li>
                        <li> <Link to="/" onClick={handleLogout}>Sair</Link></li>
                      </ul>
                  )}
                </>
                  :
              <Link to="#" onClick={handleLogin}> Entre ou cadastre-se</Link>
              }
            </li> */}
            <li className="onlymobile"><a href="https://www.intranetmall.com/shoppingparquedacidade/">Área do lojista</a></li>
            <li className="onlymobile">
              <a href="https://www.instagram.com/_shoppingparquedacidade/" target="_blank" rel="noreferrer" title="Instagra,">
                <GrInstagram color="#485860" size={16} />
              </a>
              <a href="https://www.facebook.com/shoppingparquedacidade" target="_blank" rel="noreferrer" title="Facebook">
                <GrFacebook color="#485860" size={16} />
              </a>
            </li>
          </ul>
        </div>
        <hr/>
        {/* {(open ?  <div className="desk"><div className="container"><Cinema /></div></div> : '' )}
        {(open2 ? <div className="desk"><div className="container"><AconteceNoParque /></div></div>: '' )}
        {(open3 ? <div className="desk"><div className="container"><TemNoParque /></div></div> : '' )}
        {(open4 ? <div className="desk"><div className="container"><ClubeDoParque /></div></div> : '' )} */}
      </header>
    </>
  );
}

export default Header;