import React, {useState, useEffect, useMemo} from 'react';
import { FaRegClock, FaMapMarkerAlt, FaInstagram, FaFacebookSquare, FaWhatsapp } from 'react-icons/fa';
import { AiFillCaretRight } from 'react-icons/ai';
import { GrClose } from 'react-icons/gr';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import logo1 from '../../assets/Lojas/logos/Arbo-real.png';
import logo2 from '../../assets/Lojas/logos/arranjo-express.png';
import logo3 from '../../assets/Lojas/logos/Atelie-das-Artes.jpg';
import logo4 from '../../assets/Lojas/logos/Best-Cell.jpg';
import logo5 from '../../assets/Lojas/logos/Cats.png';
import logo6 from '../../assets/Lojas/logos/CHILLI-BEANS.jpg';
import logo7 from '../../assets/Lojas/logos/Claro.png';
import logo8 from '../../assets/Lojas/logos/Clínica-Einstein.jpg';
import logo9 from '../../assets/Lojas/logos/logo-cvc.jpg';
import logo10 from '../../assets/Lojas/logos/Empório-dos-cosmeticos.jpg';
import logo11 from '../../assets/Lojas/logos/espaco-laser.png';
import logo12 from '../../assets/Lojas/logos/Espaco_prana_spa.jpg';
import logo13 from '../../assets/Lojas/logos/Velocity.jpg';
import logo14 from '../../assets/Lojas/logos/Sestini.png';
import logo15 from '../../assets/Lojas/logos/Vila-Casa.png';
import logo16 from '../../assets/Lojas/logos/Morana.png';
import logo17 from '../../assets/Lojas/logos/tropical.png';
//import logo18 from '../../assets/Lojas/logos/Usaflex.png';
import logo19 from '../../assets/Lojas/logos/Vips-Cambio.png';
import logo20 from '../../assets/Lojas/logos/MS-Odontontologia.png';
//import logo21 from '../../assets/Lojas/logos/CacauShow.png';
//import logo22 from '../../assets/Lojas/logos/extrafarma.png';
import logo23 from '../../assets/Lojas/logos/THE-NAIL-BOX-01.png';
import logo24 from '../../assets/Lojas/logos/lavanderia-santa-maria.png';
import logo25 from '../../assets/Lojas/logos/estudio-da-sobrancelha.jpg';
import logo26 from '../../assets/Lojas/logos/Loterias.png';
import logo27 from '../../assets/Lojas/logos/hirota.png';
import logo28 from '../../assets/Lojas/logos/lupo.jpg';
import logo29 from '../../assets/Lojas/logos/Polo-Wear.png';
import logo30 from '../../assets/Lojas/logos/Via-Italy.png';
import logo31 from '../../assets/Lojas/logos/Óticas-Carol.png';
import logo32 from '../../assets/Lojas/logos/PET-KINGDOM.png';
import logo33 from '../../assets/Lojas/logos/Time-e-co-1.png';
import logo34 from '../../assets/Lojas/logos/samsung.jpg';
import logo35 from '../../assets/Lojas/logos/podesim.png';
import logo36 from '../../assets/Lojas/logos/Piticas.jpg';
import logo37 from '../../assets/Lojas/logos/minha-vaidade.jpg';
import logo38 from '../../assets/Lojas/logos/kinoplex.jpg';



import './styles.css';

function Lojas(){

  const lojas =  useMemo(
    () => [
      {
        id: 1,
        logo: logo1,
        nome: 'ArboREAL',
        categoria: 'Móveis e Decoração',
        info: 'Móveis em madeira maciça, com o propósito de melhorar a destinação dos resíduos florestais e criar móveis artesanais de alta qualidade, exclusivos e com compromisso ambiental.',
        piso: 'Piso 1',
        telefone: '5184-2318',
        site: 'https://www.arboreal.com.br',
        instagram: 'https://www.instagram.com/arbo_real',
        facebook: 'https://www.facebook.com/arborealbr',
        whatsapp: ''
      },
      {
        id: 2,
        logo: logo2,
        nome: 'Arranjos Express',
        categoria: 'Conserto de roupas',
        categoria2: 'Serviços',
        info: 'Especialista em costura! Nossos serviços como, ajustes em até 24 horas, barras em até uma hora, customização, cama, mesa e banho, bordados, roupas pet, alfaiataria, moda praia, baby, peças sob medida. Também realizamos atendimento domiciliar.',
        piso: 'Piso 1',
        telefone: '',
        site: 'https://www.arranjosexpress.com.br',
        instagram: 'https://www.instagram.com/arranjosexpressoficial',
        facebook: 'https://www.facebook.com/arranjosexpressbrasil',
        whatsapp: '5511994146439'
        
      },
      {
        id: 3,
        logo: logo3,
        nome: 'Ateliê das Artes',
        categoria: 'Papelaria e Gráfica',
        categoria2: 'Serviços',
        info: 'O Ateliê das Artes transforma a imaginação em solução por meio de presentes especiais, com alta qualidade e design inovador.',
        piso: 'Piso 1',
        telefone: '5184-2500',
        site: '',
        instagram: 'https://www.instagram.com/ateliedasartessp2',
        facebook: '',
        whatsapp: ''
      },
      {
        id: 4,
        logo: logo4,
        nome: 'Best Cell',
        categoria: 'Acessórios de telefonia',
        categoria2: 'Telefones e acessórios',
        info: 'A Best Cell oferece uma variedade de produtos e acessórios para celulares e tablets.',
        piso: 'Térreo',
        telefone: '5184-2466',
        site: 'http://www.bestcellstore.com.br',
        instagram: 'https://www.instagram.com/bestcellstore/',
        facebook: 'https://www.facebook.com/bestcellstore/',
        whatsapp: ''
      },
      {
        id: 5,
        logo: logo5,
        nome: 'Cat`s Cabeleireiros',
        categoria: 'Cabelereiro e Barbeiro',
        categoria2: 'Serviços',
        info: 'Espaço de beleza e estética que está desde 1995 no mercado fazendo a cabeça de homens e mulheres exigentes.',
        piso: 'Piso 1',
        telefone: '5181-3767 / 2158-5638 / (11) 9. 9594-5691',
        site: 'http://www.catscabeleireiros.com.br/',
        instagram: 'https://www.instagram.com/catsparquedacidade',
        facebook: 'https://www.facebook.com/CatsCabeleireiros',
        whatsapp: ''
      },
      {
        id: 6,
        logo: logo6,
        nome: 'Chilli Beans',
        categoria: 'Óticas e Relógios',
        info: 'A Chilli Beans iniciou suas atividades no final dos anos 90, sob o comando do empresário Caito Maia. Mais de 20 anos depois, hoje é consolidada como a maior rede especializada em óculos e acessórios da América Latina, com mais de 800 pontos de venda no Brasil e também em Portugal, Estados Unidos, Colômbia, Kuwait, Peru, México, Bolívia e Tailândia. A empresa foi pioneira no conceito de ótica self service, que permite ao cliente manusear e experimentar os produtos, e também a primeira marca do segmento a inaugurar uma máquina de customização, que permite que o cliente fabrique seus próprios óculos.',
        piso: 'Térreo',
        telefone: '',
        site: 'https://loja.chillibeans.com.br/',
        instagram: 'https://www.instagram.com/chillibeansoficial/',
        facebook: 'https://www.facebook.com/ChilliBeansBR/',
        whatsapp: ''
      },
      {
        id: 7,
        logo: logo7,
        nome: 'Claro',
        categoria: 'Telefones e acessórios',
        categoria2: 'Serviços',
        info: 'Serviços de Internet, Telefone, Celular e TV. Além de aparelho e pacotes.',
        piso: 'Térreo',
        telefone: '(11) 99349-2995/ (11) 97692-5131',
        site: 'https://www.claro.com.br/',
        instagram: 'https://www.instagram.com/clarobrasil',
        facebook: 'https://www.facebook.com/clarobrasil/',
        whatsapp: ''
        
      },
      {
        id: 8,
        logo: logo8,
        nome: 'Clínica Einstein',
        categoria: 'Exames, vacinas e consultas',
        categoria2: 'Serviços',
        info: '​Na Clínica Einstein você tem à disposição uma equipe formada por médicos de família e enfermeiros, com apoio de nutricionistas, educadores físicos, psicólogos, entre outros, trabalhando juntos para garantir o cuidado da sua saúde e construir um plano de cuidado exclusivo para você. Levamos em consideração o seu histórico de saúde, seus comportamentos de vida e outros fatores para definir com você, suas metas de saúde e programar consultas e orientações que vão te ajudar a melhorar a sua qualidade de vida.',
        piso: 'Piso 1',
        telefone: '2151-0021',
        site: 'https://einstein.br/estrutura/unidades/parque-da-cidade',
        instagram: 'http://instagram.com/hosp_einstein',
        facebook: 'https://www.facebook.com/HospitalAlbertEinstein',
        whatsapp: ''
      },
      {
        id: 9,
        logo: logo9,
        nome: 'Cvc',
        categoria: 'Agência de Viagens',
        categoria2: 'Serviços',
        info: 'A CVC, a maior operadora de turismo da América Latina, possui uma ampla rede de produtos e serviços turísticos.',
        piso: 'Piso 1',
        telefone: '2158-5626',
        site: 'https://www.cvc.com.br',
        instagram: 'https://www.instagram.com/cvcviagens/',
        facebook: 'https://www.facebook.com/cvcviagens',
        whatsapp: ''
      },
      {
        id: 10,
        logo: logo10,
        nome: 'Empório dos Cosméticos',
        categoria: 'Perfumaria e Cosméticos',
        info: 'Os melhores produtos para cabelos e maquiagem.',
        piso: 'Térreo',
        telefone: '97322-0940',
        site: '',
        instagram: 'https://www.instagram.com/emporiodoscosmeticossp',
        facebook: 'https://www.facebook.com/emporiodoscosmeticossp',
        whatsapp: ''
      },
      {
        id: 11,
        logo: logo11,
        nome: 'Espaço laser',
        categoria: 'Estética',
        categoria2: 'Serviços',
        info: 'Rede de depilação a laser que democratizou os procedimentos a laser.',
        piso: 'Térreo',
        telefone: '11 4858-3330 R 8219',
        site: 'https://www.espacolaser.com.br',
        instagram: 'https://www.instagram.com/espacolaser',
        facebook: 'https://www.facebook.com/pageespacolaser',
        whatsapp: ''
      },
      {
        id: 12,
        logo: logo12,
        nome: 'Espaço Prana Spa',
        categoria: 'SPA',
        categoria2: 'Serviços',
        info: 'O Espaço Prana Spa disponibiliza aos clientes mais de 30 opções de diferentes tratamentos para promover o equilíbrio do corpo, mente e espírito.',
        piso: 'Térreo',
        telefone: '(11) 5184-0652',
        site: 'https://www.espacoprana.com.br',
        instagram: 'https://www.instagram.com/espacopranaspa/',
        facebook: 'https://www.facebook.com/EspacoPrana/',
        whatsapp: ''
      },
      {
        id: 13,
        logo: logo13,
        nome: 'Velocity',
        categoria: 'Academia',
        categoria2: 'Serviços',
        info: 'Velocity é uma academia que se sente. É o corpo todo em movimento, a sincronia perfeita entre música, ritmo, energia e sensações únicas.',
        piso: 'Térreo',
        telefone: '5181-0441',
        site: 'http://www.studiovelocity.com.br',
        instagram: 'https://www.instagram.com/studio_velocity/',
        facebook: 'https://www.facebook.com/velocityPQCD/',
        whatsapp: ''
      },
      {
        id: 14,
        logo: logo14,
        nome: 'Sestini',
        categoria: 'Malas e Acessórios',
        info: 'A marca nacional referência em malas e mochilas que te acompanham em todas as suas histórias.',
        piso: 'Piso 1',
        telefone: '(11) 97644-5111 / (11) 5184-1742',
        site: 'https://www.sestini.com.br/',
        instagram: 'https://www.instagram.com/sestinioficial',
        facebook: 'https://www.facebook.com/sestinioficial',
        whatsapp: ''
      },
      {
        id: 15,
        logo: logo15,
        nome: 'Vila Casa',
        categoria: 'Artigos para o lar',
        info: 'Na Vila Casa você encontra, desde 1999, as principais e melhores marcas do mundo em utensílios culinários. Artigos para mesa e bar.',
        piso: 'Térreo',
        telefone: '11 97168-8029',
        site: 'https://www.vilacasa.com.br',
        instagram: 'https://www.instagram.com/vilacasaud',
        facebook: 'https://www.facebook.com/vilacasaUD',
        whatsapp: ''
      },
      {
        id: 16,
        logo: logo16,
        nome: 'Morana',
        categoria: 'Acessórios',
        info: 'Uma das maiores redes de acessórios femininos do Brasil com um vasto número de produtos.',
        piso: 'Piso 1',
        telefone: '2158-5657',
        site: 'https://www.morana.com.br',
        instagram: 'https://www.instagram.com/morana_parquedacidade',
        facebook: 'https://www.facebook.com/moranaparquedacidade',
        whatsapp: ''
      },
      {
        id: 17,
        logo: logo17,
        nome: 'Tropical Sandálias e Acessórios',
        categoria: 'Calçados Unissex',
        info: 'Multimarcas de sandálias e acessórios: Havaianas, Crocs, Cartago e Ipanema.',
        piso: 'Térreo',
        telefone: '5181-0483',
        site: '',
        instagram: 'https://www.instagram.com/tropicalshoppingparquedacidade/',
        facebook: 'https://www.facebook.com/Tropicalshoppingparquedacidade',
        whatsapp: ''
      },
      // {
      //   id: 18,
      //   logo: logo18,
      //   nome: 'Usaflex',
      //   categoria: 'Calçados Femininos',
      //   info: 'Pioneira na fabricação de calçados de conforto e moda para mulheres de todas as idades.',
      //   piso: 'Térreo',
      //   telefone: '5184-2456',
      //   site: 'https://www.usaflex.com.br',
      //   instagram: 'https://www.instagram.com/usaflexparquedacidade/',
      //   facebook: 'https://www.facebook.com/Usaflex',
      //   whatsapp: ''
      // },
      {
        id: 19,
        logo: logo19,
        nome: 'Vip´s corretora de câmbio - Reabertura em breve',
        categoria: 'Agência de Câmbio',
        categoria2: 'Serviços',
        info: 'Atuando no mercado de Câmbio há mais de 30 anos, credenciada pelo BACEN. Especializada em moedas estrangeiras para turismo e negócios internacionais.',
        piso: 'Piso 1',
        telefone: '5181-2192/4440-1348',
        site: 'https://www.vipsturecambio.com.br',
        instagram: 'https://www.instagram.com/vipscambio/',
        facebook: 'https://www.facebook.com/vipscorretoradecambio',
        whatsapp: ''
      },
      {
        id: 20,
        logo: logo20,
        nome: 'Ms Odontologia',
        categoria: 'Consultório odontológico',
        categoria2: 'Serviços',
        info: 'A MS Odontologia oferece soluções que resgatam o sorriso e a autoestima das pessoas por meio de sólidos benefícios na saúde e estética bucal.',
        piso: 'Térreo',
        telefone: '2158-5637',
        site: 'http://msodontologia.com/',
        instagram: 'https://www.instagram.com/ms_odontologiaa',
        facebook: 'https://www.facebook.com/msodontologiaespecializada',
        whatsapp: ''
      },
      // {
      //   id: 22,
      //   logo: logo22,
      //   nome: 'Extrafarma',
      //   categoria: 'Drogaria',
      //   info: 'Rede de farmácia nacional que atua com pioneirismo nos mercados de atacado e de varejo farmacêutico do Brasil.',
      //   piso: 'Térreo',
      //   telefone: '99306-5626',
      //   site: 'https://www.extrafarma.com.br',
      //   instagram: 'https://www.instagram.com/extrafarma',
      //   facebook: 'https://www.facebook.com/Extrafarma',
      //   whatsapp: ''
      // },
      {
        id: 23,
        logo: logo23,
        nome: 'The Nail Box',
        categoria: 'Esmalteria',
        categoria2: 'Serviços',
        info: 'A The Nail Box oferece o melhor tratamento e estética para suas unhas de um jeito rápido e com boa qualidade.',
        piso: 'Piso 1',
        telefone: '11 2158-5659',
        site: 'https://thenailbox.com.br/',
        instagram: 'https://www.instagram.com/thenailboxsp/',
        facebook: 'https://www.facebook.com/thenailboxsp',
        whatsapp: ''
      },
      {
        id: 24,
        logo: logo24,
        nome: 'Lavanderia Sta Maria - Reabertura em breve',
        categoria: 'Lavanderia',
        info: 'Atende com qualidade e eficiência com serviços de lavanderia e passadoria.',
        piso: 'Piso 1',
        telefone: '94200-6162',
        site: 'http://www.lavanderiasantamaria.com.br',
        instagram: 'https://www.instagram.com/lavanderiasantamaria/',
        facebook: 'https://www.facebook.com/LavanderiaStaMaria/',
        whatsapp: ''
      },
      {
        id: 25,
        logo: logo25,
        nome: 'Estúdio da Sobrancelha',
        categoria: 'Estética',
        categoria2: 'Serviços',
        info: 'Espaço de beleza voltado para o cuidado do rosto. Design de Sobrancelha, micropigmentação da sobrancelha.',
        piso: 'Piso 1',
        telefone: '5184-2468',
        site: '',
        instagram: 'https://www.instagram.com/estudiodasobrancelha_cn.pq/',
        facebook: '',
        whatsapp: ''
      },
      {
        id: 26,
        logo: logo26,
        nome: 'Lotérica Lucky Place',
        categoria: 'Lotérica',
        categoria2: 'Serviços',
        info: 'Pioneira no ramo de loterias, credenciada pela Caixa Econômica Federal. Atualmente, a Lotéricas presta outros tipos de serviços, como pagamento de contas.',
        piso: 'Piso 1',
        telefone: '5184-2460',
        site: 'https://www.lotericas.com.br',
        instagram: '',
        facebook: '',
        whatsapp: ''
      },
      {
        id: 27,
        logo: logo27,
        nome: 'Hirota Foods',
        categoria: 'Mini mercado',
        info: 'Com conceito das lojas de conveniências japonesas, o Hirota Food Express foi pensado em oferecer a melhor solução em alimentação, com refeições saudáveis, variadas, práticas e inteligentes.',
        piso: 'Térreo',
        telefone: '2158-5610',
        site: 'https://www.hirotafood.com.br',
        instagram: 'https://www.instagram.com/hirotafoodsupermercados/',
        facebook: 'https://www.facebook.com/HirotaFoodSupermercados',
        whatsapp: ''
      },
      
      {
        id: 28,
        logo: logo28,
        nome: 'Lupo',
        categoria: 'Moda íntima',
        info: 'Fabricante brasileira de moda íntima, meias e uniformes de times.',
        piso: 'Térreo',
        telefone: '2158-5555',
        site: 'https://www.lupo.com.br',
        instagram: 'https://www.instagram.com/lupo_shoppingparquedacidade_sp',
        facebook: 'https://www.facebook.com/LupoOficial',
        whatsapp: ''
      },
      {
        id: 29,
        logo: logo29,
        nome: 'Polo Wear',
        categoria: 'Moda unissex',
        info: 'A Polo Wear nasceu da vontade de oferecer ao público uma linha de produtos exclusivos que mesclam o estilo casual e esportivo, sem deixar de lado a influência das principais tendências do momento, e sempre as adaptando ao nosso estilo e conceito.',
        piso: 'Piso 1',
        telefone: '5181-0640 ou 5181-0690',
        site: 'https://www.polowear.com.br/',
        instagram: 'https://www.instagram.com/polowearoficial/',
        facebook: 'https://www.facebook.com/polowear',
        whatsapp: ''
      },
      {
        id: 30,
        logo: logo30,
        nome: 'Via Italy – EM BREVE',
        categoria: 'Moda masculina',
        info: 'Desde 2012 , trabalhando com os melhores tecidos do mundo, estilo su misura em São Paulo. A VIA ITALY nasceu em 2012 com o propósito de resgatar a essência da alfaiataria no Brasil.',
        piso: 'Térreo',
        telefone: '',
        site: 'http://www.viaitaly.com.br',
        instagram: 'https://www.instagram.com/viaitalysumisura/',
        facebook: 'https://www.facebook.com/viaitalysumisura',
        whatsapp: ''
      },
      {
        id: 31,
        logo: logo31,
        nome: 'Óticas Carol',
        categoria: 'Ótica',
        info: 'Uma das maiores redes de Óticas do Brasil, com mais de 1200 lojas, presentes em todo País.',
        piso: 'Térreo',
        telefone: '5184-2458',
        site: 'https://www.oticascarol.com.br',
        instagram: 'https://www.instagram.com/oticascarol',
        facebook: 'https://www.facebook.com/OticasCarol',
        whatsapp: ''
      },
      {
        id: 32,
        logo: logo32,
        nome: 'Pet Kingdom',
        categoria: 'Petshop',
        categoria2: 'Serviços',
        info: 'Um novo conceito de Pet Shop com Day Care, Serviços e muitos mimos.',
        piso: 'Térreo',
        telefone: '(11) 96417-7465 / (11) 5181-2421 / 5184-1190',
        site: 'https://www.petkingdom.com.br',
        instagram: 'https://www.instagram.com/petkingdomoficial/',
        facebook: '',
        whatsapp: ''
      },
      {
        id: 33,
        logo: logo33,
        nome: 'Time & Co.',
        categoria: 'Relojoaria',
        categoria2: 'Serviços',
        info: 'Conserto de relógios de parede e de pulso, troca de baterias.',
        piso: 'Piso Superior',
        telefone: '2158-5635',
        site: '',
        instagram: 'https://www.instagram.com/time_co.parquedacidade',
        facebook: '',
        whatsapp: ''
      },
      {
        id: 34,
        logo: logo34,
        nome: 'Samsung',
        categoria: 'Telefones e acessórios',
        info: 'Loja de celulares e acessórios com Smart Service.',
        piso: 'Térreo',
        telefone: '98663-0228',
        site: 'https://www.samsung.com.br',
        instagram: 'https://www.instagram.com/samsungparquedacidade/',
        facebook: '',
        whatsapp: ''
      },
      {
        id: 35,
        logo: logo35,
        nome: 'PódeSim',
        categoria: 'Moda Sustentável',
        info: 'Destinação criativa de retalhos têxteis. Acessórios exclusivos e banco de tecido.',
        piso: 'Piso 1',
        telefone: '(31) 98464-8050',
        site: 'https://www.podesimoficial.com.br',
        instagram: 'https://www.instagram.com/podesim_modasustentavel',
        facebook: '',
        whatsapp: ''
      },
      {
        id: 36,
        logo: logo36,
        nome: 'Piticas - Reabertura em breve',
        categoria: 'Moda unissex',
        info: 'Rede de franquias de Quiosques e Lojas focada no mundo Geek. Hoje com mais de 450 lojas no Brasil, se tornou a maior da América Latina focada em cultura Pop, e a 33ª maior franquia do Brasil!',
        piso: 'Térreo',
        telefone: '',
        site: 'https://www.piticas.com.br',
        instagram: 'https://www.instagram.com/piticasoficial',
        facebook: 'https://www.facebook.com/camisetaspiticas',
        whatsapp: '5511993830653'
      },
      {
        id: 37,
        logo: logo37,
        nome: 'Minha Vaidade',
        categoria: 'Moda feminina',
        info: 'Moda feminina e Plus Size, maquiagem importada e acessórios.',
        piso: 'Piso 1',
        telefone: '98110-9798',
        site: '',
        instagram: 'https://www.instagram.com/minha_vaidade',
        facebook: '',
        whatsapp: ''
      },
      {
        id: 38,
        logo: logo38,
        nome: 'Kinoplex',
        categoria: 'Cinema',
        categoria2: 'Entretenimento',
        info: 'O complexo de cinema Kinoplex foi eleito com a melhor salas VIP de São Paulo, pelo Guia da Folha de S.Paulo. As salas possuem poltronas maiores e totalmente reclináveis. Além disso, a unidade conta um cardápio com opções diferenciadas como milk-shake de pipoca.',
        piso: '',
        telefone: '',
        site: '',
        instagram: 'https://www.instagram.com/kinoplex',
        facebook: 'https://www.facebook.com/kinoplex',
        whatsapp: ''
      },

    ],
    []
  );

  const sortByName = lojas.sort((a, b) => {
    return a.nome.localeCompare(b.nome);
  });

  const [showLoja, setShowLoja] = useState(4);
  const [display, setDisplay] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [searchCat, setSearchCat] = useState('');
  const [catDisplay, setCatDisplay] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState(false);


  function handleShowMoreLojas() {
    setShowLoja(moreLojas => moreLojas + 4);
  }

  function handleShowLojasByCat(e){
    //setSearchCat(searchCat);
    setSearchInput('');
    setCatDisplay(true);
    setDisplay(false);
    setShowLoja(false);
    e.preventDefault();
  }

  function handleModal(e, loja){
    setModalContent(loja);
    setModal(true);
  }


  useEffect(() => {
    const suggestions = [];
    lojas.forEach(function (loja) {
      suggestions.push(loja.nome);    
    });
    setOptions(suggestions);
  }, [lojas]);

  const resultSearch = item => {
    setSearchInput(item);
    setDisplay(false);
  }

  const searchLojaByName = lojas.filter(loja => loja.nome === searchInput);
  const searchLojaByCat = lojas.filter(loja => loja.categoria === searchCat || loja.categoria2 === searchCat);

  return (
    <>
      <Header />
        <main>
        {modalContent && (
            <>
              <div className={modal ? "modal-bg show-modal" : "modal-bg"}>
                <div className="modal">
                  <GrClose size={30} color="#485860" className="modal-icon" onClick={() => setModal(false)} />
                    <div className="modal-content">
                      <div className="logo">
                        <img src={modalContent.logo} alt={modalContent.nome} />
                      </div>
                      <div className="info">
                        <h1>{modalContent.nome}</h1>
                        <p>{modalContent.categoria}</p>
                        <br />
                        <p className="categoria">{modalContent.info}</p>
                        <br />
                        <strong>{modalContent.piso}</strong>
                        <br /><br />
                        <p>{modalContent.telefone}</p>
                        <br />
                        <a href={modalContent.site} target="_blank" rel="noopener noreferrer">{modalContent.site}</a>
                        <br /><br />
                        <ul className="modal-redes-sociais">
                          {modalContent.instagram !== '' && (
                            <li>
                              <a href={modalContent.instagram} target="_blank" rel="noopener noreferrer">
                                <FaInstagram size={24} color="#485860" />
                              </a>
                            </li>
                          )}
                          {modalContent.facebook !== '' && (
                            <li>
                              <a href={modalContent.facebook} target="_blank" rel="noopener noreferrer">
                                <FaFacebookSquare size={24} color="#485860" />
                              </a>
                            </li>
                          )}
                          {modalContent.whatsapp !== '' && (
                            <li>
                              <a href={`https://api.whatsapp.com/send?phone=${modalContent.whatsapp}`} target="_blank" rel="noopener noreferrer">
                                <FaWhatsapp size={24} color="#485860" />
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                </div>
              </div>
            </>
        )}
          <div id="lojas-page">
            <div className="lojas-banner">
              <div className="lojas-filter">
                <div className="container"><div className="banner-menu"><ul><li>Home <AiFillCaretRight size={10} /> Lojas</li></ul></div></div>
                <div className="banner">
                  <h1>Lojas</h1>
                  <hr/>
                  <div className="shopping-info">
                    <FaRegClock size={40} className="banner-icon" /> <p>Aberto hoje das 10h às 22h<br /> e facultativo até as 22h</p> <span></span>
                    <FaMapMarkerAlt size={40} className="banner-icon" /> <p>Planeje sua visita <br /> <a href="/contato"><strong>VEJA O MAPA</strong></a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section id="todas-lojas">
            <div className="container">
              <form>
                <input 
                  type="text" 
                  name="loja"  
                    onChange={(e) => setSearchInput(e.target.value)}
                    onClick={() => setDisplay(!display)}
                    value={searchInput}
                  placeholder="Encontrar loja" />
                <select name="categoria" onChange={(e) => setSearchCat(e.target.value)}>
                  <option value="categoria">Categoria</option>
                  <option value="Academia">Academia</option>
                  <option value="Acessórios">Acessórios</option>
                  <option value="Acessórios de telefonia">Acessórios de telefonia</option>
                  <option value="Agência de Câmbio">Agência de Câmbio</option>
                  <option value="Agência de Viagens">Agência de Viagens</option>
                  {/* <option value="Artigos de Viagem">Artigos de Viagem</option> */}
                  {/* <option value="Artigos para o lar">Artigos para o lar</option> */}
                  {/* <option value="Análises Clínicas">Análises Clínicas</option> */}
                  <option value="Cabelereiro e Barbeiro">Cabelereiro e Barbeiro</option>
                  <option value="Calçados Unissex">Calçados Unissex</option>
                  <option value="Calçados Femininos">Calçados Femininos</option>
                  <option value="Cinema">Cinema</option>
                  <option value="Conserto de roupas">Conserto de roupas</option>
                  <option value="Consultório odontológico">Consultório odontológico</option>
                  {/* <option value="Delicatessen">Delicatessen</option> */}
                  <option value="Drogaria">Drogaria</option>
                  <option value="Entretenimento">Entretenimento</option>
                  <option value="Esmalteria">Esmalteria</option>
                  <option value="Estética">Estética</option>
                  <option value="Exames, vacinas e consultas">Exames, vacinas e consultas</option>
                  <option value="Lavanderia">Lavanderia</option>
                  <option value="Lotérica">Lotérica</option>
                  <option value="Malas e Acessórios">Malas e Acessórios</option>
                  <option value="Mini mercado">Mini mercado</option>
                  <option value="Moda feminina">Moda feminina</option>
                  <option value="Moda íntima">Moda íntima</option>
                  <option value="Moda masculina">Moda masculina</option>
                  <option value="Moda unissex">Moda unissex</option>
                  <option value="Móveis e Decoração">Móveis e Decoração</option>
                  <option value="Ótica">Ótica</option>
                  <option value="Óticas e Relógios">Óticas e Relógios</option> 
                  <option value="Papelaria e Gráfica">Papelaria e Gráfica</option>
                  <option value="Perfumaria e Cosméticos">Perfumaria e Cosméticos</option>
                  <option value="Petshop">Petshop</option>
                  <option value="Relojoaria">Relojoaria</option>
                  {/* <option value="Saúde">Saúde</option> */}
                  <option value="Serviços">Serviços</option>
                  <option value="SPA">SPA</option>
                  <option value="Telefones e acessórios">Telefones e acessórios</option>
                  
                  {/* {
                    lojas.map((loja, index) => (
                      <option key={index} name={index} value={loja.categoria}>{loja.categoria}</option>
                    ))
                  } */}
                </select>
                <button className="form-button" onClick={handleShowLojasByCat}>Buscar</button>
                { display && (
                    <div className={display ? "autocomplete autocomplete-show" : "autocomplete"}>
                      {options.filter(nome => nome.toLowerCase().indexOf(searchInput.toLowerCase()) > -1).map((value,index) => {
                        return (
                          <div key={index} data={index} onClick={() => resultSearch(value)}>
                            <span>{value}</span>
                          </div>
                        );
                      })}
                    </div> 
                  )
                }                
              </form>
              <div className="lojas-load-content">
                {!searchInput ? (
                    sortByName.slice(0, showLoja).map((loja, index) => (
                      <>
                        <div key={index} id={loja.id} onClick={(e) => handleModal(e, loja)}>
                          <div className="logo_loja"><img src={loja.logo} alt={loja.nome} width="100%" /></div>
                          <p>{loja.nome}</p>
                          <span>{loja.categoria}</span>
                        </div>
                      </>
                    ))
                  ) :
                  (
                    searchLojaByName.map((loja, index) => (
                      <>
                        <div key={index} id={loja.id} onClick={(e) => handleModal(e, loja)}>
                          <div className="logo_loja"><img src={loja.logo} alt={loja.nome} width="100%" /></div>
                          <p>{loja.nome}</p>
                          <span>{loja.categoria}</span>
                        </div>
                      </>
                    ))
                  ) 
                }
                {catDisplay && (
                    searchLojaByCat.map((loja, index) => (
                      <>
                        <div key={index} id={loja.id} onClick={(e) => handleModal(e, loja)}>
                          <div className="logo_loja"><img src={loja.logo} alt={loja.nome} width="100%" /></div>
                          <p>{loja.nome}</p>
                          <span>{loja.categoria}</span>
                        </div>
                      </>
                    ))   
                  )
                }
              </div>
              {showLoja >= lojas.length || !showLoja || searchInput ? '': <button className="form-button" onClick={handleShowMoreLojas}>Carregar mais</button>}
            </div>
          </section>  
        </main>
      <Footer />
    </>
  );
}

export default Lojas;