import React, {useState, useEffect, useMemo} from 'react';
import { FaRegClock, FaMapMarkerAlt, FaInstagram, FaFacebookSquare, FaWhatsapp } from 'react-icons/fa';
import { AiFillCaretRight } from 'react-icons/ai';
import { GrClose } from 'react-icons/gr';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import logo1 from '../../assets/Gastronomia/logos/Piazza-Italia.jpeg';
import logo2 from '../../assets/Gastronomia/logos/logo_franscafe_estiloearte_.png';
import logo3 from '../../assets/Gastronomia/logos/havana-01.png';
import logo4 from '../../assets/Gastronomia/logos/NAZCA-café.png';
import logo5 from '../../assets/Gastronomia/logos/Champions-League-Experience.png';
import logo6 from '../../assets/Gastronomia/logos/logo-burger-king.png';
import logo7 from '../../assets/Gastronomia/logos/Marca-Burger-1.png';
import logo8 from '../../assets/Gastronomia/logos/Cook.png';
import logo9 from '../../assets/Gastronomia/logos/mcdonalds-logo-meaning.png';
import logo10 from '../../assets/Gastronomia/logos/oak.jpg';
import logo11 from '../../assets/Gastronomia/logos/patroni-01.png';
import logo12 from '../../assets/Gastronomia/logos/GT_PrimoPollo_Logo.png';
import logo13 from '../../assets/Gastronomia/logos/Rice-01-1.png';
import logo14 from '../../assets/Gastronomia/logos/Rizzo-Italian-Grill.jpg';
import logo15 from '../../assets/Gastronomia/logos/taco-bell.png';
import logo16 from '../../assets/Gastronomia/logos/Terra-do-fogo.jpg';
import logo17 from '../../assets/Gastronomia/logos/the-cook-house.jpg';
import logo18 from '../../assets/Gastronomia/logos/The-steak-factory.png';
import logo19 from '../../assets/Gastronomia/logos/Vivenda-do-Camarão-01.jpg';
import logo20 from '../../assets/Gastronomia/logos/fabrica_full.png';
import logo21 from '../../assets/Gastronomia/logos/cafe-creme-logo.png';
import logo22 from '../../assets/Gastronomia/logos/Confraria-Manga-Rosa.png';
import logo23 from '../../assets/Gastronomia/logos/hiro.png';
import logo24 from '../../assets/Gastronomia/logos/Jeronimo.jpg';
import logo25 from '../../assets/Gastronomia/logos/KHARINA.png';
import logo26 from '../../assets/Gastronomia/logos/Mania-de-churrasco.png';
import logo27 from '../../assets/Gastronomia/logos/Quintal.jpg';
import logo28 from '../../assets/Gastronomia/logos/sale_pepe.png';
import logo29 from '../../assets/Gastronomia/logos/Cuor-di-Crema.png';
//import logo30 from '../../assets/Gastronomia/logos/DAPAVIRADA.jpg';
import logo31 from '../../assets/Gastronomia/logos/Hawk.png';
import logo32 from '../../assets/Gastronomia/logos/manai.jpg';
import logo33 from '../../assets/Gastronomia/logos/puertas-grill.PNG';
import logo34 from '../../assets/Gastronomia/logos/logo-burger-king.png';
import logo35 from '../../assets/Lojas/logos/CacauShow.png';

import './styles.css';

function Gastronomia(){

  const restaurantes = useMemo(
    () => [
      {
        id: 1,
        logo: logo1,
        nome: 'Piazza Italia – EM BREVE',
        categoria: 'Empório',
        info: 'Empório',
        piso: 'Térreo',
        telefone: '',
        site: '',
        instagram: '',
        facebook: '',
        whatsapp: ''
      },
      {
        id: 2,
        logo: logo2,
        nome: 'Fran’s Café',
        categoria: 'Cafeteria',
        info: 'Cafeteria brasileira, criada em 1972, que oferece ambiente aconchegante com cardápio variado.',
        piso: 'Térreo',
        telefone: '5184-2464',
        site: 'http://www.franscafe.com.br',
        instagram: 'https://www.instagram.com/franscafeparquedacidade',
        facebook: 'https://www.facebook.com/franscafeoficial',
        whatsapp: ''
      },
      {
        id: 3,
        logo: logo3,
        nome: 'Havanna',
        categoria: 'Cafeteria',
        categoria2: 'Doceria',
        info: 'Conhecida por seus alfajores, a Havanna é uma empresa multinacional que produz e comercializa produtos alimentícios típicos da Argentina.',
        piso: 'Térreo',
        telefone: '2158-5636',
        site: 'http://www.havanna.com.br',
        instagram: 'https://www.instagram.com/havannaparquedacidade',
        facebook: 'https://www.facebook.com/Havannaparquedacidade',
        whatsapp: ''
      },
      {
        id: 4,
        logo: logo4,
        nome: 'Nazca Café',
        categoria: 'Cafeteria',
        info: 'Uma cafeteria, focada em cafés especiais,trazendo novas experiências sensoriais com diversos tipos de cafés 100 % arábicos.',
        piso: 'Piso 1',
        telefone: '2158-5600',
        site: '',
        instagram: 'https://www.instagram.com/nazca.cafe',
        facebook: '',
        whatsapp: ''
      },
      {
        id: 5,
        logo: logo5,
        nome: 'Champions League Experience – EM BREVE',
        categoria: 'Bar',
        categoria2: 'Entretenimento',
        info: 'O espaço, com mil metros quadrados, terá uma série de itens para atrair e entreter os fãs da competição europeia de clubes de futebol. A experiência imersiva tem imagens históricas do campeonato, brincadeiras interativas, uma sala de eSports, além de uma área aberta ao público com restaurante, loja e produtos oficiais da Champions League.',
        piso: 'Térreo',
        telefone: '',
        site: 'https://championsexperiencebrasil.com.br',
        instagram: 'https://www.instagram.com/championsexperiencebrasil',
        facebook: 'https://www.facebook.com/ChampionsExperienceBrasil',
        whatsapp: ''
      },
      {
        id: 6,
        logo: logo6,
        nome: 'Burger King - Reabertura em breve',
        categoria: 'Fast-Food',
        info: 'Segunda maior rede de restaurantes especializada em fast-food, fundada nos Estados Unidos.',
        piso: 'Piso 2',
        telefone: '97429-8206',
        site: 'http://www.burgerking.com.br',
        instagram: 'https://www.instagram.com/burgerkingbrasil',
        facebook: 'https://www.facebook.com/BurgerKingBrasil',
        whatsapp: ''
      },
      {
        id: 7,
        logo: logo7,
        nome: 'Burger+',
        categoria: 'Fast-Food',
        info: 'Burger + carinhosamente é muito mais hambúrguer, preparado especialmente para você. Nosso diferencial o torna ainda mais delicioso, carne 100% fresca.',
        piso: 'Piso 2',
        telefone: '5184-0620',
        site: 'http://www.burgermais.com.br',
        instagram: 'https://www.instagram.com/burgermais',
        facebook: 'https://www.facebook.com/burgermais',
        whatsapp: ''
      },
      {
        id: 8,
        logo: logo8,
        nome: 'Cook4U',
        categoria: 'Fast-Food',
        info: 'O Cook4U oferece uma alternativa de alimentação saborosa, natural e saudável para todo tipo de preferência e necessidades alimentares. Opções veganas e com proteínas animais, você é quem decide e monta sua própria refeição.',
        piso: 'Piso 2',
        telefone: '98909-4855',
        site: '',
        instagram: 'https://www.instagram.com/cook4ubr',
        facebook: 'https://www.facebook.com/cook4ubr',
        whatsapp: ''
      },
      {
        id: 9,
        logo: logo9,
        nome: 'Mc Donalds',
        categoria: 'Fast-Food',
        info: 'Maior e mais conhecida empresa de serviço rápido de alimentação do mundo.',
        piso: 'Piso 2',
        telefone: '2158-5555',
        site: 'http://www.mcdonalds.com.br',
        instagram: 'https://www.instagram.com/mcdonalds_br',
        facebook: 'https://www.facebook.com/McDonaldsBrasil',
        whatsapp: ''
      },
      {
        id: 10,
        logo: logo10,
        nome: 'OAKBERRY',
        categoria: 'Fast-Food',
        info: 'A OAKBERRY AÇAI BOWLS é um fast-food saudável, presente em mais de 5 países e com mais de 100 lojas.',
        piso: 'Térreo',
        telefone: '',
        site: 'https://oakberry.com/',
        instagram: 'https://www.instagram.com/oakberryacai',
        facebook: 'https://www.facebook.com/oakberryacai',
        whatsapp: ''
      },
      {
        id: 11,
        logo: logo11,
        nome: 'Patroni',
        categoria: 'Fast-Food',
        info: 'Fundada em 1984, a Patroni Pizza conquistou a preferência de seus clientes com a qualidade de seus produtos e um atendimento personalizado.',
        piso: 'Piso 2',
        telefone: '2158-5617',
        site: 'http://www.patronipizza.com.br',
        instagram: 'https://www.instagram.com/patronipizzaoficial',
        facebook: 'https://www.facebook.com/PatroniOficial',
        whatsapp: ''
      },
      {
        id: 12,
        logo: logo12,
        nome: 'Primo Pollo',
        categoria: 'Fast-Food',
        info: 'Um sabor inesquecível, baseado na alta qualidade e agilidade no atendimento, como o bom fast-food deve ser.',
        piso: 'Piso 2',
        telefone: '5184-2457',
        site: '',
        instagram: 'https://www.instagram.com/primopollo',
        facebook: 'https://www.facebook.com/primopollogaleteria',
        whatsapp: ''
      },
      {
        id: 13,
        logo: logo13,
        nome: 'Rice Risoteria - Reabertura em breve',
        categoria: 'Fast-Food',
        info: 'Rice Risoteria Urbano, nossa primeira loja. Conheça um novo jeito de se alimentar.',
        piso: 'Piso 2',
        telefone: '2158-5615',
        site: 'http://www.ricerisoteria.com.br',
        instagram: 'https://www.instagram.com/rice_risoteria',
        facebook: 'https://www.facebook.com/RiceShoppingParqueDaCidade',
        whatsapp: ''
      },
      {
        id: 14,
        logo: logo14,
        nome: 'Rizzo Italian Grill',
        categoria: 'Fast-Food',
        info: 'Redes de fast-food com ingredientes frescos, rapidez, qualidade, pratos especiais e reconhecimento no mercado.',
        piso: 'Piso 2',
        telefone: '(11) 5184-0620',
        site: 'http://www.rizzogourmet.com.br',
        instagram: '',
        facebook: 'https://www.facebook.com/rizzoitaliangourmetoficial',
        whatsapp: ''
      },
      {
        id: 15,
        logo: logo15,
        nome: 'TACO BELL – EM BREVE',
        categoria: 'Fast-Food',
        info: 'Uma rede de fast-food californiana, inspirada na culinária mexicana.',
        piso: 'Piso 2',
        telefone: '',
        site: 'http://www.tacobellbrasil.com.br',
        instagram: 'https://www.instagram.com/tacobellbrasil',
        facebook: 'https://www.facebook.com/tacobellbrasil',
        whatsapp: ''
      },
      {
        id: 16,
        logo: logo16,
        nome: 'Terra do Fogo',
        categoria: 'Fast-Food',
        info: 'O restaurante proporciona a experiência única de degustar a tradicional Parrilla argentina preparada em frente ao cliente.',
        piso: 'Piso 2',
        telefone: '5184-2457',
        site: 'http://www.restauranteterradofogo.com.br',
        instagram: 'https://www.instagram.com/restauranteterradofogo',
        facebook: 'https://www.facebook.com/terradofogogrelhados',
        whatsapp: ''
      },
      {
        id: 17,
        logo: logo17,
        nome: 'The Cook House - Reabertura em breve',
        categoria: 'Fast-Food',
        info: 'Seja bem-vindo ao The Cookhouse. Aqui você encontra os sabores, aromas e peculiaridades da cozinha contemporânea que prima pela estética gastronômica. Nossos produtos são de excelente qualidade, sabor, intensidade, ingredientes frescos e selecionados.',
        piso: 'Piso 2',
        telefone: '(11) 97155-4315',
        site: '',
        instagram: 'https://www.instagram.com/thecookhouse_sp',
        facebook: 'https://www.facebook.com/The-Cookhouse-384990262105074',
        whatsapp: ''
      },
      {
        id: 18,
        logo: logo18,
        nome: 'The Steak Factory',
        categoria: 'Fast-Food',
        info: 'Uma steakhouse premium com diferentes tipos de grelhados e burger rústico.',
        piso: 'Piso 2',
        telefone: '2158-5616',
        site: 'http://www.thesteakfactory.com.br',
        instagram: 'https://www.instagram.com/thesteakfactory',
        facebook: 'https://www.facebook.com/thesteakfactory',
        whatsapp: ''
      },
      {
        id: 19,
        logo: logo19,
        nome: 'Vivenda Do Camarão',
        categoria: 'Fast-Food',
        info: 'A Vivenda do Camarão surgiu com o intuito de oferecer a sofisticação e a qualidade do camarão a um preço acessível.',
        piso: 'Piso 2',
        telefone: '11 96445-8363',
        site: 'http://www.vivendadocamarao.com.br',
        instagram: 'https://www.instagram.com/vivendadocamaraooficial',
        facebook: 'https://www.facebook.com/vivendacamarao',
        whatsapp: ''
      },
      {
        id: 20,
        logo: logo20,
        nome: 'Fábrica Di Chocolate - Reabertura em breve',
        categoria: 'Fondue',
        info: 'A Fábrica Di Chocolate é a maior rede de fondue express do mundo e com a cara do Brasil. Aquele fondue gostoso, com frutas frescas ou um sorvete delicioso, feito com o melhor e mais saudável chocolate. Experimente e apaixone-se!',
        piso: 'Piso 1',
        telefone: '',
        site: 'http://www.fabricadichocolate.com.br',
        instagram: '',
        facebook: '',
        whatsapp: ''
      },
      {
        id: 21,
        logo: logo21,
        nome: 'Caffé Creme',
        categoria: 'Restaurante',
        info: 'Espaço múltiplo com salgados, doces, lanches diversos, com diferentes formas de servir café, de um jeito descontraído e cosmopolita.',
        piso: 'Piso 2',
        telefone: '',
        site: 'http://www.cafecreme.com.br',
        instagram: 'https://www.instagram.com/caffecremeparquedacidade',
        facebook: 'https://www.facebook.com/cafecremeoficial',
        whatsapp: '551151810608'
      },
      {
        id: 22,
        logo: logo22,
        nome: 'Confraria Manga Rosa',
        categoria: 'Restaurante',
        info: 'Variado Buffet com ingredientes frescos e selecionados.',
        piso: 'Térreo',
        telefone: '(11) 5184-0708',
        site: 'https://confrariamangarosa.com.br/',
        instagram: 'https://www.instagram.com/confrariamangarosa',
        facebook: 'https://www.facebook.com/confrariamangarosa',
        whatsapp: ''
      },
      {
        id: 23,
        logo: logo23,
        nome: 'Hiro - Reabertura em breve',
        categoria: 'Restaurante',
        info: 'O Hiro busca o equilíbrio entre o tradicional e o moderno para oferecer aos seus clientes o melhor da culinária japonesa.',
        piso: 'Piso 2',
        telefone: '5184-2446',
        site: 'http://www.restaurantehiro.com.br',
        instagram: 'https://www.instagram.com/sushihiroshioficial',
        facebook: 'https://www.facebook.com/hirorestaurante',
        whatsapp: ''
      },
      {
        id: 24,
        logo: logo24,
        nome: 'Jeronimo',
        categoria: 'Restaurante',
        categoria2: 'Lanchonete',
        info: 'A paixão do Jeronimo é criar burgers que todo mundo adora. Feitos na hora, só com ingredientes frescos e naturais.',
        piso: 'Térreo',
        telefone: 'Restaurante: (11) 2158-5641 Delivery: (11) 2158-5644',
        site: 'http://www.jeronimoburger.com.br',
        instagram: 'https://www.instagram.com/jeronimoburger',
        facebook: 'https://www.facebook.com/jeronimoburger',
        whatsapp: ''
      },
      {
        id: 25,
        logo: logo25,
        nome: 'Kharina Burgers Steaks Salads',
        categoria: 'Restaurante',
        categoria2: 'Lanchonete',
        info: 'Rede de restaurantes curitibano com garantia de frescor e qualidade, desde a matéria-prima, para proporcionar os melhores sabores de cada prato.',
        piso: 'Piso 1',
        telefone: '5181-0799',
        site: 'http://www.kharina.com.br',
        instagram: 'https://www.instagram.com/kharinaoficial',
        facebook: 'https://www.facebook.com/kharinaoficial',
        whatsapp: ''
      },
      {
        id: 26,
        logo: logo26,
        nome: 'Mania De Churrasco - Reabertura em breve',
        categoria: 'Restaurante',
        categoria2: 'Lanchonete',
        info: 'Rede de fast food especializada em churrasco, esta é a proposta da Mania de Churrasco que oferece carne de primeira em ambientes informais.',
        piso: 'Piso 2',
        telefone: '',
        site: 'http://www.maniadechurrasco.com.br',
        instagram: 'https://www.instagram.com/maniadechurrascoprime',
        facebook: 'https://www.facebook.com/ManiaDeChurrascoPrimeSteakHouse',
        whatsapp: ''
      },
      {
        id: 27,
        logo: logo27,
        nome: 'Quintal da Cidade',
        categoria: 'Restaurante',
        info: 'O Quintal oferece pratos tradicionais como: risotos, massas caseiras, parmegianas juntamente com opções mais leves e saudáveis como: omeletes, saladas e tortas. O hamburguer de shimeji é o nosso coringa para todo tipo de paladar. Experimente nossos pratos preparados diariamente, com ingredientes frescos e de excelente qualidade.',
        piso: 'Piso 2',
        telefone: '2158-5555',
        site: '',
        instagram: 'https://www.instagram.com/quintaldacidadebr',
        facebook: 'https://www.facebook.com/quintaldacidadebr',
        whatsapp: ''
      },
      {
        id: 28,
        logo: logo28,
        nome: 'Sale & Pepe Cucina Italiana',
        categoria: 'Restaurante',
        info: 'Um conceito que resgata toda a hospitalidade e qualidade da essência italiana. No cardápio, os sabores da Europa são apresentados com grande variedade de pratos com entradas, pastas, massas, risotos, carnes e peixes com opções veganas e vegetarianas.',
        piso: 'Térreo',
        telefone: '2158-5646',
        site: 'http://www.ristorantesaleepepe.com.br',
        instagram: 'https://www.instagram.com/saleepepecucinaitaliana',
        facebook: 'https://www.facebook.com/SaleePepeCucinaItaliana',
        whatsapp: ''
      },
      {
        id: 29,
        logo: logo29,
        nome: 'Cuor di Crema',
        categoria: 'Sorveteria',
        categoria2: 'Cafeteria',
        info: 'Sorvetes artesanais italianos feito com produtos 100% naturais, sem adição de aromatizantes, corantes e conservantes.',
        piso: 'Térreo',
        telefone: '5184-2465',
        site: 'http://www.cuordicrema.com.br',
        instagram: 'https://www.instagram.com/cuordicrema.parquedacidade',
        facebook: 'https://www.facebook.com/cuordicremabr',
        whatsapp: ''
      },
      // {
      //   id: 30,
      //   logo: logo30,
      //   nome: 'Da Pá Virada',
      //   categoria: 'Sorveteria',
      //   categoria2: 'Cafeteria',
      //   info: 'Gelatos puros, naturais e gostosos, produzidos artesanalmente, com técnica italiana e sotaque contemporâneo. E outros produtos deliciosos e surpreendentes!',
      //   piso: 'Térreo',
      //   telefone: '5184-2470',
      //   site: 'https://www.pavirada.com.br',
      //   instagram: 'https://www.instagram.com/dapaviradagelateria',
      //   facebook: 'https://www.facebook.com/dapaviradagelateria',
      //   whatsapp: ''
      // },
      {
        id: 31,
        logo: logo31,
        nome: `Hawke's`,
        categoria: 'Vinhos e acessórios',
        info: "Com bom humor e leveza, a marca Hawke's quer levar até você a sofisticação do vinho através de roupas e acessórios para que você possa vivenciar em vários momentos sua paixão.",
        piso: 'Térreo',
        telefone: '11 99027-4422',
        site: 'https://www.hawkes.com.br',
        instagram: 'https://www.instagram.com/hawkes.co',
        facebook: 'https://www.facebook.com/hawkes.co',
        whatsapp: ''
      },
      {
        id: 32,
        logo: logo32,
        nome: 'Manai - EM BREVE',
        categoria: 'Restaurante',
        info: "Cozinhamos com amor, inspirados na diversidade de ingredientes e de paladares. Nossas receitas são feitas diariamente em cada uma de nossas casas com ingredientes fresquinhos e da melhor qualidade.",
        piso: 'Piso 2',
        telefone: '',
        site: 'https://www.manaigastronomia.com.br',
        instagram: 'https://www.instagram.com/manaigastronomia',
        facebook: 'https://www.facebook.com/pages/Manai-Gastronomia/1620536221563016',
        whatsapp: ''
      },
      {
        id: 33,
        logo: logo33,
        nome: 'Puertas Grill',
        categoria: 'Fast-Food',
        info: "A mais nova opção de Fast Food atende com sistema de sistema Self Service e destaca-se por apresentar opções saudáveis com ingredientes frescos e selecionados, além da variedade de grelhados com opção de grelhar a carne na hora.",
        piso: 'Piso 2',
        telefone: '',
        site: '',
        instagram: '',
        facebook: '',
        whatsapp: ''
      },
      {
        id: 34,
        logo: logo34,
        nome: 'Burger King (Quiosque)',
        categoria: 'Fast-Food',
        info: 'Segunda maior rede de restaurantes especializada em fast-food, fundada nos Estados Unidos.',
        piso: '',
        telefone: '',
        site: 'http://www.burgerking.com.br',
        instagram: 'https://www.instagram.com/burgerkingbrasil',
        facebook: 'https://www.facebook.com/BurgerKingBrasil',
        whatsapp: ''
      },
      {
        id: 35,
        logo: logo35,
        nome: 'Cacau Show',
        categoria: 'Bomboniere',
        categoria2: 'Cafeteria',
        info: 'Marca nacional de chocolates presente em quase todos os estados brasileiros. Para a empresa, cada chocolate é um momento de felicidade, uma expressão de cuidado e carinho.',
        piso: 'Piso 2',
        telefone: '2158-5634',
        site: 'https://www.cacaushow.com.br',
        instagram: 'https://www.instagram.com/cacaushow/',
        facebook: 'https://www.facebook.com/CacauShow/',
        whatsapp: ''
      },
      {
        id: 36,
        logo: logo9,
        nome: 'Mc Donalds (Quiosque)',
        categoria: 'Sorveteria',
        info: 'Maior e mais conhecida empresa de serviço rápido de alimentação do mundo.',
        piso: '',
        telefone: '',
        site: 'http://www.mcdonalds.com.br',
        instagram: 'https://www.instagram.com/mcdonalds_br',
        facebook: 'https://www.facebook.com/McDonaldsBrasil',
        whatsapp: ''
      }
    ],
    []
  );

  const sortByName = restaurantes.sort((a, b) => {
    return a.nome.localeCompare(b.nome);
  });

  const [showRestaurante, setShowRestaurante] = useState(4);
  const [display, setDisplay] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [searchCat, setSearchCat] = useState('');
  const [catDisplay, setCatDisplay] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState(false);

  function handleShowMoreRestaurantes() {
    setShowRestaurante(moreRestaurantes => moreRestaurantes + 4);
  }

  function handleShowRestaurantesByCat(e){
    //setSearchCat(searchCat);
    setSearchInput('');
    setCatDisplay(true);
    setDisplay(false);
    setShowRestaurante(false);
    e.preventDefault();
  }

  function handleModal(e, restaurante){
    setModalContent(restaurante);
    setModal(true);
  }

  useEffect(() => {
    const suggestions = [];
    restaurantes.forEach(function (restaurante) {
      suggestions.push(restaurante.nome);    
    });
    setOptions(suggestions);
  }, [restaurantes]);

  const resultSearch = item => {
    setSearchInput(item);
    setDisplay(false);
  }

  const searchRestauranteByName = restaurantes.filter(restaurante => restaurante.nome === searchInput);
  const searchRestauranteByCat = restaurantes.filter(restaurante => restaurante.categoria === searchCat || restaurante.categoria2 === searchCat);




  return (
    <>
      <Header />
        <main>
        {modalContent && (
            <>
              <div className={modal ? "modal-bg show-modal" : "modal-bg"}>
                <div className="modal">
                  <GrClose size={30} color="#485860" className="modal-icon" onClick={() => setModal(false)} />
                    <div className="modal-content">
                      <div className="logo">
                        <img src={modalContent.logo} alt={modalContent.nome} />
                      </div>
                      <div className="info">
                        <h1>{modalContent.nome}</h1>
                        <p>{modalContent.categoria}</p>
                        <br />
                        <p className="categoria">{modalContent.info}</p>
                        <br />
                        <strong>{modalContent.piso}</strong>
                        <br /><br />
                        <p>{modalContent.telefone}</p>
                        <br />
                        <a href={modalContent.site} target="_blank" rel="noopener noreferrer">{modalContent.site}</a>
                        <br /><br />
                        <ul className="modal-redes-sociais">
                          {modalContent.instagram !== '' && (
                            <li>
                              <a href={modalContent.instagram} target="_blank" rel="noopener noreferrer">
                                <FaInstagram size={24} color="#485860" />
                              </a>
                            </li>
                          )}
                          {modalContent.facebook !== '' && (
                            <li>
                              <a href={modalContent.facebook} target="_blank" rel="noopener noreferrer">
                                <FaFacebookSquare size={24} color="#485860" />
                              </a>
                            </li>
                          )}
                          {modalContent.whatsapp !== '' && (
                            <li>
                              <a href={`https://api.whatsapp.com/send?phone=${modalContent.whatsapp}`} target="_blank" rel="noopener noreferrer">
                                <FaWhatsapp size={24} color="#485860" />
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                </div>
              </div>
            </>
        )}
          <div id="gastronomia-page">
            <div className="gastronomia-banner">
              <div className="gastronomia-filter">
              <div className="container"><div className="banner-menu"><ul><li>Home <AiFillCaretRight size={10} /> Alimentação</li></ul></div></div>
                <div className="banner">
                  <h1>Gastronomia</h1>
                  <hr/>
                  <div className="shopping-info">
                    <FaRegClock size={40} className="banner-icon" /> <p>Aberto hoje das 10h às 22h<br /> e facultativo até as 22h</p> <span></span>
                    <FaMapMarkerAlt size={40} className="banner-icon" /> <p>Planeje sua visita <br /> <a href="/contato"><strong>VEJA O MAPA</strong></a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section id="todas-gastronomia">
            <div className="container">
              <form>
                <input 
                  type="text" 
                  name="restaurante"
                  onChange={(e) => setSearchInput(e.target.value)}
                  onClick={() => setDisplay(!display)}
                  value={searchInput}
                  placeholder="Encontrar restaurante" />
                <select name="categoria" onChange={(e) => setSearchCat(e.target.value)}>
                  <option value="categoria">Categoria</option>
                  {/* <option value="Alimentos especiais">Alimentos especiais</option> */}
                  <option value="Bar">Bar</option>
                  <option value="Bomboniere">Bomboniere</option>
                  <option value="Cafeteria">Cafeteria</option>
                  <option value="Doceria">Doceria</option>
                  <option value="Empório">Empório</option>
                  <option value="Entretenimento">Entretenimento</option>
                  <option value="Fast-Food">Fast-Food</option>
                  <option value="Fondue">Fondue</option>
                  <option value="Lanchonete">Lanchonete</option>
                  <option value="Restaurante">Restaurante</option>
                  <option value="Sorveteria">Sorveteria</option>
                  <option value="Vinhos e acessórios">Vinhos e acessórios</option>
                  {/* {
                    restaurantes.map((restaurante, index) => (
                      <option key={index} name={index} value={restaurante.categoria}>{restaurante.categoria}</option>
                    ))
                  } */}
                </select>
                <button className="form-button" onClick={handleShowRestaurantesByCat}>Buscar</button>
                { display && (
                    <div className={display ? "autocomplete autocomplete-show" : "autocomplete"}>
                      {options.filter(nome => nome.toLowerCase().indexOf(searchInput.toLowerCase()) > -1).map((value,index) => {
                        return (
                          <div key={index} data={index} onClick={() => resultSearch(value)}>
                            <span>{value}</span>
                          </div>
                        );
                      })}
                    </div> 
                  )
                }                
              </form>
              <div className="gastronomia-load-content">
                {!searchInput ? (
                    sortByName.slice(0, showRestaurante).map((restaurante, index) => (
                      <>
                        <div key={index} id={restaurante.id} onClick={(e) => handleModal(e, restaurante)}>
                          <div className="logo_restaurante"><img src={restaurante.logo} alt={restaurante.nome} width="100%" /></div>
                          <p>{restaurante.nome}</p>
                          <span>{restaurante.categoria}</span>
                        </div>
                      </>
                    ))
                  ) :
                  (
                    searchRestauranteByName.map((restaurante, index) => (
                      <>
                        <div key={index} id={restaurante.id} onClick={(e) => handleModal(e, restaurante)}>
                          <div className="logo_restaurante"><img src={restaurante.logo} alt={restaurante.nome} width="100%" /></div>
                          <p>{restaurante.nome}</p>
                          <span>{restaurante.categoria}</span>
                        </div>
                      </>
                    ))
                  ) 
                }
                {catDisplay && (
                    searchRestauranteByCat.map((restaurante, index) => (
                      <>
                        <div key={index} id={restaurante.id} onClick={(e) => handleModal(e, restaurante)}>
                          <div className="logo_restaurante"><img src={restaurante.logo} alt={restaurante.nome} width="100%" /></div>
                          <p>{restaurante.nome}</p>
                          <span>{restaurante.categoria}</span>
                        </div>
                      </>
                    ))   
                  )
                }
              </div>
              {showRestaurante >= restaurantes.length || !showRestaurante || searchInput ? '' : <button className="form-button" onClick={handleShowMoreRestaurantes}>Carregar mais</button>}
            </div>
          </section>
        </main>
      <Footer />
    </>
  );
}

export default Gastronomia;