import React from 'react';
import { FaRegClock, FaMapMarkerAlt } from 'react-icons/fa';
import { AiFillCaretRight } from 'react-icons/ai';

import Header from '../../components/Header/Header';
import Filmes from '../../components/Filmes/Filmes';
import Footer from '../../components/Footer/Footer';


import '../Cinema/styles.css';

function CinemaProg(){
  return (
    <>
     <Header />
      <main>
        <div id="cinema-programacao-page">
          <div className="cinema-banner">
            <div className="cinema-filter">
              <div className="container"><div className="banner-menu"><ul><li>Home <AiFillCaretRight size={10} /> Cinema <AiFillCaretRight size={10} /> Programação</li></ul></div></div>
              <div className="banner">
                <span>Cinema</span>
                <h1>Programação</h1>
                <hr/>
                <div className="cinema-page-content">
                  <div>
                    <p>Confira os filmes em cartaz</p>
                  </div>
                  <div className="shopping-info">
                    <FaRegClock size={40} className="slide-icon" /> <p>Aberto de Quinta à Domingo <br /> das 12h às 20h</p> <span></span>
                    <FaMapMarkerAlt size={40} className="slide-icon" /> <p>Planeje sua visita <br /> <a href="/contato"><strong>VEJA O MAPA</strong></a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Filmes />
      </main>
    <Footer />
   </>
 );
}

export default CinemaProg;