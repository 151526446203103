import React from 'react';
import { Link } from 'react-router-dom';
import { AiFillCaretRight } from 'react-icons/ai';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import lifecenter from '../../assets/Home/life_center.jpg';
import temnoparque1 from '../../assets/Tem-no-Parque/tem_no_parque1.jpg';
import temnoparque2 from '../../assets/Tem-no-Parque/tem_no_parque2.jpg';
import temnoparque3 from '../../assets/Tem-no-Parque/tem_no_parque3.jpg';
import temnoparque4 from '../../assets/Tem-no-Parque/tem_no_parque4.jpg';


import '../Sustentabilidade/styles.css';

function LifeCenter(){
  return (
    <>
      <Header />
      <main>
        <div id="tem-no-parque-life-center-page">
          <div className="tem-no-parque-banner">
            <div className="tem-no-parque-filter">
              <div className="container"><div className="banner-menu"><ul><li>Home <AiFillCaretRight size={10} /> Tem no Parque <AiFillCaretRight size={10} /> Primeiro Life Center do Brasil</li></ul></div></div>
              <div className="banner">
                <span>Tem no Parque</span>
                <h1>O Primeiro Life Center do Brasil</h1>
                <hr/>
                <p>Reunimos as características de shopping e de parque</p>
              </div>
            </div>
          </div>
        </div>
        <section id="tem-no-parque">
          <div className="container">
            <div className="tem-no-parque-content">
              <div className="tem-no-parque">
                <p>Nós somos o Shopping Parque da Cidade. O primeiro LIFE CENTER do Brasil, um conceito que reúne as características de shopping (lojas, serviços e entretenimento) e de parque (local arborizado, tranquilo e sustentável).</p>
                <p>Acreditamos que para nossos clientes, que estão alinhados a esse novo pensamento, uma compra não é só uma compra: ela precisa ter propósito, precisar estar ligada a uma experiência, ser feita com consciência e preocupação com o meio ambiente, as pessoas e o nosso entorno.</p>
              </div>
              <div>
                <img src={lifecenter} alt="Life Center" width="100%" />
              </div>
            </div>
          </div>
        </section>
        <section id="tem-no-parque-box">
          <div className="tem-no-parque-box-content">
            <Link to="/mataatlantica">
              <div style={{backgroundImage:`url(${temnoparque1})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Mata Atlântica</h1>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/telhadoverde">
              <div style={{backgroundImage:`url(${temnoparque2})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Telhado Verde</h1>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/coletadelixo">
              <div style={{backgroundImage:`url(${temnoparque3})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Descarte Ecológico</h1>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/sanitarios">
              <div style={{backgroundImage:`url(${temnoparque4})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Economia de Água</h1>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default LifeCenter;