import React from 'react';
import { Link } from 'react-router-dom';
import { AiFillCaretRight } from 'react-icons/ai';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import temnoparque from '../../assets/Tem-no-Parque/sanitario_content.jpg';
import temnoparque1 from '../../assets/Tem-no-Parque/tem_no_parque1.jpg';
import temnoparque2 from '../../assets/Tem-no-Parque/tem_no_parque2.jpg';
import temnoparque3 from '../../assets/Tem-no-Parque/tem_no_parque3.jpg';
import temnoparque4 from '../../assets/Tem-no-Parque/tem_no_parque4.jpg';


import '../Sustentabilidade/styles.css';

function EconomiaDeAgua(){
  return (
    <>
      <Header />
      <main>
        <div id="tem-no-parque-sanitario-page">
          <div className="tem-no-parque-banner">
            <div className="tem-no-parque-filter">
              <div className="container"><div className="banner-menu"><ul><li>Home <AiFillCaretRight size={10} /> Tem no Parque <AiFillCaretRight size={10} /> Economia de Água</li></ul></div></div>
              <div className="banner">
                <span>Tem no Parque</span>
                <h1>Economia de Água</h1>
                <hr/>
                <p>Produtos de limpeza sustentáveis e economia de água</p>
              </div>
            </div>
          </div>
        </div>
        <section id="tem-no-parque">
          <div className="container">
            <div className="tem-no-parque-content">
              <div className="tem-no-parque">
                <p>Utilizamos produtos e sistemas de limpeza sustentáveis, que não agridem o meio ambiente e são até mais eficazes. A tecnologia usa geradores de ozônio em substituição ao cloro e é 3 mil vezes mais rápida e 35 vezes mais potente na eliminação de germes e bactérias. Ao mesmo tempo, equipamentos convertem eletricamente água e sal em uma solução ionizada que substitui produtos químicos convencionais.</p>
                <p>Nosso sistema a vácuo dos mictórios e sanitários utiliza apenas ¼ da água das soluções convencionais, o que garante a economia de mais de 1 milhão de litros por ano.</p>
              </div>
              <div>
                <img src={temnoparque} alt="Tem no Parque" width="100%" />
              </div>
            </div>
          </div>
        </section>
        <section id="tem-no-parque-box">
          <div className="tem-no-parque-box-content">
            <Link to="/mataatlantica">
              <div style={{backgroundImage:`url(${temnoparque1})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Mata Atlântica</h1>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/telhadoverde">
              <div style={{backgroundImage:`url(${temnoparque2})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Telhado Verde</h1>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/coletadelixo">
              <div style={{backgroundImage:`url(${temnoparque3})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Descarte Ecológico</h1>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/economiadeagua">
              <div style={{backgroundImage:`url(${temnoparque4})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Economia de Água</h1>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default EconomiaDeAgua;