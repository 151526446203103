import React, {useEffect, useState} from 'react';
import { parseString } from 'xml2js';
import slugify from 'react-slugify';
import api from './api';

import '../../pages/Cinema/styles.css';

function Filmes(){
  const [programacaoFilmes, setProgramacaoFilmes] = useState([]);


  useEffect(() => {

    //let dataAtual = new Date();

    // function getMonth(date) {
    //   let mes = dataAtual.getMonth() + 1;
    //   return mes < 10 ? '0' + mes : '' + mes;
    // }
    
    // function getDate(date) {
    //   let dia = dataAtual.getDate() + 1;
    //   return dia < 10 ? '0' + dia : '' + dia;
    // }
  
    //let dataFilmes = dataAtual.getFullYear()+''+getMonth()+''+getDate();

    //api.get(`/51/${dataFilmes}`)
    api.get(`/51`).then(function(response) {

      const filmesXml = response.data;
      

      parseString(filmesXml, function (err, result) {
        const filmesJSON = result.kinoplex.programacao;

        const filmesArray = filmesJSON.map(cinema => {
          return cinema.filmes[0].filme;
        });
        
        
        setProgramacaoFilmes(filmesArray[0]);

      });

    }).catch(function (error) {
      console.log(error);
    });
  }, []);


  return (
    <>
      <section id="programacao">
        <div className="container">
          <div className="filmes-content">
            {
              programacaoFilmes.map(item =>(
                <>
                  <div key={item['$']['id']}>
                    <a href="http://bit.ly/spdc_kinoplex" target="_blank" rel="noreferrer" title={item['titulo-portugues']}>
                      <div id={item['$']['id']}  className="capa-filme">
                          <img src={`https://www.kinoplex.com.br/filmes/images/cartaz/262x388/${slugify(item['titulo-portugues']).replace('---','-')}.jpg`} alt={item['titulo-portugues']} width='100%' />
                      </div>
                      <div className="titulo">
                        {item['titulo-portugues']}
                      </div>
                      {item['generos'].map(genero => (
                        <div className="genero">{genero['genero'][0]['$']['amigavel']} | {item['duracao']} min.<div className="classificacao">{item['classificacao']}</div></div>

                      ))} 
                      <span className="link" href="http://bit.ly/spdc_kinoplex" target="_blank">Ver Sessões</span>
                    </a>
                  </div>
                </>
              ))
            }
          </div>
          {/* <div className="erro">Programação indisponível no momento</div> */}
        </div>
      </section>
    </>
  );
}

export default Filmes;