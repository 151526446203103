import React, {useMemo, useState} from 'react';
import { Link } from 'react-router-dom';
import { AiFillCloseCircle, AiFillCaretRight } from 'react-icons/ai';
import { FaUserPlus} from 'react-icons/fa';


import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import ModalPfForm from '../../components/ModalPfForm/ModalPfForm';
import ModalPjForm from '../../components/ModalPjForm/ModalPjForm';

import iconBusiness from '../../assets/Clube-do-Parque/add_business.svg';
import logo1 from '../../assets/Lojas/logos/arranjo-express.png';
import logo2 from '../../assets/Lojas/logos/Atelie-das-Artes.jpg';
import logo3 from '../../assets/Lojas/logos/Best-Cell.jpg';
import logo4 from '../../assets/Lojas/logos/Cats.png';

import './styles.css';

function ClubedoParque(){

  const clubes =  useMemo(
    () => [
      {
        id: 1,
        logo: logo1,
        nome: 'Arranjos Express',
        info: '10% de desconto em serviços.',
        info2: ''
      },
      {
        id: 2,
        logo: logo2,
        nome: 'Ateliê das Artes',
        info: '10% de desconto em todos os produtos e serviços gráficos, exceto livros.',
        info2: ''
      },
      {
        id: 3,
        logo: logo3,
        nome: 'Best Cell',
        info: '10% de desconto no total da compra.',
        info2: ''
        
      },
      {
        id: 4,
        logo: logo4,
        nome: 'Cat`s Cabeleireiros',
        info: '15% de desconto nos serviços.',
        info2: 'Válido de segunda a quinta-feira (exceto feriados).'
      }
    ],
    []
  );

  const [modalPF, setModalPF] = useState(false);
  const [modalPJ, setModalPJ] = useState(false);

  const handleCadastroPf = () => {
    setModalPF(true);
  }

  const handleCadastroPj = () => {
    setModalPJ(true);
  }

  return(
    <>
      <Header />
      <main>

        {modalPF && (
          <>
            <ModalPfForm />
            <AiFillCloseCircle size={30} color="#ffffff" className="modal-icon-x" onClick={() => setModalPF(false)} />
          </>
        )}

        {modalPJ && (
          <>
            <ModalPjForm />
            <AiFillCloseCircle size={30} color="#ffffff" className="modal-icon-x" onClick={() => setModalPJ(false)} />
          </>
        )}
        <div id="clube-do-parque-page">
          <div className="clube-do-parque-banner">
            <div className="clube-do-parque-filter">
              <div className="container"><div className="banner-menu"><ul><li>Home <AiFillCaretRight size={10} /> Clube do Parque <AiFillCaretRight size={10} /> Sobre</li></ul></div></div>
              <div className="banner">
                <span>Clube do Parque</span>
                <h1>Sobre</h1>
                <hr/>
                <p>Clube de benefícios criado para quem frequenta o Shopping Parque da Cidade</p>
                <button><Link to="/beneficios">Confira os benefícios</Link></button>
              </div>
            </div>
          </div>
        </div>
        <section id="beneficios">
          <div className="container">
            <p>O Clube do Parque é o programa de benefício do Shopping Parque da Cidade.
            Criado para beneficiar quem trabalha na região do shopping e busca mais comodidade, aqui você terá benefícios exclusivos na praça de alimentação e descontos em lojas.
            Se a sua empresa já está cadastrada, faça o seu cadastro pessoal no link ao lado. 
            Se não, solicite ao seu RH.
            Faça o seu cadastro!</p>
            <div className="beneficios-content-grid">
              <div className="bg">
                <FaUserPlus size={40} color="#485860" />
                <p>Clique aqui e vire um membro<br /> do Clube do Parque!</p>
                <Link to="#cadastrese" onClick={handleCadastroPf}>Cadastre-se</Link>
              </div>
              <div className="bg">
                <img src={iconBusiness} alt="Add Business" width="40" />
                <p>Clique aqui e faça o cadastro da sua empresa para que seus funcionários possam virar membros do Clube do Parque. </p>
                <Link to="#cadastresuaempresa" onClick={handleCadastroPj}>Cadastre sua empresa</Link>
              </div>
            </div>
          </div>
        </section>
        <section id="confira">
          <div className="container">
            <h1>Confira alguns dos benefícios</h1>
            <div className="confira-content">
              {
                clubes.map((clube, index) => (
                  <>
                    <div key={index} id={clube.id}>
                      <div className="logo_clube"><img src={clube.logo} alt={clube.nome} width="100%" /></div>
                      <p>{clube.nome}</p>
                      <span>{clube.info}</span><br />
                      <span>{clube.info2}</span>
                    </div>
                  </>
                ))
              }
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default ClubedoParque;