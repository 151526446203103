import React from 'react';
import { Link } from 'react-router-dom';
import { AiFillCaretRight } from 'react-icons/ai';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import temnoparque from '../../assets/Tem-no-Parque/mata_atlantica_content.jpg';
import temnoparque1 from '../../assets/Tem-no-Parque/tem_no_parque1.jpg';
import temnoparque2 from '../../assets/Tem-no-Parque/tem_no_parque2.jpg';
import temnoparque3 from '../../assets/Tem-no-Parque/tem_no_parque3.jpg';
import temnoparque4 from '../../assets/Tem-no-Parque/tem_no_parque4.jpg';


import '../Sustentabilidade/styles.css';

function MataAtlantica(){
  return (
    <>
      <Header />
      <main>
        <div id="tem-no-parque-atlantica-page">
          <div className="tem-no-parque-banner">
            <div className="tem-no-parque-filter">
              <div className="container"><div className="banner-menu"><ul><li>Home <AiFillCaretRight size={10} /> Tem no Parque <AiFillCaretRight size={10} /> Mata Atlântica</li></ul></div></div>
              <div className="banner">
                <span>Tem no Parque</span>
                <h1>Mata Atlântica</h1>
                <hr/>
                <p>Um local tranquilo, seguro e com muito verde, para um passeio ou para espairecer. Isso é possível? Aqui, sim!</p>
              </div>
            </div>
          </div>
        </div>
        <section id="tem-no-parque">
          <div className="container">
            <div className="tem-no-parque-content">
              <div className="tem-no-parque">
                <p>Nós temos um ambiente aberto, claro, agradável, com grandes janelas que valorizam a luz natural. Aqui dentro há mais de 10 espécies de plantas originais da Mata Atlântica, entre elas: Flor de Maio, Palmito Jussara, Pitangueira, Palmeira Petrópolis e Jabuticabeira. </p>
                <p>Além disso, em todos os pisos do shopping, o mobiliário com peças exclusivas, de madeira maciça e certificada, ressalta como a destinação adequada e sustentável de troncos vegetais pode resultar em peças decorativas e confortáveis, respeitando a natureza.</p>
                <p>Essa é uma das experiências bacanas que te proporcionamos. É sensação de bem-estar e contato com a natureza em plena cidade de São Paulo.</p>
              </div>
              <div>
                <img src={temnoparque} alt="Tem no Parque" width="100%" />
              </div>
            </div>
          </div>
        </section>
        <section id="tem-no-parque-box">
          <div className="tem-no-parque-box-content">
            <Link to="/mataatlantica">
              <div style={{backgroundImage:`url(${temnoparque1})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Mata Atlântica</h1>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/telhadoverde">
              <div style={{backgroundImage:`url(${temnoparque2})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Telhado Verde</h1>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/coletadelixo">
              <div style={{backgroundImage:`url(${temnoparque3})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Descarte Ecológico</h1>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/sanitarios">
              <div style={{backgroundImage:`url(${temnoparque4})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Economia de Água</h1>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default MataAtlantica;