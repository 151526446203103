import React from 'react';
import { Link } from 'react-router-dom';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
//import { FaRegClock, FaMapMarkerAlt } from 'react-icons/fa';

import 'swiper/swiper-bundle.css';
import './SlideHome.css';

function SlideHome() {
  SwiperCore.use([Pagination, Autoplay]);

  return (
    <Swiper slidesPerView={1} pagination={{ clickable: true }} autoplay>
      <SwiperSlide className="slide1">
        <div className="slide-filter">
          <div className="slides">
            {/* <span>Cinema</span> */}
            <h1>Cinema</h1>
            <hr/>
            <div className="slide-content">
              <div>
                <p>Clube do Parque com 50% de desconto</p>
                <button><Link to="/clubedoparque">Saiba mais</Link></button>
              </div>
              {/* <div className="shopping-info">
                <FaRegClock size={40} className="slide-icon" /> <p>Aberto de Quinta à Domingo <br /> das 12h às 20h</p> <span></span>
                <FaMapMarkerAlt size={40} className="slide-icon" /> <p>Planeje sua visita <br /> <strong>VEJA O MAPA</strong></p>
              </div> */}
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide className="slide2">
        <div className="slide-filter">
          <div className="slides">
            {/* <span>Nossos horários</span> */}
            <h1>Nossos horários</h1>
            <hr/>
            <div className="slide-content">
              <div>
                <p>Saiba quais são</p>
                <button><Link to="/nossoshorarios">Saiba mais</Link></button>
              </div>
              {/* <div className="shopping-info">
                <FaRegClock size={40} className="slide-icon" /> <p>Aberto de Quinta à Domingo <br /> das 12h às 20h</p> <span></span>
                <FaMapMarkerAlt size={40} className="slide-icon" /> <p>Planeje sua visita <br /> <strong>VEJA O MAPA</strong></p>
              </div> */}
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide className="slide3">
        <div className="slide-filter">
          <div className="slides">
            {/* <span>Sustentabilidade</span> */}
            <h1>Sustentabilidade</h1>
            <hr/>
            <div className="slide-content">
              <div>
                <p>Nosso diferencial</p>
                <button><Link to="/sustentabilidade">Saiba mais</Link></button>
              </div>
              {/* <div className="shopping-info">
                <FaRegClock size={40} className="slide-icon" /> <p>Aberto de Quinta à Domingo <br /> das 12h às 20h</p> <span></span>
                <FaMapMarkerAlt size={40} className="slide-icon" /> <p>Planeje sua visita <br /> <strong>VEJA O MAPA</strong></p>
              </div> */}
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide className="slide4">
        <div className="slide-filter">
          <div className="slides">
            {/* <span>Biblioteca do Parque</span> */}
            <h1>Biblioteca do Parque</h1>
            <hr/>
            <div className="slide-content">
              <div>
                <p>Incentivo a leitura</p>
                <button><Link to="/bibliotecadoparque">Saiba mais</Link></button>
              </div>
              {/* <div className="shopping-info">
                <FaRegClock size={40} className="slide-icon" /> <p>Aberto de Quinta à Domingo <br /> das 12h às 20h</p> <span></span>
                <FaMapMarkerAlt size={40} className="slide-icon" /> <p>Planeje sua visita <br /> <strong>VEJA O MAPA</strong></p>
              </div> */}
            </div>
          </div>
        </div>
      </SwiperSlide>
      
    </Swiper>
  );
}

export default SlideHome;