import axios from 'axios';

const api = axios.create({
  baseURL: "https://cors-anywhere.herokuapp.com/https://api-sandbox.spotmetrics.com/api/app/v1/",
  headers: {
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "Content-Type": "application/json",
      "x-partner-key": 'e46c65fc-7ac0-43b4-b364-6f021831eda4'
  }
  // auth: {
  //     username: "admancriativa",
  //     password: "12345"
  // }
  //baseURL: 'http://localhost:5000'
});

export default api;