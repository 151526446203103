import React from 'react';
//import { isAuthenticated } from "./services/auth";

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import ScrollToTop from './components/Header/ScrollToTop';

//import Painel from './pages/Painel/Cadastro';
//import Endereco from './pages/Painel/Endereco';
//import AlterarSenha from './pages/Painel/AlterarSenha';

import Home from './pages/Home';
import Lojas from './pages/Lojas';
import Gastronomia from './pages/Gastronomia';
import Cinema from './pages/Cinema';
import CinemaProg from './pages/CinemaProg';
import Eventos from './pages/Eventos';
import Novidades from './pages/Novidades';
import BibliotecaDoParque from './pages/BibliotecaDoParque';
import NossosHorarios from './pages/NossosHorarios';
import Sustentabilidade from './pages/Sustentabilidade';
import LifeCenter from './pages/LifeCenter';
import MataAtlantica from './pages/MataAtlantica';
import TelhadoVerde from './pages/TelhadoVerde';
import ColetadeLixo from './pages/ColetadeLixo';
import Clinica from './pages/ClinicaEinstein';
import EconomiaDeAgua from './pages/EconomiaDeAgua';
import ClubeDoParque from './pages/ClubeDoParque';
import Beneficios from './pages/Beneficios';
import Servicos from './pages/Servicos';
import Contato from './pages/Contato';
import MidiaKit from './pages/MidiaKit';
import PoliticadePrivacidade from './pages/PoliticadePrivacidade';

// const PrivateRoute = ({ component: Component, ...rest }) => (
//   <Route
//     {...rest}
//     render={props =>
//       isAuthenticated() ? (
//         <Component {...props} />
//       ) : (
//         <Redirect to={{ pathname: "/", state: { from: props.location } }} />
//       )
//     }
//   />
// );

function Routes(){
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <Route path="/" exact component={Home}  />
          <Route path="/lojas" component={Lojas} />
          <Route path="/alimentacao" component={Gastronomia} />
          <Route path="/cinema" component={Cinema} />
          <Route path="/programacao" component={CinemaProg} />
          <Route path="/eventos" component={Eventos} />
          <Route path="/novidades" component={Novidades} />
          <Route path="/nossoshorarios" component={NossosHorarios} />
          <Route path="/bibliotecadoparque" component={BibliotecaDoParque} />
          <Route path="/lifecenter" component={LifeCenter} />
          <Route path="/sustentabilidade" component={Sustentabilidade} />
          <Route path="/mataatlantica" component={MataAtlantica} />
          <Route path="/telhadoverde" component={TelhadoVerde} />
          <Route path="/coletadelixo" component={ColetadeLixo} />
          <Route path="/economiadeagua" component={EconomiaDeAgua} />
          <Route path="/clinica-einstein" component={Clinica} />
          <Route path="/clubedoparque" component={ClubeDoParque} />
          <Route path="/beneficios" component={Beneficios} />
          <Route path="/servicos" component={Servicos} />
          <Route path="/contato" component={Contato} />
          <Route path="/midiakit" component={MidiaKit} />
          <Route path="/politicadeprivacidade" component={PoliticadePrivacidade} />
          <Redirect to="/" />

          {/* <PrivateRoute path="/painel" component={Painel} />
          <PrivateRoute path="/endereco" component={Endereco} />
          <PrivateRoute path="/alterarsenha" component={AlterarSenha} /> */}

        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default Routes;