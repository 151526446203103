import React from 'react';
import { AiFillCaretRight } from 'react-icons/ai';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

// import novidades1 from '../../assets/Home/polo_wear.jpg';
// import novidades2 from '../../assets/Home/gastronomia.jpg';
//import champions from '../../assets/Acontece-no-Parque/champions_banner.jpg';

import '../Eventos/styles.css';

function BibliotecaDoParque(){
  return (
    <>
      <Header />
      <main>
        <div id="acontece-no-parque-biblioteca-page">
          <div className="acontece-no-parque-novidades-banner">
            <div className="acontece-no-parque-novidades-filter">
              <div className="container"><div className="banner-menu"><ul><li>Home <AiFillCaretRight size={10} /> Acontece no Parque <AiFillCaretRight size={10} /> Biblioteca do Parque</li></ul></div></div>
              <div className="banner">
                <span>Acontece no Parque</span>
                <h1>Biblioteca do Parque</h1>
                <hr/>
                <p>Livros são como portais para os mais diversos mundos</p>
              </div>
            </div>
          </div>
        </div>
        <section id="informativo">
          <div className="container">
            <p>Uma floresta encantada, um vilarejo medieval, aquele mundo de criaturas mágicas ou quem sabe uma megacidade futurista com carros voando para todos os lados.</p>
            <p>Sabendo o potencial de poder mergulhar nas páginas dos livros e viajar para qualquer lugar, criamos um projeto para ajudar a doar magia e uma companhia inestimável ao próximo.</p>
            <p>A Biblioteca do Parque é uma estante colaborativa, em que você deixa aqueles seus livros que estão em casa, sem uso, para serem selecionados por qualquer visitante. E aí, claro, quem traz o seu também pode escolher algum exemplar que interesse, alguma história que nunca tenha lido, ou quem sabe aquela que vale a pena ler de novo, levar pra casa para aproveitar a viagem pela leitura. </p>
            <p>Tudo sem custo e sem burocracia nenhuma, só pela vontade de compartilhar grandes histórias.</p>
            <p>Nosso espaço, que é uma declaração de amor e um incentivo à literatura, fica no Coworking, ali no piso 1.</p>
            <p>E para sua total tranquilidade, garantimos que todos os exemplares são devidamente higienizados, seguindo todas as recomendações de segurança para sua proteção.</p>
            <p>Deixe um livro ser a sua melhor companhia!</p>
          </div>
        </section>
        {/* <section id="novidades">
          <div className="novidades-content">
            <div style={{backgroundImage:`url(${novidades1})`}}>
              <div className="novidades_filter">
                <div className="card">
                  <span>Novidades</span>
                  <h1>Polo Wear</h1>
                  <p>Inspiração tropical em tecidos leves protagonizam vestidos na primavera-verão 2021 Polo Wear.</p>
                  <button>Saiba mais</button>
                </div>
              </div>
            </div>
            <div style={{backgroundImage:`url(${novidades2})`}}>
              <div className="novidades_filter">
                <div className="card">
                  <span>Novidades</span>
                  <h1>Burguer +</h1>
                  <p>Aproveite as delícias da casa: tem fritas, hambúrguer para todos os gostos, shakes, hot-dogs e a opção de burger #vegetariano com legumes e soja!</p>
                  <button>Saiba mais</button>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section id="champions">
          <div className="champions-content" style={{backgroundImage:`url(${champions})`}}>
              <div className="filter">
                <div className="champions">
                  <h1>Champions League Experience</h1>
                  <p>Chegou ao Brasil um espaço inédito, imersivo e interativo para os fãs da principal competição europeia de futebol: a Champions League Experience Brasil.</p>
                  <button>Garanta seu ingresso</button>
                </div>
              </div>
          </div>
        </section> */}
      </main>
      <Footer />
    </>
  );
}

export default BibliotecaDoParque;