import React, {useState} from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import icon1 from '../../assets/Servicos/ICON1.svg';
import icon2 from '../../assets/Servicos/ICON2.svg';
import icon3 from '../../assets/Servicos/ICON3.svg';
import icon4 from '../../assets/Servicos/ICON4.svg';
//import icon5 from '../../assets/Servicos/ICON5.svg';
import icon6 from '../../assets/Servicos/ICON6.svg';
import icon7 from '../../assets/Servicos/ICON7.svg';
import icon8 from '../../assets/Servicos/ICON8.svg';
import icon9 from '../../assets/Servicos/ICON9.svg';
import icon10 from '../../assets/Servicos/ICON10.svg';
import icon11 from '../../assets/Servicos/ICON11.svg';
import icon12 from '../../assets/Servicos/ICON12.svg';
import icon13 from '../../assets/Servicos/ICON13.svg';
import icon14 from '../../assets/Servicos/ICON14.svg';
import icon15 from '../../assets/Servicos/ICON15.svg';
// import icon16 from '../../assets/Servicos/ICON16.svg';
import icon17 from '../../assets/Servicos/ICON17.svg';

import './Accordion.css';


function Accordion(){
  const data = [
    {
      icon: icon1,
      title: 'Estacionamento',
      div: 'São mais de 900 vagas no estacionamento. <br /> Além das vagas comuns, o estacionamento conta com vagas preferenciais para:<br /> Idosos, deficientes físicos, gestantes e carros elétricos.<br /><br /> <strong>Valor:</strong><br /> - Carro: até 2 horas: R$ 15,00 ( Por hora adicional +R$ 4,00)<br /> - Moto: até 2 horas: R$ 10,00 ( Por hora adicional +R$ 3,00)<br /> - Ainda contamos com serviço de Valet*: até 1 hora: R$ 20,00 (Por hora adicional +R$ 5,00)<br /><br />Para planos mensais, vá até o balcão de atendimento da Estapar, no primeiro subsolo.<br /><br /> *Serviço temporariamente suspenso, devido à pandemia.'
    },
    {
      icon: icon2,
      title: 'Bicicletário',
      div: 'Um espaço onde o ciclista pode deixar sua bike com toda a segurança. Para a sua comodidade, oferecemos vestiário com chuveiros.<br /<br /> <strong>Local:</strong> Estacionamento, no 1º subsolo.'
    },
    {
      icon: icon3,
      title: 'Pet Friendly',
      div: 'Traga o seu amiguinho para aproveitar o dia aqui no Shopping Parque da Cidade!<br /><br /> O Concierge disponibiliza: <br /><br /> *Saquinhos higiênicos de material biodegradável <br /><br /> *Carrinho Pet <strong>(serviço suspenso durante o período da pandemia)</strong> <br /><br /> Para que vocês aproveitem ao máximo o passeio, é importante seguirmos algumas dicas: <br /><br /> <ul class="pet"><li>Sempre antes de entrar na loja, pergunte se esta aceita que entre com o seu PET.</li><li>Será necessário o uso de coleira ou uma guia mais curta. Mantenha o PET sempre preso para sua segurança.</li><li>Oferecemos dispensers com saquinhos para maior comodidade. O dono do do PET é responsável pelo mesmo inclusive pela limpeza dos dejetos.</li><li>Vai usar a escada rolante? Para a segurança do PET, leve-o no colo.</li><li>O PET é bem-vindo, mas nas áreas de alimentação, não pode. Contamos com a sua compreensão.</li><li>Fique de olho o tempo todo no seu PET.</li><li>Somente é permitido circular com cães das raças: Mastim Napolitano, Pitbull, Rottweiler, American Stafforshire Terrier ou variações de qualquer uma das raças citadas com a utilização de guia curta, focinheira e enforcador, de acordo com a Lei n.11.531, de novembro de 2003.</li><li>Cães-guias de portadores de deficiência visual têm acesso livre.</li></ul>'
    },
    {
      icon: icon4,
      title: 'Wifi',
      div: 'O Shopping Parque da Cidade disponibiliza Wifi sem custo e está disponível em todos os 4 pisos, basta conectar e se cadastrar uma única vez.'
    },
    // {
    //   icon: icon5,
    //   title: 'Táxi',
    //   div: ''
    // },
    {
      icon: icon6,
      title: 'Concierge',
      div: 'O Serviço de Atendimento ao Cliente está disponível durante o período de funcionamento do Shopping. <br /> <strong>Local:</strong> 1º piso.'
    },
    {
      icon: icon7,
      title: 'Fraldário',
      div: 'Estrutura completa, equipada com sala de amamentação com cadeiras e poltronas, frigobar, micro-ondas, filtro de água, trocador de fraldas e um banheiro exclusivo, para o conforto dos bebês e da sua família. <br /><br /> O espaço de 53 m², conta ainda com uma atendente e auxiliar. Tudo oferecido gratuitamente. <br /><br />  <strong>Local:</strong> 1º piso <br /> <strong>Horário de funcionamento:</strong><br /> Segunda a sábado, das 10h às 22h <br /> Domingos e feriados, das 14h às 20h <br /><br /> <strong>Serviço temporariamente suspenso, devido à pandemia.</strong>'
    },
    {
      icon: icon8,
      title: 'Cadeirão Infantil',
      div: 'Nossa Praça de Alimentação oferece cadeiras especiais para crianças.'
    },
    {
      icon: icon9,
      title: 'Achados e perdidos',
      div: 'Caso perca ou encontre algo dentro do Shopping, dirija-se ao Concierge. <br /> <strong>Local:</strong> 1º piso.'
    },
    {
      icon: icon10,
      title: 'Caixas eletrônicos',
      div: 'Banco 24H e Caixa ATM localizados no 1º piso.'
    },
    {
      icon: icon11,
      title: 'Estacionamento - Vagas exclusivas',
      div: 'O estacionamento é administrado pela empresa Estapar. <br /><br /> Há vagas exclusivas para portadores de deficiência, idosos, gestantes  e carros elétricos em todos os pisos de estacionamento. <br /><br /> <strong>Valor:</strong><br /> Até 2 horas: R$ 15,00 ( Por hora adicional +R$ 4,00)' 
    },
    {
      icon: icon12,
      title: 'Valet Parking',
      div: 'Oferecemos serviço de Valet no 1º subsolo.<br /><br /> <strong>Valor:</strong> <br /> Até 1 hora R$20,00 ( Por hora adicional + R$ 5,00 ) <br /><br /><strong>Serviço temporariamente suspenso, devido à pandemia.</strong>'
    },
    {
      icon: icon13,
      title: 'Cadeira de rodas',
      div: 'O Shopping dispõe de cadeiras de rodas tradicionais e motorizadas que podem ser retiradas no nosso Concierge no 1º piso, estacionamento ou mediante solicitação.'
    },
    {
      icon: icon14,
      title: 'Carrinho de bebê',
      div: 'Pais ou responsáveis com crianças de até 2 anos ou pesando até 15 kg podem usufruir dos carrinhos de bebê do Shopping. O empréstimo não tem custo e é feito mediante cadastro no nosso Concierge, que fica localizado no 1º piso. <br /><br /><strong>Serviço temporariamente suspenso, devido à pandemia.</strong>'
    },
    {
      icon: icon15,
      title: 'Ambulatório',
      div: 'Sala de pré-atendimento equipada para que os socorristas possam realizar o primeiro atendimento em caso de emergência. <br /> <strong>Local:</strong> 2º Subsolo'
    },
    // {
    //   icon: icon16,
    //   title: 'Carregador de carro Elétrico',
    //   div: 'Pontos de recarga gratuita para carros elétricos e híbridos no 2º subsolo, nas vagas exclusivas.'
    // },
    {
      icon: icon17,
      title: 'Banheiro Adaptado',
      div: 'Possuímos banheiros adaptados em todos os pisos do Shopping. Com entradas livres de obstáculos, portas largas e adaptações necessárias para receber cadeirantes e pessoas com dificuldades de locomoção.'
    },
  ];

  const [open, setOpen] = useState("");
  const [arrow, setArrow] = useState(false);

  const toggle = (index) => {
    setOpen(open === index ? "" : index);
    setArrow(arrow === index ? "" : index);
  };


  return (
    <>
      <div className="container">
        <ul className="accordion-list">
          {
            data.map((item, index) => (
              <>
                <li key={index} id={index} onClick={() => toggle(index)}>
                  <span><img src={item.icon} alt={item.title}/></span> <b>{item.title}</b> {arrow === index ? <IoIosArrowUp size={30} color="#485860" className="icon-accordion" /> : <IoIosArrowDown size={30} color="#485860" className="icon-accordion" />}
                </li>
                <div className={open === index ? "open" : "close"} dangerouslySetInnerHTML={{__html: `${item.div}` }} />
              </>
            ))
          }
        </ul>
      </div>
    </>
  );
}

export default Accordion;