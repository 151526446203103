import React from 'react';
import { AiFillCaretRight } from 'react-icons/ai';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

// import novidades1 from '../../assets/Home/polo_wear.jpg';
// import novidades2 from '../../assets/Home/gastronomia.jpg';
//import champions from '../../assets/Acontece-no-Parque/champions_banner.jpg';

import '../Eventos/styles.css';

function MidiaKit(){
  return (
    <>
      <Header />
      <main>
        <div style={{backgroundColor: 'gray'}}>
          <div className="acontece-no-parque-novidades-banner">
            <div className="acontece-no-parque-novidades-filter">
              <div className="container"><div className="banner-menu"><ul><li>Home <AiFillCaretRight size={10} /> Mídia Kit </li></ul></div></div>
              <div className="banner">
                {/* <span>Acontece no Parque</span> */}
                <h1>Mídia Kit</h1>
                <hr/>
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <section id="informativo">
          <div className="container">
            <strong>Email: </strong> <p>comercial@shoppingparquedacidade.com.br</p><br />
            <strong>Telefone: </strong> <p>(11) 2158-6401</p>
          </div>
        </section>
        {/* <section id="novidades">
          <div className="novidades-content">
            <div style={{backgroundImage:`url(${novidades1})`}}>
              <div className="novidades_filter">
                <div className="card">
                  <span>Novidades</span>
                  <h1>Polo Wear</h1>
                  <p>Inspiração tropical em tecidos leves protagonizam vestidos na primavera-verão 2021 Polo Wear.</p>
                  <button>Saiba mais</button>
                </div>
              </div>
            </div>
            <div style={{backgroundImage:`url(${novidades2})`}}>
              <div className="novidades_filter">
                <div className="card">
                  <span>Novidades</span>
                  <h1>Burguer +</h1>
                  <p>Aproveite as delícias da casa: tem fritas, hambúrguer para todos os gostos, shakes, hot-dogs e a opção de burger #vegetariano com legumes e soja!</p>
                  <button>Saiba mais</button>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section id="champions">
          <div className="champions-content" style={{backgroundImage:`url(${champions})`}}>
              <div className="filter">
                <div className="champions">
                  <h1>Champions League Experience</h1>
                  <p>Chegou ao Brasil um espaço inédito, imersivo e interativo para os fãs da principal competição europeia de futebol: a Champions League Experience Brasil.</p>
                  <button>Garanta seu ingresso</button>
                </div>
              </div>
          </div>
        </section> */}
      </main>
      <Footer />
    </>
  );
}

export default MidiaKit;