import React from 'react';
import { Link } from 'react-router-dom';
import { AiFillCaretRight } from 'react-icons/ai';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import clinica from '../../assets/Tem-no-Parque/clinica_content.jpg';
import temnoparque1 from '../../assets/Tem-no-Parque/tem_no_parque1.jpg';
import temnoparque2 from '../../assets/Tem-no-Parque/tem_no_parque2.jpg';
import temnoparque3 from '../../assets/Tem-no-Parque/tem_no_parque3.jpg';
import temnoparque4 from '../../assets/Tem-no-Parque/tem_no_parque4.jpg';


import '../Sustentabilidade/styles.css';

function LifeCenter(){
  return (
    <>
      <Header />
      <main>
        <div id="tem-no-parque-clinica-page">
          <div className="tem-no-parque-banner">
            <div className="tem-no-parque-filter">
              <div className="container"><div className="banner-menu"><ul><li>Home <AiFillCaretRight size={10} /> Tem no Parque <AiFillCaretRight size={10} /> Clínica Einstein</li></ul></div></div>
              <div className="banner">
                <span>Tem no Parque</span>
                <h1>Clínica Einstein</h1>
                <hr/>
                <p>Sempre pronto para atender você</p>
              </div>
            </div>
          </div>
        </div>
        <section id="tem-no-parque">
          <div className="container">
            <div className="tem-no-parque-content">
              <div className="tem-no-parque">
                <p>A Clínica Einstein disponibiliza uma equipe formada por médicos e enfermeiros, com apoio de
                nutricionistas, educadores físicos e psicólogos, trabalhando juntos para garantir o cuidado à
                saúde e construir planos individuais exclusivos aos seus pacientes.</p>
                <p>
                <strong>Premissa</strong><br />A Clínica tem como premissa levar em consideração o histórico de saúde, comportamentos de
                vida e outros fatores para definir, com cada paciente, suas metas de saúde e programar
                consultas e orientações com o intuito de melhorar a qualidade de vida de cada indivíduo, seja
                ele criança, adolescente, jovem, adulto ou idoso.</p>
              </div>
              <div>
                <img src={clinica} alt="Clínica Einstein" width="100%" />
              </div>
            </div>
          </div>
        </section>
        <section id="tem-no-parque-box">
          <div className="tem-no-parque-box-content">
            <Link to="/mataatlantica">
              <div style={{backgroundImage:`url(${temnoparque1})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Mata Atlântica</h1>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/telhadoverde">
              <div style={{backgroundImage:`url(${temnoparque2})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Telhado Verde</h1>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/coletadelixo">
              <div style={{backgroundImage:`url(${temnoparque3})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Descarte Ecológico</h1>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/sanitarios">
              <div style={{backgroundImage:`url(${temnoparque4})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Economia de Água</h1>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default LifeCenter;