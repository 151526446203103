import React from 'react';
import { Link } from 'react-router-dom';
import { FaRegClock, FaMapMarkerAlt } from 'react-icons/fa';
import { AiFillCaretRight } from 'react-icons/ai';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import cinemaslide from '../../assets/Cinema/cinema_slide_1.jpg';

import './styles.css';

function Cinema(){
 return (
   <>
    <Header />
      <main>
        <div id="cinema-page">
          <div className="cinema-banner">
            <div className="cinema-filter">
              <div className="container"><div className="banner-menu"><ul><li>Home <AiFillCaretRight size={10} /> Cinema <AiFillCaretRight size={10} /> Sobre</li></ul></div></div>
              <div className="banner">
                <span>Cinema</span>
                <h1>Kinoplex</h1>
                <hr/>
                <div className="cinema-page-content">
                  <div>
                    <p>Eleito a melhor sala VIP de São Paulo</p>
                    <button><Link to="/programacao">Confira a programação</Link></button>
                  </div>
                  <div className="shopping-info">
                    <FaRegClock size={40} className="slide-icon" /> <p>Aberto de Quinta à Domingo <br /> das 12h às 20h</p> <span></span>
                    <FaMapMarkerAlt size={40} className="slide-icon" /> <p>Planeje sua visita <br /> <a href="/contato"><strong>VEJA O MAPA</strong></a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="life-center">
          <div className="life-center-content">
            <div>
              <img src={cinemaslide} alt="Cinema" width="100%" className="cinema-img" />
            </div>
            <div className="life-center cinema-box">
              <h2>Kinoplex Platinum<br /> Parque da Cidade</h2><br />
              <p>O complexo de cinema Kinoplex Parque da Cidade foi eleito a melhor sala VIP de São Paulo pelo Guia da Folha de S.Paulo em fevereiro de 2020. As salas possuem poltronas maiores e totalmente reclináveis. Além disso, o complexo tem uma arquitetura que impressiona e conta um cardápio com opções diferenciadas como milk-shake de pipoca.</p>
              <p>O menu especial* foi idealizado com o chef David Eleutério, especialista na área de criação. Além de pipoca, o cinema tem opções de drinks inspirados em filmes clássicos, fondue de pipoca com cheddar e de pipoca com chocolate.</p>
              <p>Com todas as seis salas modelo Platinum, telas maiores e som Dolby 7.1, o estilo é inspirado em cinemas como o iPic (EUA) e o Cine Royal (Emirados Árabes)</p>
              <p>Quem é membro do Clube do Parque tem 50% de desconto sobre o valor do ingresso inteiro do dia - válido de segunda a sexta - para uma sessão no Kinoplex. Para fazer parte do programa, é só passar no concierge do shopping para fazer um cadastro, que é simples e de graça.</p>
              <p>O Kinoplex está localizado no nosso piso 3 e funciona de segunda a domingo. Confira a programação completa clicando abaixo:</p>
              <button><Link to="/programacao">Clique Aqui</Link></button>
              <p>*Devido à pandemia, alguns itens não estão sendo comercializados. Confira o menu disponível.</p>
            </div>
          </div>
        </section>
      </main>
    <Footer />
   </>
 );
}

export default Cinema;

  