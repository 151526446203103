import React from 'react';
import { Link } from 'react-router-dom';
import { AiFillCaretRight } from 'react-icons/ai';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import temnoparque from '../../assets/Tem-no-Parque/sustentabilidade_content.jpg';
import temnoparque1 from '../../assets/Tem-no-Parque/tem_no_parque1.jpg';
import temnoparque2 from '../../assets/Tem-no-Parque/tem_no_parque2.jpg';
import temnoparque3 from '../../assets/Tem-no-Parque/tem_no_parque3.jpg';
import temnoparque4 from '../../assets/Tem-no-Parque/tem_no_parque4.jpg';


import './styles.css';

function Sustentabilidade(){
  return (
    <>
      <Header />
      <main>
        <div id="tem-no-parque-page">
          <div className="tem-no-parque-banner">
            <div className="tem-no-parque-filter">
              <div className="container"><div className="banner-menu"><ul><li>Home <AiFillCaretRight size={10} /> Tem no Parque <AiFillCaretRight size={10} /> Sustentabilidade</li></ul></div></div>
              <div className="banner">
                <span>Tem no Parque</span>
                <h1>Sustentabilidade</h1>
                <hr/>
                <p>O complexo Parque da Cidade emprega as mais modernas tecnologias em prol da sustentabilidade</p>
              </div>
            </div>
          </div>
        </div>
        <section id="tem-no-parque">
          <div className="container">
            <div className="tem-no-parque-content">
              <div className="tem-no-parque">
                <p>Sustentabilidade é um dos propósitos do empreendimento. Desde sua construção à sua operação, o complexo Parque da Cidade emprega as mais modernas tecnologias em prol da sustentabilidade, buscando potencializar impactos positivos e mitigar impactos negativos. Por isso, é o primeiro no Brasil a obter a certificação LEED nível Gold, do United States Green Building Council, concedido a empreendimentos capazes de impactar positivamente seu entorno.</p>
                <p>O seu projeto foi premiado no International Property Awards, em duas categorias: arquitetura e desenvolvimento.</p>
                <p>Também é pioneiro no descarte ecológico de lixo com sistema de coleta automatizada de resíduos a vácuo, utilizando o Sistema ENVAC. A adoção dessa tecnologia proporciona redução da quantidade de rejeitos encaminhados para aterros e a redução de viagens de caminhões de lixo necessárias para atender o empreendimento, contribuindo assim com o meio ambiente.</p>
                <p>Possui infraestrutura completa para utilização de bicicletas; calçadas com biorretenção para reuso das águas da chuva; telhado verde; equipado com tecnologia ICT (Information and Comunication Technology), que informa em tempo real o consumo de água e energia e as condições do trânsito e do tempo. Estas são apenas algumas iniciativas, dentre muitas outras, que fazem do Parque da Cidade o Life Center mais moderno e “parceiro da sustentabilidade” do Brasil.</p>
              </div>
              <div>
                <img src={temnoparque} alt="Tem no Parque" width="100%" />
              </div>
            </div>
          </div>
        </section>
        <section id="tem-no-parque-box">
          <div className="tem-no-parque-box-content">
            <Link to="/mataatlantica">
              <div style={{backgroundImage:`url(${temnoparque1})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Mata Atlântica</h1>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/telhadoverde">
              <div style={{backgroundImage:`url(${temnoparque2})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Telhado Verde</h1>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/coletadelixo">
              <div style={{backgroundImage:`url(${temnoparque3})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Descarte Ecológico</h1>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/sanitarios">
              <div style={{backgroundImage:`url(${temnoparque4})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Economia de Água</h1>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Sustentabilidade;