import React from 'react';
import { Link } from 'react-router-dom';
import { FaMapMarkerAlt } from 'react-icons/fa';

import logoFooter from '../../assets/logo_footer.svg';
import logoena from '../../assets/logo_enashopp.svg';

import './Footer.css';

function Footer(){
  return (
    <>
      <footer>
          <hr/>
          <div className="container">
            <div className="logo_footer">
              <img src={logoFooter} alt="Shopping Parque da Cidade" width="100%" />
            </div>
            <div className="footer-content">
              <div>
                <span className="title">Endereço</span>
                <p>Av. das Nações Unidas, 14401 <br />
                  Chácara Santo Antônio, São Paulo - SP <br />
                  CEP: 04794-000</p>
                <FaMapMarkerAlt size={20} color="#000000" className="footer_icone" /> <span>Como chegar</span>
              </div>
              <div>
                <span className="title">Horário de funcionamento</span>
                <p>Segunda a sábado <br />
                12h às 20h (facultativo de 10h às 22h</p>
                <p>
                  Domingos e feriados <br />
                  Lojas e Quiosques - 14h às 20h <br />
                  Alimentação - 12h às 20h
                </p>
                <p>
                  Cinema <br />
                  De quinta a domingo (consulte a <br /> 
                  programação no site do cinema)
                </p>
              </div>
              <div>
                <span className="title">Institucional</span>
                <ul>
                  <li><Link to="/midiakit">Mídia Kit</Link></li>
                  <li><a href="http://vagas.intranetmall.com.br/shoppingparquedacidade/">Trabalhe Conosco</a></li>
                  <li><Link to="/politicadeprivacidade">Política de Privacidade</Link></li>
                  <li><a href="http://enashopp.com.br/new/">Enashopp</a></li>
                  <li><Link to="/contato">Contato</Link></li>
                </ul>
              </div>
              <div>
                <span className="title">Contato</span>
                  <p>Telefone <br />
                  (11) 2158-5555</p>
              </div>
            </div>
          </div>
          <hr/>
          <div className="container">
            <div className="footer-content2">
              <div className="logo-ena">
                <a href="http://enashopp.com.br/new/"><img src={logoena} alt="Enashopp" /></a>
              </div>
              <div>
                <p>© Shopping Parque da Cidade - Todos os direitos reservados</p>
              </div>
            </div>
          </div>
      </footer>
    </>
  );
}

export default Footer;