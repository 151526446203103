import React from 'react';
import { Link } from 'react-router-dom';
// import { GrMail } from 'react-icons/gr';

import Header from '../../components/Header/Header';
import SlideHome from '../../components/SlideHome/SlideHome';
import Footer from '../../components/Footer/Footer';

import lifecenter from '../../assets/Home/life_center.jpg';
import cinema from '../../assets/Home/cinema.jpg';
import novidades1 from '../../assets/Home/biblioteca.png';
import novidades2 from '../../assets/Home/mata_atlantica.jpg';
import lojas from '../../assets/Home/nossas_lojas.jpg';
import diferenciais1 from '../../assets/Home/clube_do_parque.jpg';
import diferenciais2 from '../../assets/Home/servicos.jpg';
import diferenciais3 from '../../assets/Home/clinica_einstein.jpg';
import diferenciais4 from '../../assets/Home/nossa_localizacao.jpg';

import api from '../../services/mos';

import './styles.css';

function Home(){

  async function SendForm(e) {
    e.preventDefault();

    try {
      let cpf = '65226677030';
      const mallId = 6;

      const response = await api.get(`clients/${cpf}?mallId=${mallId}`);

      console.log(response);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <Header />
      <main>
        <SlideHome />
        <section id="life-center">
          <div className="life-center-content">
            <div>
              <img src={lifecenter} alt="Life center" width="100%" />
            </div>
            <div className="life-center">
              <h2>O primeiro<br />Life center<br />do Brasil</h2>
              <p>Um novo conceito de shopping center, que privilegia qualidade 
                de vida e praticidade, incluindo gastronomia, conveniência, entretenimento e serviços, 
                sem abrir mão do mix de moda, acessórios e presentes.</p>
                <button onClick={SendForm}><Link to="/lifecenter">Saiba mais</Link></button>
            </div>
          </div>
        </section>
        <section id="cinema">
          <div className="cinema-content" style={{backgroundImage:`url(${cinema})`, backgroundPosition: 'center bottom'} }>
            <div className="filter">
              <div className="filmes">
                <span>Kinoplex</span>
                <h1>MULHER-MARAVILHA 1984</h1>
                <p>Mulher-Maravilha 1984 acompanha Diana Prince/Mulher-Maravilha (Gal Gadot) em 1984, durante a Guerra Fria, entrando em conflito com dois grande inimigos.</p>
                <button><Link to="/programacao">Garanta seu ingresso</Link></button>
              </div>
            </div>
          </div>
        </section>
        <section id="novidades">
          <div className="novidades-content">
            <div style={{backgroundImage:`url(${novidades1})`}}>
              <div className="novidades_filter">
                <div className="card">
                  <span>Novidades</span>
                  <h1>Biblioteca do Parque</h1>
                  {/* <p>Inspiração tropical em tecidos leves protagonizam vestidos na primavera-verão 2021 Polo Wear.</p> */}
                  <button><Link to="/bibliotecadoparque">Saiba mais</Link></button>
                </div>
              </div>
            </div>
            <div style={{backgroundImage:`url(${novidades2})`}}>
              <div className="novidades_filter">
                <div className="card">
                  <span>Novidades</span>
                  <h1>Mata Atlântica</h1>
                  {/* <p>Aproveite as delícias da casa: tem fritas, hambúrguer para todos os gostos, shakes, hot-dogs e a opção de burger #vegetariano com legumes e soja!</p> */}
                  <button><Link to="/mataatlantica">Saiba mais</Link></button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="lojas">
          <div className="lojas-content" style={{backgroundImage:`url(${lojas})`}}>
              <div className="filter">
                <div className="lojas">
                  <h1>Nossas Lojas</h1>
                  <p>Para todos os estilos, gostos e idades.</p>
                  <button><Link to="/lojas">Confira</Link></button>
                </div>
              </div>
          </div>
        </section>
        <section id="diferenciais">
          <div className="diferenciais-content">
            <div style={{backgroundImage:`url(${diferenciais1})`}}>
              <div className="diferenciais_filter">
                <div className="card">
                  <Link to="/clubedoparque">
                    <h1>Clube do Parque</h1>
                    <p>Seja membro!</p>
                  </Link>
                </div>
              </div>
            </div>
            <div style={{backgroundImage:`url(${diferenciais2})`}}>
              <div className="diferenciais_filter">
                <div className="card">
                  <Link to="/servicos">
                    <h1>Serviços</h1>
                    <p>Tudo pensado para a sua conveniência</p>
                  </Link>
                </div>
              </div>
            </div>
            <div style={{backgroundImage:`url(${diferenciais3})`}}>
              <div className="diferenciais_filter">
                <div className="card">
                  <Link to="/clinica-einstein">
                    <h1>Clínica Einstein</h1>
                    <p>Sempre pronto para atender você</p>
                 </Link>
                </div>
              </div>
            </div>
            <div style={{backgroundImage:`url(${diferenciais4})`}}>
              <div className="diferenciais_filter">
                <div className="card">
                  <Link to="/contato">
                    <h1>Nossa Localização</h1>
                    <p>Saiba como chegar</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section id="newsletter">
          <div className="container">
            <div className="newsletter-content">
              <GrMail color="#6ba968" size={30} className="news-icone" />
              <p>Receba as novidades do Parque da Cidade. <br /> Cadastre-se e seja um dos primeiros a saber de todas as 
                novidades e ofertas.</p>
                <button>Cadastrar</button>
            </div>
          </div>
        </section> */}
      </main>
      <Footer />
    </>
  );
}

export default Home;