import React, {useState} from 'react';
import InputMask from 'react-input-mask';
import { AiFillCaretRight } from 'react-icons/ai';
import api from '../../services/wiseit';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import './styles.css';

function Contato(){

  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [assunto, setAssunto] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [newsletter, setNewsletter] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);

  async function SendFormSac(e) {
    e.preventDefault();
  
    try {
      let news = newsletter;
      const tipo = 190;
      const formaRecebimento = 11;
      const status = 1;

      const descricao = `Nome: ${nome} \n Email: ${email} \n Telefone ${telefone} \n Assunto ${assunto} \n Mensagem ${mensagem} \n\n
      Newsletter: ${news === true ? 'Desejo receber newsletter' : ''}`

      const data = {
        "tipo": {"codigo": tipo},
        "descricao": descricao,
        "status": {"codigo": status},
        "formaRecebimento": {"codigo": formaRecebimento},
        "assunto":assunto,
        "news": news
      }

      const response = await api.post('sac/ocorrencia', data);

      console.log(response);

      setSuccessMsg(true);

      setNome('');
      setEmail('');
      setTelefone('');
      setAssunto('');
      setMensagem('');

      setTimeout(function(){ setSuccessMsg(false); }, 3000);

    } catch (error) {
      console.error(error) 
    }
  }
  
  return (
    <>
      <Header />
        <main>
          <div id="contato-page">
            <div className="container">
              <div className="contato-banner">
                <div className="banner-menu">
                  <ul><li>Home <AiFillCaretRight size={10} /> Contato </li></ul>
                </div>
                <div>
                  <h1>Contato</h1>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <section id="form-contato">
            <div className="container">
              <div className="form-contato-content">
                <div>
                  <h1>Entre em contato conosco preenchendo o formulário abaixo:</h1>
                    <form>
                      <input type="text" name="nome" placeholder="Nome" onChange={e => setNome(e.target.value)} value={nome} />
                      <div className="half">
                        <input type="email" name="email" placeholder="E-mail" onChange={e => setEmail(e.target.value)} value={email} />
                        <InputMask type="tel" mask={ telefone.length > 14 ? '(99) 99999-9999' : '(99) 9999-99999' } maskChar={null}  name="telefone" placeholder="Telefone" onChange={e => setTelefone(e.target.value)} value={telefone} />
                      </div>
                      <select name="assunto" onChange={e => setAssunto(e.target.value)} value={assunto}>
                        <option value="assunto">Assunto</option>
                        <option value="reclamações">Reclamações</option>
                        <option value="elogios">Elogios</option>
                        <option value="informações">Informações</option>
                        <option value="comercial">Comercial</option>
                        <option value="outros">Outros</option>
                      </select>
                      <label htmlFor="mensagem">Mensagem</label>
                      <textarea name="mensagem" onChange={e => setMensagem(e.target.value)} value={mensagem}></textarea>
                      <div className="checkbox">
                        <input type="checkbox" name="newsletter" className="news" value={newsletter} onChange={e => setNewsletter(e.target.checked)} checked={newsletter === true}  /> <span>Desejo receber a newsletter</span>
                      </div>
                      
                      <div className="msg">
                        <button className="form-button" type="submit" onClick={SendFormSac}>Enviar</button>
                        {successMsg && (<div>Mensagem enviada com sucesso!</div>)}
                      </div>
                    </form>
                </div>
                <div className="line"><span></span></div>
                <div className="contato-info">
                  <strong>Telefone</strong><br />
                  (11) 2158-5555
                  <br /><br />
                  <strong>Localização</strong><br />
                  Av. das Nações Unidas, 14401<br />
                  Chácara Santo Antônio, São Paulo - SP<br /> 
                  CEP: 04794-000
                  <br /><br />
                  <iframe title="mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3655.4075367987775!2d-46.70779008458155!3d-23.625571884651087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5737126368f5%3A0x3b9e9f476725f94b!2sShopping%20Parque%20da%20Cidade!5e0!3m2!1spt-BR!2sbr!4v1606333935181!5m2!1spt-BR!2sbr" width="600" height="450"></iframe>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="comercial">
                <h1>Quer colocar a sua loja no nosso shopping? Fale com o nosso comercial!</h1><br />
                <strong>Email: </strong> <a href="mailto:mariangela.fleury@enashopp.com.br">mariangela.fleury@enashopp.com.br</a><br />
                <strong>Telefone: </strong> <a href="tel:1121585555">(11) 2158-5555</a><br /><br />
                <h2>Informações para imprensa:</h2><br />
                <strong>Pub Comunica</strong><br />
                <strong>Telefone: </strong> <a href="tel:1130310746">(11) 3031-0746</a><br /><br />
                <strong>Valéria Masson: </strong> <a href="mailto:valeria.masson@pubcomunica.com.br">valeria.masson@pubcomunica.com.br</a><br />
                <strong>Telefone: </strong> <a href="tel:11956517474">(11) 95651-7474</a><br /><br />
                <strong>Cecília Panizza: </strong> <a href="mailto:cecilia.panizza@pubcomunica.com.br">cecilia.panizza@pubcomunica.com.br</a><br />
                <strong>Telefone: </strong> <a href="tel:11966445727">(11) 96644-5727</a>
              </div>
            </div>
          </section>
        </main>
      <Footer />
    </>
  );
}

export default Contato;