import React, {useState, useEffect, useMemo} from 'react';
import {FaInstagram, FaFacebookSquare, FaWhatsapp } from 'react-icons/fa';
import { AiFillCaretRight } from 'react-icons/ai';
import { GrClose } from 'react-icons/gr';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import logo1 from '../../assets/Lojas/logos/arranjo-express.png';
import logo2 from '../../assets/Lojas/logos/Atelie-das-Artes.jpg';
import logo3 from '../../assets/Lojas/logos/Best-Cell.jpg';
import logo4 from '../../assets/Lojas/logos/Cats.png';
import logo5 from '../../assets/Gastronomia/logos/cafe-creme-logo.png';
import logo6 from '../../assets/Gastronomia/logos/Confraria-Manga-Rosa.png';
import logo7 from '../../assets/Gastronomia/logos/Cook.png';
import logo8 from '../../assets/Gastronomia/logos/DAPAVIRADA.jpg';
import logo9 from '../../assets/Lojas/logos/Empório-dos-cosmeticos.jpg';
import logo10 from '../../assets/Lojas/logos/Espaco_prana_spa.jpg';
import logo11 from '../../assets/Lojas/logos/espaco-laser.png';
import logo12 from '../../assets/Lojas/logos/estudio-da-sobrancelha.jpg';
import logo13 from '../../assets/Gastronomia/logos/logo_franscafe_estiloearte_.png';
import logo14 from '../../assets/Gastronomia/logos/Hawk.png';
import logo15 from '../../assets/Gastronomia/logos/hiro.png';
import logo16 from '../../assets/Lojas/logos/hirota.png';
import logo17 from '../../assets/Gastronomia/logos/KHARINA.png';
import logo18 from '../../assets/Lojas/logos/kinoplex.jpg';
import logo19 from '../../assets/Lojas/logos/lupo.jpg';
import logo20 from '../../assets/Lojas/logos/Morana.png';
import logo21 from '../../assets/Gastronomia/logos/NAZCA-café.png';
import logo22 from '../../assets/Lojas/logos/Óticas-Carol.png';
import logo23 from '../../assets/Gastronomia/logos/patroni-01.png';
import logo24 from '../../assets/Lojas/logos/PET-KINGDOM.png';
import logo25 from '../../assets/Lojas/logos/Piticas.jpg';
import logo26 from '../../assets/Gastronomia/logos/GT_PrimoPollo_Logo.png';
import logo27 from '../../assets/Gastronomia/logos/Quintal.jpg';
import logo28 from '../../assets/Gastronomia/logos/sale_pepe.png';
import logo29 from '../../assets/Lojas/logos/SoR.png';
import logo30 from '../../assets/Lojas/logos/Sestini.png';
import logo31 from '../../assets/Gastronomia/logos/Terra-do-fogo.jpg';
import logo32 from '../../assets/Gastronomia/logos/the-cook-house.jpg';
import logo33 from '../../assets/Lojas/logos/THE-NAIL-BOX-01.png';
import logo34 from '../../assets/Lojas/logos/Time-e-co-1.png';
import logo35 from '../../assets/Lojas/logos/Usaflex.png';
import logo36 from '../../assets/Lojas/logos/Claro.png';
import logo37 from '../../assets/Gastronomia/logos/puertas-grill.PNG';

import './styles.css';

function Beneficios(){

  const clubes =  useMemo(
    () => [
      {
        id: 1,
        logo: logo1,
        nome: 'Arranjos Express',
        categoria: 'Conserto de roupas',
        categoria2: 'Serviços',
        bio: 'Especialista em costura! Nossos serviços como, ajustes em até 24 horas, barras em até uma hora, customização, cama, mesa e banho, bordados, roupas pet, alfaiataria, moda praia, baby, peças sob medida. Também realizamos atendimento domiciliar.',
        info: '10% de desconto em serviços.',
        piso: 'Piso 1',
        telefone: '',
        site: 'https://www.arranjosexpress.com.br',
        instagram: 'https://www.instagram.com/arranjosexpressoficial',
        facebook: 'https://www.facebook.com/arranjosexpressbrasil',
        whatsapp: ''
      },
      {
        id: 2,
        logo: logo2,
        nome: 'Ateliê das Artes',
        categoria: 'Papelaria e Gráfica',
        categoria2: 'Serviços',
        bio: 'O Ateliê das Artes transforma a imaginação em solução por meio de presentes especiais, com alta qualidade e design inovador.',
        info: '10% de desconto em todos os produtos e serviços gráficos, exceto livros.',
        piso: 'Piso 1',
        telefone: '5184-2500',
        site: '',
        instagram: '',
        facebook: '',
        whatsapp: ''
      },
      {
        id: 3,
        logo: logo3,
        nome: 'Best Cell',
        categoria: 'Acessórios de telefonia',
        info: '10% de desconto no total da compra.',
        bio: 'A Best Cell oferece uma variedade de produtos e acessórios para celulares e tablets.',
        piso: 'Térreo',
        telefone: '5184-2466',
        site: 'http://www.bestcellstore.com.br',
        instagram: 'https://www.instagram.com/bestcellstore',
        facebook: 'https://www.facebook.com/bestcellstore',
        whatsapp: ''        
      },
      {
        id: 4,
        logo: logo4,
        nome: 'Cat`s Cabeleireiros',
        categoria: 'Cabelereiro e Barbeiro',
        categoria2: 'Serviços',
        info: '15% de desconto nos serviços. Válido de segunda a quinta-feira (exceto feriados).',
        bio: 'Espaço de beleza e estética que está desde 1995 no mercado fazendo a cabeça de homens e mulheres exigentes.',
        piso: 'Piso 1',
        telefone: '5181-3767 / 2158-5638 / (11) 9. 9594-5691',
        site: 'http://www.catscabeleireiros.com.br',
        instagram: 'https://www.instagram.com/catsparquedacidade',
        facebook: 'https://www.facebook.com/CatsCabeleireiros',
        whatsapp: ''
      },
      {
        id: 5,
        logo: logo5,
        nome: 'Caffé Creme',
        categoria: 'Restaurante',
        info: '10% de desconto no total da compra.',
        bio: 'Espaço múltiplo com salgados, doces, lanches diversos, com diferentes formas de servir café, de um jeito descontraído e cosmopolita.',
        piso: 'Piso 2',
        telefone: '',
        site: 'http://www.cafecreme.com.br',
        instagram: 'https://www.instagram.com/caffecremeparquedacidade',
        facebook: 'https://www.facebook.com/cafecremeoficial',
        whatsapp: ''
      },
      {
        id: 6,
        logo: logo6,
        nome: 'Confraria Manga Rosa',
        categoria: 'Restaurante',
        bio: '10% de desconto no total da conta (Não cumulativo com outras promoções).',
        info: 'Variado Buffet com ingredientes frescos e selecionados.',
        piso: 'Térreo',
        telefone: '(11) 5184-0708',
        site: 'https://confrariamangarosa.com.br/',
        instagram: 'https://www.instagram.com/confrariamangarosa',
        facebook: 'https://www.facebook.com/confrariamangarosa',
        whatsapp: ''
      },
      {
        id: 7,
        logo: logo7,
        nome: 'Cook4U',
        categoria: 'Fast-Food',
        info: 'Ganhe um suco natural na compra de uma refeição.',
        bio: 'O Cook4U oferece uma alternativa de alimentação saborosa, natural e saudável para todo tipo de preferência e necessidades alimentares. Opções veganas e com proteínas animais, você é quem decide e monta sua própria refeição.',
        piso: 'Piso 2',
        telefone: '98909-4855',
        site: '',
        instagram: 'https://www.instagram.com/cook4ubr',
        facebook: 'https://www.facebook.com/cook4ubr',
        whatsapp: ''
      },
      {
        id: 8,
        logo: logo8,
        nome: 'Da Pa Virada',
        categoria: 'Sorveteria',
        categoria2: 'Cafeteria',
        info: '10% de desconto no total da compra.',
        bio: 'Gelatos puros, naturais e gostosos, produzidos artesanalmente, com técnica italiana e sotaque contemporâneo. E outros produtos deliciosos e surpreendentes!',
        piso: 'Térreo',
        telefone: '5184-2470',
        site: 'https://www.pavirada.com.br',
        instagram: 'https://www.instagram.com/dapaviradagelateria',
        facebook: 'https://www.facebook.com/dapaviradagelateria',
        whatsapp: ''
      },
      {
        id: 9,
        logo: logo9,
        nome: 'Empório dos Cosméticos',
        categoria: 'Perfumaria e Cosméticos',
        info: '10% de desconto no total da compra.',
        bio: 'Os melhores produtos para cabelos e maquiagem.',
        piso: 'Térreo',
        telefone: '97322-0940',
        site: '',
        instagram: 'https://www.instagram.com/emporiodoscosmeticossp',
        facebook: 'https://www.facebook.com/emporiodoscosmeticossp',
        whatsapp: ''
      },
      {
        id: 10,
        logo: logo10,
        nome: 'Espaço Prana',
        categoria: 'SPA',
        categoria2: 'Serviços',
        info: '15% de desconto nas massagens relaxantes de 50″ ou shiatsu 50″.',
        bio: 'O Espaço Prana Spa disponibiliza aos clientes mais de 30 opções de diferentes tratamentos para promover o equilíbrio do corpo, mente e espírito.',
        piso: 'Térreo',
        telefone: '(11) 5184-0652',
        site: 'https://www.espacoprana.com.br',
        instagram: 'https://www.instagram.com/espacopranaspa/',
        facebook: 'https://www.facebook.com/EspacoPrana/',
        whatsapp: ''
      },
      {
        id: 11,
        logo: logo11,
        nome: 'Espaço Laser',
        categoria: 'Estética',
        categoria2: 'Serviços',
        info: 'Ganhe as 03 primeiras sessões para região das axilas ou da faixa de barba.',
        bio: 'Rede de depilação a laser que democratizou os procedimentos a laser.',
        piso: 'Térreo',
        telefone: '4858-3330-819',
        site: 'https://www.espacolaser.com.br',
        instagram: 'https://www.instagram.com/espacolaser',
        facebook: 'https://www.facebook.com/pageespacolaser',
        whatsapp: ''
      },
      {
        id: 12,
        logo: logo12,
        nome: 'Estúdio da Sobrancelha',
        categoria: 'Estética',
        categoria2: 'Serviços',
        info: '50% de desconto em qualquer procedimento das tabelas de serviços.',
        bio: 'Espaço de beleza voltado para o cuidado do rosto. Design de Sobrancelha, micropigmentação da sobrancelha.',
        piso: 'Piso 1',
        telefone: '5184-2468',
        site: '',
        instagram: 'https://www.instagram.com/estudiodasobrancelha_cn.pq/',
        facebook: '',
        whatsapp: ''
      },
      {
        id: 13,
        logo: logo13,
        nome: 'Fran’s Café',
        categoria: 'Cafeteria',
        info: '15% de desconto na compra do COMBO Refeição + Suco+ Café Expresso.',
        bio: 'Cafeteria brasileira, criada em 1972, que oferece ambiente aconchegante com cardápio variado.',
        piso: 'Térreo',
        telefone: '5184-2464',
        site: 'http://www.franscafe.com.br',
        instagram: 'https://www.instagram.com/franscafeparquedacidade',
        facebook: 'https://www.facebook.com/franscafeoficial',
        whatsapp: ''
      },
      {
        id: 14,
        logo: logo14,
        nome: 'Hawke’s',
        categoria: 'Vinhos e acessórios',
        info: '10% de desconto em vinhos selecionados, camisetas e acessórios da loja (exceto itens em promoção)',
        bio: "Com bom humor e leveza, a marca Hawke's quer levar até você a sofisticação do vinho através de roupas e acessórios para que você possa vivenciar em vários momentos sua paixão.",
        piso: 'Térreo',
        telefone: '11 99027-4422',
        site: 'https://www.hawkes.com.br',
        instagram: 'https://www.instagram.com/hawkes.co',
        facebook: 'https://www.facebook.com/hawkes.co',
        whatsapp: ''
      },
      {
        id: 15,
        logo: logo15,
        nome: 'Hiro',
        categoria: 'Restaurante',
        info: '20% de desconto no total da conta. *Promoção não cumulativa.',
        bio: 'O Hiro busca o equilíbrio entre o tradicional e o moderno para oferecer aos seus clientes o melhor da culinária japonesa.',
        piso: 'Piso 2',
        telefone: '5184-2446',
        site: 'http://www.restaurantehiro.com.br',
        instagram: 'https://www.instagram.com/sushihiroshioficial',
        facebook: 'https://www.facebook.com/hirorestaurante',
        whatsapp: ''
      },
      {
        id: 16,
        logo: logo16,
        nome: 'Hirota Food',
        categoria: 'Mini mercado',
        info: '10% de desconto em produtos HIROTA FOOD',
        bio: 'Com conceito das lojas de conveniências japonesas, o Hirota Food Express foi pensado em oferecer a melhor solução em alimentação, com refeições saudáveis, variadas, práticas e inteligentes.',
        piso: 'Térreo',
        telefone: '2158-5610',
        site: 'https://www.hirotafood.com.br',
        instagram: 'https://www.instagram.com/hirotafoodsupermercados/',
        facebook: 'https://www.facebook.com/HirotaFoodSupermercados',
        whatsapp: ''
      },
      {
        id: 17,
        logo: logo17,
        nome: 'Kharina Burgers Steaks Salads',
        categoria: 'Restaurante',
        categoria2: 'Lanchonete',
        info: '10% no total da conta.',
        bio: 'Rede de restaurantes curitibano com garantia de frescor e qualidade, desde a matéria-prima, para proporcionar os melhores sabores de cada prato.',
        piso: 'Piso 1',
        telefone: '5181-0799',
        site: 'http://www.kharina.com.br',
        instagram: 'https://www.instagram.com/kharinaoficial',
        facebook: 'https://www.facebook.com/kharinaoficial',
        whatsapp: ''
      },
      {
        id: 18,
        logo: logo18,
        nome: 'Kinoplex',
        categoria: 'Cinema',
        categoria2: 'Entretenimento',
        info: '50% de desconto em todas as salas de cinema. Válido de segunda a sexta-feira, exceto feriados. Desconto aplicado apenas sobre o valor do ingresso “inteira” do dia.',
        bio: 'O complexo de cinema Kinoplex foi eleito com a melhor salas VIP de São Paulo, pelo Guia da Folha de S.Paulo. As salas possuem poltronas maiores e totalmente reclináveis. Além disso, a unidade conta um cardápio com opções diferenciadas como milk-shake de pipoca.',
        piso: '',
        telefone: '',
        site: '',
        instagram: '',
        facebook: '',
        whatsapp: ''
      },
      {
        id: 19,
        logo: logo19,
        nome: 'Lupo',
        categoria: 'Moda íntima',
        info: 'O desconto será de 10% em todas as compras realizadas exceto em períodos promocionais da loja.',
        bio: 'Fabricante brasileira de moda íntima, meias e uniformes de times.',
        piso: 'Térreo',
        telefone: '2158-5555',
        site: 'https://www.lupo.com.br',
        instagram: 'https://www.instagram.com/lupo_shoppingparquedacidade_sp',
        facebook: 'https://www.facebook.com/LupoOficial',
        whatsapp: ''
      },
      {
        id: 20,
        logo: logo20,
        nome: 'Morana',
        categoria: 'Acessórios',
        info: '10% de desconto no total da compra.',
        bio: 'Uma das maiores redes de acessórios femininos do Brasil com um vasto número de produtos.',
        piso: 'Piso 1',
        telefone: '2158-5657',
        site: 'https://www.morana.com.br',
        instagram: 'https://www.instagram.com/morana_parquedacidade',
        facebook: 'https://www.facebook.com/moranaparquedacidade',
        whatsapp: ''
      },
      {
        id: 21,
        logo: logo21,
        nome: 'Nazca Café',
        categoria: 'Cafeteria',
        info: '10% de desconto no total da conta.',
        bio: 'Uma cafeteria, focada em cafés especiais,trazendo novas experiências sensoriais com diversos tipos de cafés 100 % arábicos.',
        piso: 'Piso 1',
        telefone: '2158-5600',
        site: '',
        instagram: 'https://www.instagram.com/nazca.cafe',
        facebook: '',
        whatsapp: ''
      },
      {
        id: 22,
        logo: logo22,
        nome: 'Óticas Carol',
        categoria: 'Ótica',
        info: '20% de desconto nas compras, exceto Lentes de Contato ( todas as marcas da loja ) e Óculos Ray Ban.',
        bio: 'Uma das maiores redes de Óticas do Brasil, com mais de 1200 lojas, presentes em todo País.',
        piso: 'Térreo',
        telefone: '5184-2458',
        site: 'https://www.oticascarol.com.br',
        instagram: 'https://www.instagram.com/oticascarol',
        facebook: 'https://www.facebook.com/OticasCarol',
        whatsapp: ''
      },
      {
        id: 23,
        logo: logo23,
        nome: 'Patroni',
        categoria: 'Fast-Food',
        info: 'Ganhe o refrigerante de 300ml na compra de qualquer refeição ou pizza.',
        bio: 'Fundada em 1984, a Patroni Pizza conquistou a preferência de seus clientes com a qualidade de seus produtos e um atendimento personalizado.',
        piso: 'Piso 2',
        telefone: '2158-5617',
        site: 'http://www.patronipizza.com.br',
        instagram: 'https://www.instagram.com/patronipizzaoficial',
        facebook: 'https://www.facebook.com/PatroniOficial',
        whatsapp: ''
      },
      {
        id: 24,
        logo: logo24,
        nome: 'Pet Kingdom',
        categoria: 'Petshop',
        categoria2: 'Serviços',
        info: '10% de desconto na valor total da compra (serviços e produtos). *Não é cumulativo com outras promoções.',
        bio: 'Um novo conceito de Pet Shop com Day Care, Serviços e muitos mimos.',
        piso: 'Térreo',
        telefone: '(11) 96417-7465 / (11) 5181-2421 / 5184-1190',
        site: 'https://www.petkingdom.com.br',
        instagram: 'https://www.instagram.com/petkingdomoficial/',
        facebook: '',
        whatsapp: ''
      },
      {
        id: 25,
        logo: logo25,
        nome: 'Piticas',
        categoria: 'Moda unissex',
        info: '15% de desconto nos dois primeiros itens e 30% desconto no terceiro item (produtos selecionados).',
        bio: 'Rede de franquias de Quiosques e Lojas focada no mundo Geek. Hoje com mais de 450 lojas no Brasil, se tornou a maior da América Latina focada em cultura Pop, e a 33ª maior franquia do Brasil!',
        piso: 'Térreo',
        telefone: '',
        site: 'https://www.piticas.com.br',
        instagram: 'https://www.instagram.com/piticasoficial',
        facebook: 'https://www.facebook.com/camisetaspiticas',
        whatsapp: '5511993830653'
      },
      {
        id: 26,
        logo: logo26,
        nome: 'Primo Pollo',
        categoria: 'Fast-Food',
        info: 'Ganhe um mini refrigerante, suco integral ou água na compra da refeição.',
        bio: 'Um sabor inesquecível, baseado na alta qualidade e agilidade no atendimento, como o bom fast-food deve ser.',
        piso: 'Piso 2',
        telefone: '5184-2457',
        site: '',
        instagram: 'https://www.instagram.com/primopollo',
        facebook: 'https://www.facebook.com/primopollogaleteria',
        whatsapp: ''
      },
      {
        id: 27,
        logo: logo27,
        nome: 'Quintal da Cidade',
        categoria: 'Restaurante',
        info: 'Ganhe um suco natural na compra de uma refeição.',
        bio: 'O Quintal oferece pratos tradicionais como: risotos, massas caseiras, parmegianas juntamente com opções mais leves e saudáveis como: omeletes, saladas e tortas. O hamburguer de shimeji é o nosso coringa para todo tipo de paladar. Experimente nossos pratos preparados diariamente, com ingredientes frescos e de excelente qualidade.',
        piso: 'Praça de Alimentação',
        telefone: '2158-5555',
        site: '',
        instagram: 'https://www.instagram.com/quintaldacidadebr',
        facebook: 'https://www.facebook.com/quintaldacidadebr',
        whatsapp: ''
      },
      {
        id: 28,
        logo: logo28,
        nome: 'Sale & Pepe Cucina Italiana',
        categoria: 'Restaurante',
        info: '50% de desconto no segundo prato, válido no jantar de segunda a sexta-feira. (O desconto será aplicado no prato de menor valor). 50% de desconto nas bebidas alcoólicas, durante o Happy Hour. Válido de segunda a sexta, no período das 17h às 20h. (Desconto não aplicado para garrafas de vinho e nem cervejas especiais)*. Promoções não acumulativas.',
        bio: 'Um conceito que resgata toda a hospitalidade e qualidade da essência italiana. No cardápio, os sabores da Europa são apresentados com grande variedade de pratos com entradas, pastas, massas, risotos, carnes e peixes com opções veganas e vegetarianas.',
        piso: 'Térreo',
        telefone: '2158-5646',
        site: 'http://www.ristorantesaleepepe.com.br',
        instagram: 'https://www.instagram.com/saleepepecucinaitaliana',
        facebook: 'https://www.facebook.com/SaleePepeCucinaItaliana',
        whatsapp: ''
      },
      {
        id: 29,
        logo: logo29,
        nome: 'School of Rock – Alto da Boa Vista',
        categoria: 'Parceiros',
        info: 'Benefícios: aula experimental grátis + 10% de desconto na mensalidade  + 10% de desconto na matrícula. Rua Irineu Marinho, 156 – Alto da Boa Vista – SP',
        bio: 'Na School of Rock Alto da Boa Vista, nossos alunos podem experienciar aulas virtuais individuais do conforto e segurança de suas casas. O nosso programa Remote consiste em aulas individuais semanais conduzidas por nossos professores especializados em um ambiente virtual seguro.',
        piso: '',
        telefone: '(11) 99650-5764',
        site: 'https://locations.schoolofrock.com/altodaboavista',
        instagram: 'https://www.instagram.com/schoolofrock.brasil',
        facebook: 'https://www.facebook.com/schoolofrockbrasil1',
        whatsapp: ''
      },
      {
        id: 30,
        logo: logo30,
        nome: 'Sestini',
        categoria: 'Malas e acessórios',
        info: '10% de desconto no total da compra.',
        bio: 'A marca nacional referência em malas e mochilas que te acompanham em todas as suas histórias.',
        piso: 'Piso 1',
        telefone: '(11) 97644-5111 / (11) 5184-1742',
        site: 'https://www.sestini.com.br/',
        instagram: 'https://www.instagram.com/sestinioficial',
        facebook: 'https://www.facebook.com/sestinioficial',
        whatsapp: ''
      },
      {
        id: 31,
        logo: logo31,
        nome: 'Terra do Fogo',
        categoria: 'Fast-Food',
        info: 'Ganhe um mini refrigerante, suco integral ou água ao comprar um prato.',
        bio: 'O restaurante proporciona a experiência única de degustar a tradicional Parrilla argentina preparada em frente ao cliente.',
        piso: 'Piso 2',
        telefone: '5184-2457',
        site: 'http://www.restauranteterradofogo.com.br',
        instagram: 'https://www.instagram.com/restauranteterradofogo',
        facebook: 'https://www.facebook.com/terradofogogrelhados',
        whatsapp: ''
      },
      {
        id: 32,
        logo: logo32,
        nome: 'The Cook House',
        categoria: 'Fast-Food',
        info: '10% de desconto no total da compra.',
        bio: 'Seja bem-vindo ao The Cookhouse. Aqui você encontra os sabores, aromas e peculiaridades da cozinha contemporânea que prima pela estética gastronômica. Nossos produtos são de excelente qualidade, sabor, intensidade, ingredientes frescos e selecionados.',
        piso: 'Piso 2',
        telefone: '(11) 97155-4315',
        site: '',
        instagram: 'https://www.instagram.com/thecookhouse_sp',
        facebook: 'https://www.facebook.com/The-Cookhouse-384990262105074',
        whatsapp: ''
      },
      {
        id: 33,
        logo: logo33,
        nome: 'The Nail Box',
        categoria: 'Esmalteria',
        categoria2: 'Serviços',
        info: '10% em qualquer um dos serviços.',
        bio: 'A The Nail Box oferece o melhor tratamento e estética para suas unhas de um jeito rápido e com boa qualidade.',
        piso: 'Piso 1',
        telefone: '11 2158-5659',
        site: 'https://thenailbox.com.br',
        instagram: 'https://www.instagram.com/thenailboxsp',
        facebook: 'https://www.facebook.com/thenailboxsp',
        whatsapp: ''
      },
      {
        id: 34,
        logo: logo34,
        nome: 'Time & Co.',
        categoria: 'Relojoaria',
        categoria2: 'Serviços',
        info: '15% de desconto na troca de bateria e 10% de desconto na troca de pulseira.',
        bio: 'Conserto de relógios de parede e de pulso, troca de baterias.',
        piso: 'Piso Superior',
        telefone: '2158-5635',
        site: '',
        instagram: 'https://www.instagram.com/time_co.parquedacidade',
        facebook: '',
        whatsapp: ''
      },
      {
        id: 35,
        logo: logo35,
        nome: 'Usaflex',
        categoria: 'Calçados femininos',
        info: '10% de desconto no total da compra.',
        bio: 'Pioneira na fabricação de calçados de conforto e moda para mulheres de todas as idades.',
        piso: 'Térreo',
        telefone: '5184-2456',
        site: 'https://www.usaflex.com.br',
        instagram: 'https://www.instagram.com/usaflexparquedacidade/',
        facebook: 'https://www.facebook.com/Usaflex',
        whatsapp: ''
      },
      {
        id: 36,
        logo: logo36,
        nome: 'Claro',
        categoria: 'Telefones e acessórios',
        categoria2: 'Serviços',
        bio: 'Serviços de Internet, Telefone, Celular e TV. Além de aparelho e pacotes.',
        info: 'A cada plano fechado na Claro aqui do Shopping do Parque da Cidade, você ganha uma película de vidro para o seu celular.',
        piso: 'Térreo',
        telefone: '(11) 99349-2995/ (11) 97692-5131',
        site: 'https://www.claro.com.br/',
        instagram: 'https://www.instagram.com/clarobrasil',
        facebook: 'https://www.facebook.com/clarobrasil/',
        whatsapp: ''
      },
      {
        id: 37,
        logo: logo37,
        nome: 'Puertas Grill',
        categoria: 'Fast-Food',
        bio: "A mais nova opção de Fast Food atende com sistema de sistema Self Service e destaca-se por apresentar opções saudáveis com ingredientes frescos e selecionados, além da variedade de grelhados com opção de grelhar a carne na hora.",
        info: "Ganhe 10% de desconto, ou sobremesa, ou refrigerante grátis.",        
        piso: 'Piso 2, Praça de Alimentação',
        telefone: '',
        site: '',
        instagram: '',
        facebook: '',
        whatsapp: ''
      }
    ],
    []
  );

  const sortByName = clubes.sort((a, b) => {
    return a.nome.localeCompare(b.nome);
  });
  
  const [showClube, setShowClube] = useState(4);
  const [display, setDisplay] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [searchCat, setSearchCat] = useState('');
  const [catDisplay, setCatDisplay] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState(false);

  function handleShowMoreClubes() {
    setShowClube(moreClubes => moreClubes + 4);
  }

  function handleShowClubesByCat(e){
    //setSearchCat(searchCat);
    setSearchInput('');
    setCatDisplay(true);
    setDisplay(false);
    setShowClube(false);
    e.preventDefault();
  }

  function handleModal(e, clube){
    setModalContent(clube);
    setModal(true);
  }

  useEffect(() => {
    const suggestions = [];
    clubes.forEach(function (clube) {
      suggestions.push(clube.nome);    
    });
    setOptions(suggestions);
  }, [clubes]);

  const resultSearch = item => {
    setSearchInput(item);
    setDisplay(false);
  }

  const searchClubeByName = clubes.filter(clube => clube.nome === searchInput);
  const searchClubeByCat = clubes.filter(clube => clube.categoria === searchCat || clube.categoria2 === searchCat);

  return (
    <>
      <Header />
        <main>
        {modalContent && (
            <>
              <div className={modal ? "modal-bg show-modal" : "modal-bg"}>
                <div className="modal">
                  <GrClose size={30} color="#485860" className="modal-icon" onClick={() => setModal(false)} />
                  <div className="modal-content">
                      <div className="logo">
                        <img src={modalContent.logo} alt={modalContent.nome} />
                      </div>
                      <div className="info">
                        <h1>{modalContent.nome}</h1>
                        <p>{modalContent.categoria}</p>
                        <br />
                        <p className="categoria">{modalContent.bio}</p>
                        <br />
                        <p className="categoria">{modalContent.info}</p>
                        <br />
                        <strong>{modalContent.piso}</strong>
                        <br /><br />
                        <p>{modalContent.telefone}</p>
                        <br />
                        <a href={modalContent.site} target="_blank" rel="noopener noreferrer">{modalContent.site}</a>
                        <br /><br />
                        <ul className="modal-redes-sociais">
                          {modalContent.instagram !== '' && (
                            <li>
                              <a href={modalContent.instagram} target="_blank" rel="noopener noreferrer">
                                <FaInstagram size={24} color="#485860" />
                              </a>
                            </li>
                          )}
                          {modalContent.facebook !== '' && (
                            <li>
                              <a href={modalContent.facebook} target="_blank" rel="noopener noreferrer">
                                <FaFacebookSquare size={24} color="#485860" />
                              </a>
                            </li>
                          )}
                          {modalContent.whatsapp !== '' && (
                            <li>
                              <a href={`https://api.whatsapp.com/send?phone=${modalContent.whatsapp}`} target="_blank" rel="noopener noreferrer">
                                <FaWhatsapp size={24} color="#485860" />
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                </div>
              </div>
            </>
        )}
          <div id="beneficios-page">
            <div className="container">
              <div className="beneficios-banner">
                <div className="banner-menu">
                  <ul><li>Home <AiFillCaretRight size={10} /> Clube do Parque <AiFillCaretRight size={10} /> Lojas Participantes</li></ul>
                </div>
                <div>
                  <h1>Lojas Participantes</h1>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <section id="todos-beneficios">
            <div className="container">
              <form>
                <input 
                  type="text" 
                  name="beneficio"
                  onChange={(e) => setSearchInput(e.target.value)}
                  onClick={() => setDisplay(!display)}
                  value={searchInput}
                  placeholder="Buscar benefício" 
                />
                <select name="categoria" onChange={(e) => setSearchCat(e.target.value)}>
                  <option value="categoria">Categoria</option>
                  {/* <option value="Artigos de Viagem">Artigos de Viagem</option> */}
                  <option value="Acessórios">Acessórios</option>
                  <option value="Acessórios de telefonia">Acessórios de telefonia</option>
                  {/* <option value="Bijuteria e Acessórios">Bijuteria e Acessórios</option> */}
                  <option value="Cabelereiro e Barbeiro">Cabelereiro e Barbeiro</option>
                  <option value="Cafeteria">Cafeteria</option>
                  <option value="Calçados femininos">Calçados femininos</option>
                  <option value="Cinema">Cinema</option>
                  <option value="Conserto de roupas">Conserto de roupas</option>
                  <option value="Entretenimento">Entretenimento</option>
                  <option value="Esmalteria">Esmalteria</option>
                  <option value="Estética">Estética</option>
                  <option value="Fast-Food">Fast-Food</option>
                  <option value="Lanchonete">Lanchonete</option>
                  <option value="Malas e acessórios">Malas e acessórios</option>
                  <option value="Mini mercado">Mini mercado</option>
                  <option value="Moda íntima">Moda íntima</option>
                  <option value="Moda unissex">Moda unissex</option>
                  <option value="Ótica">Ótica</option>
                  <option value="Papelaria e Gráfica">Papelaria e Gráfica</option>
                  <option value="Parceiros">Parceiros</option>
                  <option value="Perfumaria e Cosméticos">Perfumaria e Cosméticos</option>
                  <option value="Petshop">Petshop</option>
                  <option value="Relojoaria">Relojoaria</option>
                  <option value="Restaurante">Restaurante</option>
                  <option value="Serviços">Serviços</option>
                  <option value="Sorveteria">Sorveteria</option>
                  <option value="SPA">SPA</option>
                  <option value="Telefones e acessórios">Telefones e acessórios</option>
                  <option value="Vinhos e acessórios">Vinhos e acessórios</option>
                </select>
                <button className="form-button" onClick={handleShowClubesByCat}>Buscar</button>
                { display && (
                    <div className={display ? "autocomplete autocomplete-show" : "autocomplete"}>
                      {options.filter(nome => nome.toLowerCase().indexOf(searchInput.toLowerCase()) > -1).map((value,index) => {
                        return (
                          <div key={index} data={index} onClick={() => resultSearch(value)}>
                            <span>{value}</span>
                          </div>
                        );
                      })}
                    </div> 
                  )
                }            
              </form>
              <div className="beneficios-load-content">
              {!searchInput ? (
                    sortByName.slice(0, showClube).map((clube, index) => (
                      <>
                        <div key={index} id={clube.id} onClick={(e) => handleModal(e, clube)}>
                          <div className="logo_clube"><img src={clube.logo} alt={clube.nome} width="100%" /></div>
                          <p>{clube.nome}</p>
                          <span>{clube.categoria}</span>
                        </div>
                      </>
                    ))
                  ) :
                  (
                    searchClubeByName.map((clube, index) => (
                      <>
                        <div key={index} id={clube.id} onClick={(e) => handleModal(e, clube)}>
                          <div className="logo_clube"><img src={clube.logo} alt={clube.nome} width="100%" /></div>
                          <p>{clube.nome}</p>
                          <span>{clube.categoria}</span>
                        </div>
                      </>
                    ))
                  ) 
                }
                {catDisplay && (
                    searchClubeByCat.map((clube, index) => (
                      <>
                        <div key={index} id={clube.id} onClick={(e) => handleModal(e, clube)}>
                          <div className="logo_clube"><img src={clube.logo} alt={clube.nome} width="100%" /></div>
                          <p>{clube.nome}</p>
                          <span>{clube.categoria}</span>
                        </div>
                      </>
                    ))   
                  )
                }
              </div>
              {showClube >= clubes.length || !showClube || searchInput ? '' : <button className="form-button" onClick={handleShowMoreClubes}>Carregar mais</button>}
              
            </div>
          </section>
        </main>
      <Footer />
    </>
  );
}

export default Beneficios;