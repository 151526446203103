import axios from 'axios';

const api = axios.create({
  headers: {
    "X-Requested-With": "XMLHttpRequest"
  },
  baseURL: 'https://cors-anywhere.herokuapp.com/https://www.kinoplex.com.br/programacao/cinemas/xml',
});

export default api;
