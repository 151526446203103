import React from 'react';
import { AiFillCaretRight } from 'react-icons/ai';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

// import novidades1 from '../../assets/Home/polo_wear.jpg';
// import novidades2 from '../../assets/Home/gastronomia.jpg';
//import champions from '../../assets/Acontece-no-Parque/champions_banner.jpg';

import '../Eventos/styles.css';

function PoliticadePrivacidade(){
  return (
    <>
      <Header />
      <main>
        <div style={{backgroundColor: 'gray'}}>
          <div className="acontece-no-parque-novidades-banner">
            <div className="acontece-no-parque-novidades-filter">
              <div className="container"><div className="banner-menu"><ul><li>Home <AiFillCaretRight size={10} /> Política de Privacidade </li></ul></div></div>
              <div className="banner">
                {/* <span>Acontece no Parque</span> */}
                <h1>Política de Privacidade</h1>
                <hr/>
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <section id="informativo">
          <div className="container">
            <strong>Seção 1 – Dados que coletamos</strong><br /><br />
            <p>Esclarecemos que você não é obrigado a nos fornecer dados pessoais além dos dados limitados de que precisamos para o seu cadastro em nossa base de dados.</p>

            <strong>Seção 2 – Como usamos seus dados<br />
            Seção 2.1 – Contato comercial</strong><br /><br />

            <p>Utilizaremos seus dados para a sua inclusão em nossa base de dados. Dessa forma nossa equipe comercial pode entrar em contato com você que, ao responder o formulário, se declarou interessado em adquirir um ponto de loja no Shopping Parque da Cidade.</p>

            <strong>Seção 2.2 – Fique informado</strong><br /><br />

            <p>Utilizaremos suas informações de contato para manter-lhe atualizado sobre todas as novidades e informações sobre o empreendimento.</p>

            <strong>Seção 2.3 – Comunicação</strong><br /><br />

            <p>Adicionamos que podemos entrar em contato com você por celular, e não apenas por e-mail.</p>

            <strong>Seção 2.4 – Informações agregadas</strong><br /><br />

            <p>Incluímos mais esclarecimentos sobre os tipos de informações agregadas e não identificáveis que produzimos e podemos compartilhar com terceiros.</p>

            <strong>Seção 3 – Privacidade de suas informações</strong><br /><br />
            <p>As suas informações enviadas não serão compartilhadas com terceiros, somente os funcionários e colaboradores do Shopping Parque da Cidade e de sua administradora, Enashopp, terão acesso.</p>

            <strong>Seção 4 – Suas opções e obrigações<br />
            Seção 4.1 – Retenção de dados</strong><br /><br />

            <p>Esclarecemos que os dados que mantemos podem ter sido fornecidos diretamente por você.</p>

            <strong>Seção 4.2 – Direitos para acessar e controlar seus dados pessoais</strong><br /><br />

            <p>Você pode exercer os seguintes direitos de proteção de dados: direito a excluir, direito de corrigir ou alterar seus dados pessoais, direito de recusar o uso de seus dados pessoais, direito de limitar o processamento dos seus dados, direito de acesso e direito de exercer a portabilidade.</p>
          </div>
        </section>
        {/* <section id="novidades">
          <div className="novidades-content">
            <div style={{backgroundImage:`url(${novidades1})`}}>
              <div className="novidades_filter">
                <div className="card">
                  <span>Novidades</span>
                  <h1>Polo Wear</h1>
                  <p>Inspiração tropical em tecidos leves protagonizam vestidos na primavera-verão 2021 Polo Wear.</p>
                  <button>Saiba mais</button>
                </div>
              </div>
            </div>
            <div style={{backgroundImage:`url(${novidades2})`}}>
              <div className="novidades_filter">
                <div className="card">
                  <span>Novidades</span>
                  <h1>Burguer +</h1>
                  <p>Aproveite as delícias da casa: tem fritas, hambúrguer para todos os gostos, shakes, hot-dogs e a opção de burger #vegetariano com legumes e soja!</p>
                  <button>Saiba mais</button>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section id="champions">
          <div className="champions-content" style={{backgroundImage:`url(${champions})`}}>
              <div className="filter">
                <div className="champions">
                  <h1>Champions League Experience</h1>
                  <p>Chegou ao Brasil um espaço inédito, imersivo e interativo para os fãs da principal competição europeia de futebol: a Champions League Experience Brasil.</p>
                  <button>Garanta seu ingresso</button>
                </div>
              </div>
          </div>
        </section> */}
      </main>
      <Footer />
    </>
  );
}

export default PoliticadePrivacidade;