import React from 'react';
import { AiFillCaretRight } from 'react-icons/ai';

import Header from '../../components/Header/Header';
import Accordion from '../../components/Accordion/Accordion';
import Footer from '../../components/Footer/Footer';

import './styles.css';

function Servicos(){
  return (
    <>
      <Header />
        <main>
          <div id="servicos-page">
            <div className="container">
              <div className="servicos-banner">
                <div className="banner-menu">
                  <ul><li>Home <AiFillCaretRight size={10} /> Serviços </li></ul>
                </div>
                <div>
                  <h1>Serviços</h1>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <section id="servicos">
            <Accordion />
          </section>
        </main>
      <Footer />
    </>
  );
}

export default Servicos;