import React from 'react';
import { Link } from 'react-router-dom';
import { AiFillCaretRight } from 'react-icons/ai';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import temnoparque from '../../assets/Tem-no-Parque/coleta_lixo_content.jpg';
import temnoparque1 from '../../assets/Tem-no-Parque/tem_no_parque1.jpg';
import temnoparque2 from '../../assets/Tem-no-Parque/tem_no_parque2.jpg';
import temnoparque3 from '../../assets/Tem-no-Parque/tem_no_parque3.jpg';
import temnoparque4 from '../../assets/Tem-no-Parque/tem_no_parque4.jpg';


import '../Sustentabilidade/styles.css';

function ColetadeLixo(){
  return (
    <>
      <Header />
      <main>
        <div id="tem-no-parque-coleta-lixo-page">
          <div className="tem-no-parque-banner">
            <div className="tem-no-parque-filter">
              <div className="container"><div className="banner-menu"><ul><li>Home <AiFillCaretRight size={10} /> Tem no Parque <AiFillCaretRight size={10} /> Coleta de Lixo</li></ul></div></div>
              <div className="banner">
                <span>Tem no Parque</span>
                <h1>Coleta de Lixo</h1>
                <hr/>
                <p>Menos rejeitos nos aterros sanitários, sem contaminação e manipulação, para um descarte ambientalmente correto</p>
              </div>
            </div>
          </div>
        </div>
        <section id="tem-no-parque">
          <div className="container">
            <div className="tem-no-parque-content">
              <div className="tem-no-parque">
                <p>Com a tecnologia sueca ENVAC, inédita no Brasil, os resíduos são coletados por dutos subterrâneos e viajam a 80km/h para uma central que os direciona para descarte, evitando contaminação. O sistema reduz a quantidade de rejeitos encaminhados para aterros e o número de viagens de caminhões de lixo. Presente em grandes cidades do mundo como Barcelona, Estocolmo e Londres, o sistema tem tubulações que automaticamente coletam e transportam resíduos para a compactação e a reciclagem adequadas, promovendo o descarte ambientalmente correto dos rejeitos de maneira silenciosa, segura e sem odor.</p>
              </div>
              <div>
                <img src={temnoparque} alt="Tem no Parque" width="100%" />
              </div>
            </div>
          </div>
        </section>
        <section id="tem-no-parque-box">
          <div className="tem-no-parque-box-content">
            <Link to="/mataatlantica">
              <div style={{backgroundImage:`url(${temnoparque1})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Mata Atlântica</h1>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/telhadoverde">
              <div style={{backgroundImage:`url(${temnoparque2})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Telhado Verde</h1>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/coletadelixo">
              <div style={{backgroundImage:`url(${temnoparque3})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Descarte Ecológico</h1>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/sanitarios">
              <div style={{backgroundImage:`url(${temnoparque4})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Economia de Água</h1>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default ColetadeLixo;