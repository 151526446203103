import React from 'react';
import { AiFillCaretRight } from 'react-icons/ai';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

// import evento1 from '../../assets/Acontece-no-Parque/feira_sal.jpg';
// import evento2 from '../../assets/Acontece-no-Parque/mes_das_criancas.jpg';

import './styles.css';

function Eventos(){
  return (
    <>
      <Header />
      <main>
        <div id="acontece-no-parque-page">
          <div className="acontece-no-parque-banner">
            <div className="acontece-no-parque-filter">
              <div className="container"><div className="banner-menu"><ul><li>Home <AiFillCaretRight size={10} /> Acontece no Parque <AiFillCaretRight size={10} /> Eventos</li></ul></div></div>
              <div className="banner">
                <span>Acontece no Parque</span>
                <h1>Eventos</h1>
                <hr/>
                <p>Aqui você encontra os eventos para você e sua família se divertirem. Confira a programação abaixo e não perca!</p>
              </div>
            </div>
          </div>
        </div>
        <section id="informativo">
          <div className="container">
            <p>Neste momento, todos os nossos eventos aqui no Shopping Parque da Cidade estão temporariamente suspensos, respeitando a Fase Amarela no Governo do Estado de São Paulo. </p>
            <p>Lamentamos que este momento nos force a tomar essa decisão, mas precisamos garantir que, acima de tudo, estejamos cumprindo rigorosamente as regras estabelecidas pelas autoridades competentes para garantir o conforto, a tranquilidade e a segurança de nossos visitantes.</p>
            <p>Vale reforçar sempre que nossos protocolos seguem as recomendações da Organização Mundial de Saúde e dos governos locais. Mantivemos a aferição de temperatura na entrada, sempre respeitando o distanciamento e incentivando o uso de máscaras. Nossos corredores largos, arejados e de fácil circulação ajudam a evitar aglomeração e têm dispensers de álcool gel por todos os lados, mantendo a regra máxima de 40% de ocupação.</p>
          </div>
        </section>
        {/* <section id="novidades">
          <div className="novidades-content">
            <div style={{backgroundImage:`url(${evento1})`}}>
              <div className="novidades_filter">
                <div className="card">
                  <h1>Feira Sal</h1>
                  <p>Produtores “hand made”, marcas “slow fashion”, bazares de arte, moda e design.</p>
                  <button>Saiba mais</button>
                </div>
              </div>
            </div>
            <div style={{backgroundImage:`url(${evento2})`}}>
              <div className="novidades_filter">
                <div className="card">
                  <h1>MÊS DAS CRIANÇAS</h1>
                  <p>Para celebrar este mês de outubro, preparamos uma ação especial em parceria com a Tempo Junto.</p>
                  <button>Saiba mais</button>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </main>
      <Footer />
    </>
  );
}

export default Eventos;