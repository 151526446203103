import React, {useState} from 'react';
import InputMask from 'react-input-mask';
import { FaUser, FaBuilding } from 'react-icons/fa';
import { GrMail, GrInternetExplorer } from 'react-icons/gr';
import { IoFingerPrintSharp} from 'react-icons/io5';
import { MdSmartphone, MdLocationOn } from 'react-icons/md';

import cep from '../../services/cep';

function ModalPfForm(){


    const [CEP, setCEP] = useState('');
    const [endereco, setEndereco] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [uf, setUF] = useState('');
    const [telefone, setTelefone] = useState('');

    const pesquisaCEP = () => {
        cep.get(`/${CEP}/json`).then(response => {
          const foundCEP =  response.data;
          setEndereco(foundCEP.logradouro);
          setBairro(foundCEP.bairro);
          setCidade(foundCEP.localidade);
          setUF(foundCEP.uf);
        })
        .catch(error => console.log(error))
      }

    return (
        <>
            <div className="modal-bg show-modal">
                <div className="modal-contato modal-top-bar">
                    <div className="identificação">
                        <h2>Cadastre sua Empresa</h2>
                    </div>
                    <div className="header-form">
                        <h2>Informações da Empresa</h2>
                        <span><i>*preenchimento obrigatórios</i></span>
                    </div>
                    <div className="modal-content-contato modal-top-bar-content">
                        <form>
                            <div className="mb-input">
                                <input type="text" name="nome-fantasia" placeholder="*Nome Fantasia" />
                                <label><FaBuilding color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className="mb-input">
                                <InputMask mask="99.999.999/9999-99" type="tel" name="cnpj" placeholder="*CNPJ" />
                                <label><IoFingerPrintSharp color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className="mb-input">
                                <InputMask mask="99999-999" type="tel" name="cep" placeholder="*CEP" onChange={(e) => setCEP(e.target.value)} onBlur={pesquisaCEP} value={CEP} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className="mb-input">
                                <input type="text" name="endereco" placeholder="*Endereço" onChange={(e) => setEndereco(e.target.value)} value={endereco} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className="mb-input">
                                <input type="tel" name="numero" placeholder="*Número" />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className="mb-input">
                                <input type="text" name="complemento" placeholder="Complemento" />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className="mb-input">
                                <input type="text" name="bairro" placeholder="*Bairro" onChange={(e) => setBairro(e.target.value)} value={bairro} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className="mb-input">
                                <input type="text" name="cidade" placeholder="*Cidade" onChange={(e) => setCidade(e.target.value)} value={cidade} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className="mb-input">
                                <input type="text" name="uf" placeholder="*UF" onChange={(e) => setUF(e.target.value)} value={uf} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <select name="numero-colaboradores">
                                <option value="numero-colaboradores">Número de Colaboradores</option>
                                <option value="1 a 10 funcionários">1 a 10 funcionários</option>
                                <option value="11 a 50 funcionários">11 a 50 funcionários</option>
                                <option value="51 a 100 funcionários">51 a 100 funcionários</option>
                                <option value="101 a 300 funcionários">101 a 300 funcionários</option>
                                <option value="301 a 500 funcionários">301 a 500 funcionários</option>
                                <option value="501 a 1000 funcionários">501 a 1000 funcionários</option>
                                <option value="Mais de 1000 funcionários">Mais de 1000 funcionários</option>
                            </select>
                            <div className="mb-input">
                                <input type="text" name="segmento" placeholder="*Segmento" />
                                <label><FaBuilding color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className="mb-input">
                                <input type="text" name="site" placeholder="*Site da Empresa" />
                                <label><GrInternetExplorer color="#dbdbdb" size={18} /></label>
                            </div>

                            <div className="header-form">
                                <h2>Contato do RH</h2>
                                <span><i>*preenchimento obrigatórios</i></span>
                            </div>
                            <div className="mb-input">
                                <input type="text" name="nome" placeholder="*Nome" />
                                <label><FaUser color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className="mb-input">
                                <InputMask mask="999.999.999-99" type="tel" name="cpf" placeholder="*CPF" />
                                <label><IoFingerPrintSharp color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className="mb-input">
                                <input type="email" name="email" placeholder="*E-mail Profissional" />
                                <label><GrMail color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className="mb-input">
                                <InputMask type="tel" mask={ telefone.length > 14 ? '(99) 99999-9999' : '(99) 9999-99999' } maskChar={null}  name="telefone" placeholder="*Telefone Empresa / Celular" onChange={e => setTelefone(e.target.value)} value={telefone} />
                                <label><MdSmartphone color="#dbdbdb" size={18} /></label>
                                <div className="infos-input"><p>(DDD + número)</p></div>
                            </div>
                            <div className="mb-input input-radios">
                                <div><h3>Gênero</h3></div><br />
                                <div><input type="radio" name="genero" id="feminino" value="feminino"/> Feminino</div>
                                <div><input type="radio" name="genero" id="masculino" value="masculino"/> Masculino</div>
                                <div><input type="radio" name="genero" id="nao_informar" value="nao_informar"/> Prefiro não informar</div>
                            </div>
                            <div className="mb-input input-checkbox">
                                <div><input type="checkbox" name="info_mail" /> <p>Deseja receber novidades, ofertas e promoções por e-mail?</p></div>
                            </div>
                        </form>
                        <button className="form-button-identifica">Cadastrar</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModalPfForm;