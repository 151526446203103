import React from 'react';
import { Link } from 'react-router-dom';
import { AiFillCaretRight } from 'react-icons/ai';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import temnoparque from '../../assets/Tem-no-Parque/telhado_verde_content.jpg';
import temnoparque1 from '../../assets/Tem-no-Parque/tem_no_parque1.jpg';
import temnoparque2 from '../../assets/Tem-no-Parque/tem_no_parque2.jpg';
import temnoparque3 from '../../assets/Tem-no-Parque/tem_no_parque3.jpg';
import temnoparque4 from '../../assets/Tem-no-Parque/tem_no_parque4.jpg';


import '../Sustentabilidade/styles.css';

function TelhadoVerde(){
  return (
    <>
      <Header />
      <main>
        <div id="tem-no-parque-telhado-verde-page">
          <div className="tem-no-parque-banner">
            <div className="tem-no-parque-filter">
              <div className="container"><div className="banner-menu"><ul><li>Home <AiFillCaretRight size={10} /> Tem no Parque <AiFillCaretRight size={10} /> Telhado Verde</li></ul></div></div>
              <div className="banner">
                <span>Tem no Parque</span>
                <h1>Telhado Verde</h1>
                <hr/>
                <p>Uma iniciativa sustentável, que evita enchentes, diminui a poluição e economiza energia. O meio ambiente agradece!</p>
              </div>
            </div>
          </div>
        </div>
        <section id="tem-no-parque">
          <div className="container">
            <div className="tem-no-parque-content">
              <div className="tem-no-parque">
                <p>Nosso telhado verde foi construído pensando em deixar o ambiente mais agradável e saudável porque ajuda a controlar a umidade do ar dentro do shopping, protege contra altas temperatura no verão e mantém a temperatura interna no inverno. </p>
                <p>Além disso, ele ajuda a diminuir a poluição, economiza energia (por reduzir gasto com ar-condicionado) e absorve 30% da água da chuva, reduzindo a chance de enchentes.</p>
                <p>Também melhora o isolamento acústico da edificação porque a vegetação absorve e isola ruídos.</p>
              </div>
              <div>
                <img src={temnoparque} alt="Tem no Parque" width="100%" />
              </div>
            </div>
          </div>
        </section>
        <section id="tem-no-parque-box">
          <div className="tem-no-parque-box-content">
            <Link to="/mataatlantica">
              <div style={{backgroundImage:`url(${temnoparque1})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Mata Atlântica</h1>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/telhadoverde">
              <div style={{backgroundImage:`url(${temnoparque2})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Telhado Verde</h1>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/coletadelixo">
              <div style={{backgroundImage:`url(${temnoparque3})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Descarte Ecológico</h1>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/sanitarios">
              <div style={{backgroundImage:`url(${temnoparque4})`}}>
                <div className="tem-no-parque-box-filter">
                  <div className="card">
                    <h1>Economia de Água</h1>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default TelhadoVerde;