import React from 'react';
import { AiFillCaretRight } from 'react-icons/ai';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

// import novidades1 from '../../assets/Home/polo_wear.jpg';
// import novidades2 from '../../assets/Home/gastronomia.jpg';
//import champions from '../../assets/Acontece-no-Parque/champions_banner.jpg';

import '../Eventos/styles.css';

function NossosHorarios(){
  return (
    <>
      <Header />
      <main>
        <div id="acontece-no-parque-horarios-page">
          <div className="acontece-no-parque-novidades-banner">
            <div className="acontece-no-parque-novidades-filter">
              <div className="container"><div className="banner-menu"><ul><li>Home <AiFillCaretRight size={10} /> Acontece no Parque <AiFillCaretRight size={10} /> Nossos Horários</li></ul></div></div>
              <div className="banner">
                <span>Acontece no Parque</span>
                <h1>Nossos Horários</h1>
                <hr/>
                <p>Confira abaixo o período que cada loja escolheu para melhor te atender</p>
              </div>
            </div>
          </div>
        </div>
        <section id="informativo">
          <div className="container">
            <strong>1. Lojas / Quiosques que abrem nos horários:<br />
            Segunda a sábado das 12h às 20h<br />
            Domingos e feriados: 14h às 20h</strong>
            <br /><br />
            <p>ArboReal<br />
            Ateliê das Artes<br />
            Best Cell<br />
            Cacau Show<br />
            Cat’s Cabeleireiros<br />
            Chilli Beans<br />
            Claro<br />
            Empório dos Cosméticos<br />
            Estúdio da Sobrancelha<br />
            Havanna<br />
            Hawke’s<br />
            Hirota Food<br />
            Lupo<br />
            Minha vaidade<br />
            Nazca Café<br />
            OakBerry<br />
            Óticas Carol<br />
            Pet Kingdom<br />
            Piticas<br />
            Samsung<br />
            Sestini<br />
            The Nail Box<br />
            Time & co<br />
            Tropical<br />
            Usaflex<br />
            Vila Casa</p>
            <strong>2. Lojas que abrem nos horários:<br />
            Segunda a sábado: 12 às 22h<br />
            Domingos e feriados: 14h às 20h</strong>
            <br /><br />
            <p>
            Espaço Laser<br />
            Espaço Prana Spa<br />
            Extrafarma<br />
            Morana<br />
            Polo Wear</p>
            <strong>3. Restaurantes que abrem nos horários:<br />
            Segunda a sábado: 12 às 22h<br />
            Domingos e feriados: 12h às 20h</strong>
            <br /><br />
            <p>Burger King<br />
            Fábrica di Chocolate<br />
            Fran’s Café<br />
            Jeronimo Burguer<br />
            Rice Risoteria<br />
            Vivenda do Camarão</p>
            <strong>4. Restaurantes que abrem nos horários:<br />
            Segunda a sábado: 12 às 20h<br />
            Domingos e feriados: 12h às 20h</strong>
            <br /><br />
            <p>Burger+<br />
            Caffé Creme<br />
            Cook4U <br />
            DaPáVirada Sorveteria<br />
            Hiro<br />
            Kharina<br />
            Manga Rosa<br />
            McDonalds<br />
            McDonalds Quiosque<br />
            Patroni<br />
            Primo Pollo<br />
            Rizzo<br />
            Quintal da Cidade<br />
            Terra do Fogo<br />
            The Cook House<br />
            The Steak Factory</p>
            <strong>5. Lojas / Serviços / Restaurantes com horários especiais:</strong>
            <br /><br />
            <strong>Arranjos Express</strong><br /> 
            <p>Segunda a sábado: 12 às 20h<br />
            Domingos e feriados: FECHADO</p>
            <strong>Clínica Einstein</strong><br />
            <p>Segunda a sexta-feira: 07 às 22h<br />
            Sábado: 07 às 13h<br />
            Domingos e feriados: FECHADO</p>
            <strong>Lotérica Lucky Place</strong><br />
            <p>Segunda a sexta-feira: 12 às 19h<br />
            Sábado: 12 às 19h<br />
            Domingos e feriados: FECHADO</p>
            <strong>Kinoplex</strong><br />
            <p>Aberto de quinta a domingo<br />
            Acesse o site do Kinoplex para acompanhar a programação completa.</p>
            <strong>MS Odontologia</strong><br />
            <p>Segunda a sábado: 12 às 20h<br />
            Domingos e feriados: FECHADO</p>
            <strong>Sale & Pepe Cucina Italiana</strong><br />
            <p>Segunda a sábado: 12 às 22h<br />
            Domingos e feriados: 12 às 20h</p>
            <strong>Santa Maria Lavanderia</strong><br />
            <p>Segunda a sexta-feira: 12 às 20h<br />
            Sábado: 10h às 18h<br />
            Domingos e feriados: FECHADO</p>
            <strong>Velocity</strong><br />
            <p>Segunda a sexta-feira: 06:30 às 21h<br />
            Sábados, domingos e feriados: 09h às 14h</p>
            <strong>6. Lojas que permanecem fechadas</strong><br /><br />
            <p>Cuor di Crema<br />
            CVC<br />
            Mania de Churrasco<br />
            Vip’s Casa de Câmbio</p>
            <p>A Praça de Alimentação e Restaurantes retornaram com o limite de ocupação de 40% da capacidade e distanciamento entre as mesas.</p>
            <p>Somente clientes sentados dentro da Praça de Alimentação e Restaurantes podem consumir alimentos.</p>
            <p>Serviços de Estética e Salão de Beleza também estão abertos, de acordo com os protocolos de segurança do setor.</p>
            <strong>DRIVE THRU E DELIVERY</strong><br /><br />
            <p>Continuamos com lojas participantes do Delivery e Drive Thru para melhor atendimento os nossos clientes.</p>
            <p>Enquanto o empreendimento estava fechado, adequamos-o aos protocolos de saúde para a segurança e bem-estar de todos. Com extrema responsabilidade e cuidado, seguimos o protocolo apresentado oficialmente pela ABRASCE.</p>
            <p>Segue o protocolo detalhado:</p>
            <strong>Áreas de uso exclusivo de colaboradores (salas, refeitórios e vestiários)</strong><br />
            <p>Colocação de cartazes  – respeite o distanciamento, higienize as mãos e use máscaras;<br />
            Instalação de dispensadores de álcool gel 70% em todos ambientes;<br />
            Reorganização do layout com distanciamento de cadeiras e mesas;<br />
            Limitação capacidade de uso, com revezamento, informativo e sinalização;<br />
            Uso obrigatório de máscaras para todos colaboradores;<br />
            Sanitização de mesas, cadeiras, teclados, mouses, telefones a cada turno;*<br />
            Sanitização de banheiros, refeitórios, vestiários, etc. no mínimo a cada turno;**</p>
            <strong>Estacionamento</strong><br />
            <p>Emissão automatizada de ticket (sem acionamento manual);<br />
            Suspensão do serviço de manobrista;<br />
            Balcão de pagamento c/ barreira de proteção ou colaborador c/ protetor facial shield e máscara;<br />
            Aplicação de plástico protetor sobre maquinas de pagamento c/ limpeza após uso (c/ álcool gel 70%);<br />
            Demarcação de distanciamento no piso e usar organizadores de fila se necessário;<br />
            Colocação de cartazes  – respeite o distanciamento, higienize as mãos e use máscaras;<br />
            Limpeza geral antes da abertura ao público e superfícies de toque a cada duas horas;***</p>
            <strong>Acessos, Recepções, Balcões de atendimento e Informações e CAC</strong><br />
            <p>Controle de Medição de temperatura;<br />
            Demarcação de distanciamento  no piso e usar organizadores de fila se necessário;<br />
            Colocação de cartazes  – respeite o distanciamento, higienize as mãos e use máscaras;<br />
            Tapetes com aplicação de sanitizantes;<br />
            Portas mantidas abertas durante o horário de abertura ao público;<br />
            Instalação de dispensadores de álcool gel 70%;<br />
            Limpeza geral antes da abertura ao público e superfícies de toque a cada duas horas;***</p>
            <strong>Elevadores</strong><br />
            <p>Suspensão do serviço de ascensorista dos elevadores;<br />
            Demarcação de piso e cartaz de Respeite o distanciamento (cabine e hall);<br />
            Cartaz de uso preferencial de idosos, gestantes e PNE;<br />
            Instalação de dispensador de álcool gel junto à botoeira;<br />
            Limpeza geral antes da abertura ao público e superfícies de toque a cada duas horas;***</p>
            <strong>Mall, escadas e áreas diversas</strong><br />
            <p>Instalação de dispensador de álcool gel por toda a área de circulação;<br />
            Distanciamento de mobiliário ou bloqueio de assentos próximos com sinalização;<br />
            Colocação de cartazes  – respeite o distanciamento, higienize as mãos e use máscaras;<br />
            Demarcação de piso e cartaz de Respeite o distanciamento (1,5m) nos ATM’s/Bancos 24h;<br />
            Demarcação de piso e cartaz de Respeite o distanciamento nas escadas;<br />
            Suspensão do fornecimento de carrinhos de bebê;<br />
            Suspensão dos serviços e uso de área de fraldários;<br />
            Abertura de portas, janelas de átrios e demais vãos que permitam a circulação/renovação de ar;<br />
            Recolhimento de lixo constante;<br />
            Limpeza geral antes da abertura ao público e superfícies de toque a cada duas horas;***</p>
            <strong>Sanitários</strong><br /> 
            <p>Bloqueio intercalado de cubas da bancada;<br />
            Bloqueio intercalado de vasos sanitários;<br />
            Bloqueio intercalado de mictórios;<br />
            Instalação de cartazes de medidas preventivas;<br />
            Instalação de dispensador de álcool gel;<br />
            Funcionamento do sistema de exaustão;<br />
            Limpeza constante e colaboradores de limpeza com utilização de EPI adequado;</p>
            <strong>Praça de Alimentação</strong><br />
            <p>Redução da capacidade de utilização em 50% (remoção de cadeiras e adesivo de mesa);<br />
            Distanciamento de cadeiras e mesas;<br />
            Colocação de cartazes  – respeite o distanciamento, higienize as mãos e use máscaras;<br />
            Instalação de dispensador de álcool gel junto à botoeira;<br />
            Recolhimento de lixo constante;<br />
            Limpeza constante e colaboradores de limpeza com utilização de EPI adequado;</p>
          </div>
        </section>
        {/* <section id="novidades">
          <div className="novidades-content">
            <div style={{backgroundImage:`url(${novidades1})`}}>
              <div className="novidades_filter">
                <div className="card">
                  <span>Novidades</span>
                  <h1>Polo Wear</h1>
                  <p>Inspiração tropical em tecidos leves protagonizam vestidos na primavera-verão 2021 Polo Wear.</p>
                  <button>Saiba mais</button>
                </div>
              </div>
            </div>
            <div style={{backgroundImage:`url(${novidades2})`}}>
              <div className="novidades_filter">
                <div className="card">
                  <span>Novidades</span>
                  <h1>Burguer +</h1>
                  <p>Aproveite as delícias da casa: tem fritas, hambúrguer para todos os gostos, shakes, hot-dogs e a opção de burger #vegetariano com legumes e soja!</p>
                  <button>Saiba mais</button>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section id="champions">
          <div className="champions-content" style={{backgroundImage:`url(${champions})`}}>
              <div className="filter">
                <div className="champions">
                  <h1>Champions League Experience</h1>
                  <p>Chegou ao Brasil um espaço inédito, imersivo e interativo para os fãs da principal competição europeia de futebol: a Champions League Experience Brasil.</p>
                  <button>Garanta seu ingresso</button>
                </div>
              </div>
          </div>
        </section> */}
      </main>
      <Footer />
    </>
  );
}

export default NossosHorarios;